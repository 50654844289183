import {createEffect} from "effector";

export const getTestingTableDataFx = createEffect().use( async (payload) => {
	try {
		const response = await fetch(
			'https://wg8x98quyf.execute-api.eu-west-1.amazonaws.com/PortalAPI/admin',
			{
				method: 'POST',
				body: JSON.stringify({
					client_id: payload?.client_id || "klba36t1h2",
					date: payload?.date.split('.').reverse().join('-') || "",
					checkin_status: payload?.checkin_status || "employee"
				})
			}
		)

		let result = await response.json()
		console.log('getTestingTableDataFx:: Success', result)
		const tempRes = JSON.parse(result.body)
		console.log('getTestingTableDataFx:: Parsed body', tempRes)
		return tempRes
	} catch (e) {
		console.error(e)
	}
})
