import {sample} from "effector";
import {changeSearchValueEv, resetSearchDataEv, resetSearchValueEv, searchClientsEv} from "./events";
import {$searchByIdData, $searchByIdSearchValue} from "./stores";
import {disassociateUserFx, getSearchByIdDataFx} from "./effects";

$searchByIdData.reset(resetSearchValueEv)
$searchByIdSearchValue.reset(resetSearchDataEv)

sample({
	clock: changeSearchValueEv,
	target: $searchByIdSearchValue
})

sample({
	source: $searchByIdSearchValue,
	clock: searchClientsEv,
	filter: (source, clock) => source,
	fn: (searchValue) => {
		return {
			client_id: searchValue
		}
	},
	target: getSearchByIdDataFx
})

sample({
	source: $searchByIdSearchValue,
	clock: searchClientsEv,
	filter: (source, clock) => !source,
	target: [resetSearchValueEv, resetSearchDataEv]
})

sample({
	clock: getSearchByIdDataFx.doneData,
	target: $searchByIdData
})

sample({
	source: $searchByIdData,
	clock: disassociateUserFx.doneData,
	fn: (source, clock) => clock,
	target: $searchByIdData
})
