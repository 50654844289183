import React from 'react';
import {Button, Col, Form, Input, Modal, Radio, Row, Space} from "antd";
import {useUnit} from "effector-react";
import {$showCreateTenantModal, toggleCreateTenantModalEv} from "../../../models/portalUsersModel";

function CreateTenantModal() {
	const show = useUnit($showCreateTenantModal)
	const toggleCreateTenantModal = useUnit(toggleCreateTenantModalEv)
	const [tenantForm] = Form.useForm()

	const onCancel = () => {
		toggleCreateTenantModal(false)
		tenantForm.resetFields()
	}

	const onFinish = (values) => {
		console.log(values)
	}

	return (
		<Modal
			open={show}
			onCancel={onCancel}
			footer={null}
			destroyOnClose
			title={'Добавить новый tenant'}
			width={557}
		>
			<Form
				layout={"vertical"}
				form={tenantForm}
				onFinish={onFinish}
			>
				<Form.Item
					name={'name'}
					label={'Наименование'}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name={'type_legal'}
					label={'Тип юр лица'}
				>
					<Radio.Group>
						<Radio value={'ТОО'}>ТОО</Radio>
						<Radio value={'ИП'}>ИП</Radio>
						<Radio value={'АО'}>АО</Radio>
						<Radio value={'ЗАО'}>ЗАО</Radio>
						<Radio value={'ПТ'}>ПТ</Radio>
					</Radio.Group>
				</Form.Item>
				<Row gutter={8}>
					<Col span={12}>
						<Form.Item
							name={'avg_check'}
							label={'Средний чек'}
						>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name={'price_segment'}
							label={'Ценовой сегмент'}
						>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Form.Item
					name={'industry'}
					label={'Индустрия'}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name={'address'}
					label={'Адрес'}
				>
					<Input />
				</Form.Item>
				<Space>
					<Button type={"primary"} onClick={tenantForm.submit}>Сохранить</Button>
					<Button onClick={onCancel}>Отменить</Button>
				</Space>
			</Form>
		</Modal>
	);
}

export default CreateTenantModal;
