import { createEffect } from "effector/compat";

const fakeData = [
  {
    id: 1,
    name: "Test 1",
    status: "ban",
    img: "",
    total_visits: [
      "2024-09-01",
      "2024-09-05",
      "2024-09-10",
      "2024-09-12",
      "2024-09-15",
      "2024-09-20",
    ],
    comments: [
      {
        comment: "User visited for account verification.",
        date: "2024-09-01",
        comment_by: "admin",
      },
      {
        comment: "Follow-up on previous visit.",
        date: "2024-09-05",
        comment_by: "admin",
      },
      {
        comment: "Noted unusual activity.",
        date: "2024-09-12",
        comment_by: "admin",
      },
      {
        comment: "User expressed concerns about status.",
        date: "2024-09-15",
        comment_by: "user",
      },
      {
        comment: "User has been banned due to policy violations.",
        date: "2024-09-15",
        comment_by: "admin",
      },
      {
        comment: "Reviewing case for possible reinstatement.",
        date: "2024-09-20",
        comment_by: "admin",
      },
    ],
    pass_visits: 2,
    reject_visits: 4,
    bases: ["local", "premium"],
  },
  {
    id: 2,
    name: "Test 2",
    status: "vip",
    img: "",
    total_visits: [
      "2024-09-02",
      "2024-09-06",
      "2024-09-10",
      "2024-09-12",
      "2024-09-14",
      "2024-09-16",
      "2024-09-18",
      "2024-09-20",
      "2024-09-22",
      "2024-09-24",
    ],
    comments: [
      {
        comment: "First engagement observed.",
        date: "2024-09-02",
        comment_by: "admin",
      },
      {
        comment: "Consistent engagement.",
        date: "2024-09-06",
        comment_by: "admin",
      },
      {
        comment: "User shared valuable feedback.",
        date: "2024-09-12",
        comment_by: "user",
      },
      {
        comment: "Participated in feedback session.",
        date: "2024-09-14",
        comment_by: "admin",
      },
      {
        comment: "Excellent performance and engagement.",
        date: "2024-09-24",
        comment_by: "admin",
      },
    ],
    pass_visits: 10,
    reject_visits: 0,
    bases: ["local", "premium"],
  },
  {
    id: 3,
    name: "Test 3",
    status: "new",
    img: "",
    total_visits: [],
    comments: [],
    pass_visits: 0,
    reject_visits: 0,
    bases: ["premium"],
  },
  {
    id: 4,
    name: "Test 4",
    status: "new",
    img: "",
    total_visits: [
      "2024-09-03",
      "2024-09-08",
      "2024-09-11",
      "2024-09-15",
      "2024-09-18",
      "2024-09-20",
    ],
    comments: [
      {
        comment: "Initial visit to set up the profile.",
        date: "2024-09-03",
        comment_by: "admin",
      },
      {
        comment: "Reviewed user activity.",
        date: "2024-09-11",
        comment_by: "admin",
      },
      {
        comment: "Follow-up on profile completion.",
        date: "2024-09-20",
        comment_by: "admin",
      },
    ],
    pass_visits: 3,
    reject_visits: 3,
    bases: ["local"],
  },
  {
    id: 5,
    name: "Test 5",
    status: "ban",
    img: "",
    total_visits: [
      "2024-09-02",
      "2024-09-06",
      "2024-09-12",
      "2024-09-14",
      "2024-09-18",
    ],
    comments: [
      {
        comment: "User flagged for suspicious behavior.",
        date: "2024-09-06",
        comment_by: "admin",
      },
      {
        comment: "Follow-up required.",
        date: "2024-09-12",
        comment_by: "admin",
      },
      {
        comment: "User requested additional support.",
        date: "2024-09-18",
        comment_by: "user",
      },
    ],
    pass_visits: 2,
    reject_visits: 3,
    bases: ["premium"],
  },
  {
    id: 6,
    name: "Test 6",
    status: "vip",
    img: "",
    total_visits: [
      "2024-09-01",
      "2024-09-05",
      "2024-09-10",
      "2024-09-12",
      "2024-09-15",
      "2024-09-20",
      "2024-09-22",
      "2024-09-24",
      "2024-09-26",
      "2024-09-28",
    ],
    comments: [
      {
        comment: "Regular engagement noted.",
        date: "2024-09-05",
        comment_by: "admin",
      },
      {
        comment: "User reported positive experience.",
        date: "2024-09-12",
        comment_by: "user",
      },
      {
        comment: "Participation in event confirmed.",
        date: "2024-09-22",
        comment_by: "admin",
      },
      {
        comment: "Outstanding contributions and behavior.",
        date: "2024-09-28",
        comment_by: "admin",
      },
    ],
    pass_visits: 5,
    reject_visits: 5,
    bases: ["local", "premium"],
  },
  {
    id: 7,
    name: "Test 7",
    status: "new",
    img: "",
    total_visits: [
      "2024-09-04",
      "2024-09-09",
      "2024-09-12",
      "2024-09-15",
      "2024-09-18",
      "2024-09-20",
    ],
    comments: [
      {
        comment: "Initial review completed.",
        date: "2024-09-09",
        comment_by: "admin",
      },
      {
        comment: "Follow-up visit scheduled.",
        date: "2024-09-15",
        comment_by: "admin",
      },
      {
        comment: "Initial review of user activity.",
        date: "2024-09-20",
        comment_by: "admin",
      },
    ],
    pass_visits: 3,
    reject_visits: 3,
    bases: ["local"],
  },
  {
    id: 8,
    name: "Test 8",
    status: "ban",
    img: "",
    total_visits: [
      "2024-09-02",
      "2024-09-06",
      "2024-09-12",
      "2024-09-14",
      "2024-09-18",
    ],
    comments: [
      {
        comment: "Flagged for further investigation.",
        date: "2024-09-06",
        comment_by: "admin",
      },
      {
        comment: "User responded to flag.",
        date: "2024-09-14",
        comment_by: "user",
      },
    ],
    pass_visits: 1,
    reject_visits: 4,
    bases: ["local"],
  },
];

export const getBlackListDataFx = createEffect().use(async (payload) => {
  //FIXME API call
  try {
    const response = await fetch(
      "https://i8cck8k1if.execute-api.eu-west-1.amazonaws.com/BlackList/GetLatestVisits",
      {
        method: "POST",
        body: JSON.stringify({
          partition_key: "glra12a4a6",
        }),
      },
    );

    let result = await response.json();
    return JSON.parse(result.body);
  } catch (e) {
    console.error(e);
  }

  // return new Promise((resolve, reject) =>
  //   setTimeout(() => {
  //     console.log("payload", payload);
  //     return resolve(fakeData);
  //   }, 1000),
  // );
});

export const getCurrentListFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://i8cck8k1if.execute-api.eu-west-1.amazonaws.com/BlackList/GetLatestVisits",
      {
        method: "POST",
        body: JSON.stringify({
          partition_key: "glra15g5m8",
          checkin_at: payload.checkin_at,
        }),
      },
    );

    let result = await response.json();
    return JSON.parse(result.body);
  } catch (e) {
    console.error(e);
  }

  // return new Promise((resolve, reject) =>
  //   setTimeout(() => {
  //     console.log("payload", payload);
  //     return resolve(fakeData);
  //   }, 1000),
  // );
});

export const sendEditVisitorFx = createEffect().use(async (payload) => {
  return new Promise((resolve, reject) =>
    setTimeout(() => {
      console.log("payload", payload);
      return resolve(true);
    }, 1000),
  );
});

export const updateBlackListFx = createEffect().use(async (payload) => {
  try {
    const response = fetch(
      "https://i8cck8k1if.execute-api.eu-west-1.amazonaws.com/BlackList/UpdateItems",
      {
        method: "POST",
        body: JSON.stringify({
          partition_key: payload?.partition_key
            ? payload.partition_key
            : "test77a7a7",
          sort_key: payload?.sort_key
            ? payload.sort_key
            : "008164e9-dfb3-4946-b26c-47ad027cb80e#1726912543",
          // wrong_person: payload?.wrong_person ? payload.wrong_person : null,
          // comments: payload?.comments ? payload.comments : null,
          pass: payload.pass,
        }),
      },
    );

    let result = await response.json();
    return result;
  } catch (e) {
    console.error(e);
  }
});
