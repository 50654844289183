import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Tooltip,
  Upload,
} from "antd";
import { useUnit } from "effector-react";
import {
  $showCreateModal,
  $userListPlaceId,
  changeShowCreateModalEv,
  createEduUserEv,
  createEduUserFx,
  createUserFx,
} from "../../models/userListModel";
import { UploadOutlined } from "@ant-design/icons";
import style from "./SchoolUserList.module.css";
import React, { useState } from "react";
import locale from "antd/es/date-picker/locale/ru_RU";
import EmpForm from "./Subforms/EmpForm";
import StudentForm from "./Subforms/StudentForm";
import {
  defaultEndWorkTime,
  defaultStartWorkTime,
} from "../../dictionaries/edu-dictionary";

export default function CreateUserModal() {
  const showCreateModal = useUnit($showCreateModal);
  const changeShowCreateModal = useUnit(changeShowCreateModalEv);
  const loading = useUnit(createEduUserFx.pending);
  const createEduUser = useUnit(createEduUserEv);
  const [form] = Form.useForm();
  const [saveDisabled, setSaveDisabled] = useState(true);

  const emp_category = Form.useWatch(["properties", "emp_category"], form);

  const onClose = () => {
    changeShowCreateModal(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const payload = {
        ...values,
        properties: {
          ...values.properties,
          sort_key: "zasa05e1p1",
          user_birth_day: values.user_birthday.get("date"),
          user_birth_month: values.user_birthday.get("month"),
          user_birth_year: values.user_birthday.get("year"),
          created_type: "face-onboarding",
          emp_date: values.properties.emp_date
            ? values.properties.emp_date.format("YYYY-MM-DD")
            : null,
          dis_date: values.properties.emp_date
            ? values.properties.emp_date.format("YYYY-MM-DD")
            : null,
          subject: values.properties.subject
            ? values.properties.subject.split(", ")
            : null,
          start_work_time: values.properties.start_work_time
            ? Object.fromEntries(
                Object.entries(values.properties.start_work_time).map(
                  ([key, value]) => {
                    return [key, value.format("HH:mm")];
                  },
                ),
              )
            : defaultStartWorkTime,
          end_work_time: values.properties.end_work_time
            ? Object.fromEntries(
                Object.entries(values.properties.end_work_time).map(
                  ([key, value]) => {
                    return [key, value.format("HH:mm")];
                  },
                ),
              )
            : defaultEndWorkTime,
        },
        place_id: "zasa05e1p1",
        data_url: [reader.result],
      };
      delete payload.user_birthday;
      if (values.properties.emp_category === "Ученик") {
        delete payload.properties.emp_date;
        delete payload.properties.dis_date;
        delete payload.properties.subject;
        delete payload.properties.start_work_time;
        delete payload.properties.end_work_time;
        payload.properties = {
          ...payload.properties,
          user_name: payload.user_name,
          user_surname: payload.user_surname,
          data_url: payload.data_url,
        };
      }
      createEduUser(payload);
    });
    reader.readAsDataURL(values.data_url);
  };

  const normFile = (info) => {
    return info?.file;
  };

  return (
    <Modal
      open={showCreateModal}
      onCancel={onClose}
      title={"Добавить сотрудника"}
      onOk={form.submit}
      footer={null}
      width={"80vw"}
      destroyOnClose
      afterClose={() => form.resetFields()}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout={"vertical"}
        style={{
          marginTop: "16px",
        }}
        onValuesChange={(changed, all) => {
          if (Object.values(all).some((item) => !item)) {
            if (!saveDisabled) setSaveDisabled(true);
          } else {
            if (saveDisabled) setSaveDisabled(false);
          }
        }}
      >
        <Form.Item name="data_url" getValueFromEvent={normFile}>
          <Upload beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>Загрузить</Button>
          </Upload>
        </Form.Item>
        <Form.Item name={["properties", "emp_category"]} label={"Статус"}>
          <Radio.Group>
            <Radio value={"Сотрудник"}>Сотрудник</Radio>
            <Radio value={"Ученик"}>Ученик</Radio>
          </Radio.Group>
        </Form.Item>
        <Row justify={"space-between"} gutter={8}>
          <Col span={8}>
            <Form.Item name={"user_name"} label={"Имя"}>
              <Input placeholder={"Введите имя"} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={"user_surname"} label={"Фамилия"}>
              <Input placeholder={"Введите фамилию"} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={"user_birthday"} label={"День рождения"}>
              <DatePicker locale={locale} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name={["properties", "user_gender"]} label={"Пол"}>
          <Radio.Group>
            <Radio value={"Female"}>Женщина</Radio>
            <Radio value={"Male"}>Мужчина</Radio>
          </Radio.Group>
        </Form.Item>
        <Row justify={"space-between"} gutter={8}>
          <Col span={8}>
            <Form.Item name={["properties", "user_address"]} label={"Адрес"}>
              <Input placeholder={"Введите адрес"} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={["properties", "user_mobile"]}
              label={"Телефонный номер"}
            >
              <Input placeholder={"Введите номер телефона"} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={["properties", "user_email"]} label={"Почта"}>
              <Input placeholder={"Введите почту"} />
            </Form.Item>
          </Col>
        </Row>
        {emp_category === "Сотрудник" ? (
          <EmpForm form={form} />
        ) : (
          emp_category === "Ученик" && <StudentForm />
        )}
      </Form>
      <Button
        onClick={form.submit}
        className={style.modal_button}
        type={"primary"}
        style={{ marginRight: "8px" }}
        disabled={saveDisabled}
        loading={loading}
      >
        Сохранить
      </Button>
      <Button onClick={onClose} className={style.modal_button}>
        Отмена
      </Button>
    </Modal>
  );
}
