import {createEffect} from "effector";
import AWS from "aws-sdk";

export const getSearchByIdDataFx = createEffect().use(async (payload) => {
	try{
		const response = await fetch(
			'https://wg8x98quyf.execute-api.eu-west-1.amazonaws.com/PortalAPI/unrecognized-images',
			{
				method: 'POST',
				body: JSON.stringify({
					client_id: payload?.client_id ? payload.client_id : '',
				})
			}
		)

		const result = await response.json()
		console.log('getSearchByIdDataFx:: Success', result)
		return result.body
	} catch (e){
		console.error(e)
	}
})

export const disassociateUserFx = createEffect().use(async (payload) => {
	try{
		const response = await fetch(
			'https://k3yidf1b5l.execute-api.eu-west-1.amazonaws.com/default/dev-portal-disassociate-faces-lambda',
			{
				method: 'POST',
				body: JSON.stringify(payload)
			}
		)

		const result = await response.json()
		console.log('deassociateUserFx:: Success', result)
		return result.body
	} catch (e){
		console.error(e)
	}
})
