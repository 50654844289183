import { createStore } from "effector";
import dayjs from "dayjs";

export const $duplicatesStore = createStore([]);
$duplicatesStore.watch((state) => console.log("$duplicatesStore", state));

export const $duplicatesDate = createStore(dayjs());
$duplicatesDate.watch((state) => console.log("$duplicatesDate", state));

export const $duplicatesPlace = createStore("amaa25d7c9");
$duplicatesPlace.watch((state) => console.log("$duplicatesPlace", state));

export const $lastEvaluatedkey = createStore(null);
