import "./App.css";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import {
  withAuthenticator,
  Button,
  Heading,
  Link,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import styles from "@aws-amplify/ui-react/styles.css";

import { Amplify } from "aws-amplify";
import {
  fetchUserAttributes,
  fetchAuthSession,
  signIn,
  signOut,
} from "aws-amplify/auth";
// import { Auth } from 'aws-amplify';

import Foqus_logo from "./images/Foqus_white.png";
import Foqus_mini from "./images/FOQUS_logo_mini.png";
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  VideoCameraOutlined,
  TeamOutlined,
  UserOutlined,
  LogoutOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { ConfigProvider, Layout, Menu, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import SubMenu from "antd/es/menu/SubMenu";
import Icon from "antd/es/icon";
import AWS from "aws-sdk";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUnit } from "effector-react";
import {
  $userGroups, setUserAttributesEv,
  setUserGroupsEv,
  setUserKeysEv,
} from "./models/userModel";
import { $isDarkTheme } from "./models/registrationModel";
import Login from "./pages/CustomLogin/CustomLogin";
import { Header } from "./pages/CustomLogin/Header";
import { SignInHeader } from "./pages/CustomLogin/SignInHeader";
import { SignInFooter } from "./pages/CustomLogin/SignInFooter";
import { Footer } from "./pages/CustomLogin/Footer";
import { getDuplicatesFx } from "./models/duplicatesModel";
import {
  disassociateUserFx,
  getSearchByIdDataFx,
} from "./models/searchByIdModel";
import { main_theme_config } from "./main_theme_config";
import {getAWSCredentials} from "./utils/utils";
import inactivityHandler from "./utils/InactivityHandler";
import { getBadImagesFx } from "./models/badImagesModel";
import {ListObjectsV2Command, S3Client} from '@aws-sdk/client-s3';
const { Sider } = Layout;

const components = {
  Header,
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter,
  },
  Footer,
};

Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: "eu-west-1_BSw1uKjrC",
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: "1objh42gfqdiprbe6nfb2u5fbf",
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: "eu-west-1:429c297e-d491-4914-87dc-0b2a7bc3c1b5",
      // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
      // allowGuestAccess: true,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      // signUpVerificationMethod: 'code', // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: "https://foqus-portal.auth.eu-west-1.amazoncognito.com",
          scopes: [
            "phone",
            "email",
            "profile",
            "openid",
            "custom:DashboardId",
            "custom:PlaceId",
            "custom:group",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: ["http://localhost:3000/dashboard"],
          redirectSignOut: ["http://localhost:3000/login"],
          responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    },
  },
});

// You can get the current config object
// const currentConfig = Amplify.getConfig();

function App() {
  const { user } = useAuthenticator();

  // const dashboardRef = useRef([]);
  // const [dashboardId, setDashboardId] = useState('');
  // const [embeddedDashboard, setEmbeddedDashboard] = useState(null);
  // const [dashboardUrl, setDashboardUrl] = useState(null);
  // const [embeddingContext, setEmbeddingContext] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  // const [userName, setUserName] = useState('');
  const [userGroup, setUserGroup] = useState([]);
  // const [dashboardData, setDashboardData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isDarkTheme = useUnit($isDarkTheme);
  const getDuplicatesPending = useUnit(getDuplicatesFx.pending);
  const getSearchByIdDataPending = useUnit(getSearchByIdDataFx.pending);
  const disassociateUserPending = useUnit(disassociateUserFx.pending);
  const getBadImagesPending = useUnit(getBadImagesFx.pending);

  const loading =
    getDuplicatesPending ||
    getSearchByIdDataPending ||
    disassociateUserPending ||
    getBadImagesPending;

  const setUserGroups = useUnit(setUserGroupsEv);
  const setUserKeys = useUnit(setUserKeysEv);
  const setUserAttributes = useUnit(setUserAttributesEv)
  const userGroups = useUnit($userGroups);

  // console.log('im at App.js')
  inactivityHandler();

  function useInitializeUserGroups(user) {
    useEffect(() => {
      // inactivityHandler()
      // const userSession = user.getSignInUserSession()
      // console.log('userSession: ', userSession)
      const initializeUserGroups = async () => {
        try {
          if (user) {
            const session = await fetchAuthSession();
            const userAttributes = await fetchUserAttributes();
            //fixme comment s3
            try {
              const credentials = session.credentials;
              const { accessKeyId, secretAccessKey, sessionToken } = credentials;

              // Создаем экземпляр клиента S3 с временными креденшелами
              const s3 = new S3Client({
                region: 'eu-west-1', // Укажи свой регион
                credentials: {
                  accessKeyId,
                  secretAccessKey,
                  sessionToken,
                },
              });

              // Выполняем запрос на список объектов в S3
              const command = new ListObjectsV2Command({
                Bucket: 'test-lab-bucket-s3', // Укажи имя твоего бакета
              });

              const data = await s3.send(command);
              console.log('S3 Objects:', data);
            } catch (error) {
              console.error('Error accessing S3:', error);
            }
            const group = session.tokens.accessToken.payload["cognito:groups"];
            setUserGroups(group || []);
            setUserAttributes(userAttributes)
            console.log('session: ', session)
            // const keys = await getAWSCredentials()
            // setUserKeys(keys.credentials.credentials)
          }
        } catch (error) {
          console.error("Error fetching user groups:", error);
          setUserGroups([]);
        }
      };

      initializeUserGroups();
    }, [user, setUserGroups]);
  }
  //
  // useEffect(() => {
  //   const fetchUserGroup = async () => {
  //     try {
  //       const user = await fetchAuthSession();
  //       const group = user.tokens.accessToken.payload['cognito:groups'];
  //       setUserGroups(group || [])
  //     } catch (error) {
  //       console.error('Error fetching user group:', error);
  //     }
  //   };
  //
  //   fetchUserGroup();
  // }, []);

  useInitializeUserGroups(user);
  //
  //   async function handleFetchUserAttributes() {
  //     try {
  //       const userAttributes = await fetchUserAttributes();
  //       // setDashboardId(userAttributes['custom:DashboardId']);
  //       // await changeDashboard(userAttributes['custom:DashboardId']);
  //       console.log(userAttributes);
  //       setUserName(userAttributes['name']);
  //       return userAttributes
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //
  //
  //
  //   useEffect(() => {
  //   const timeout = setTimeout(() => {
  //
  //     fetch("https://9iz15iyde3.execute-api.eu-west-1.amazonaws.com/embed/anonymous-embed-v2"
  //     ).then((response) => response.json()
  //     ).then((response) => {
  //       setDashboardUrl(response.EmbedUrl)
  //     })
  //   }, 10);
  //   return () => clearTimeout(timeout);
  // }, []);
  //
  // const createContext = async () => {
  //   const context = await createEmbeddingContext();
  //   setEmbeddingContext(context);
  // }
  //
  // useEffect(() => {
  //   if (dashboardUrl) { createContext() }
  // }, [dashboardUrl])
  //
  // useEffect(() => {
  //   if (embeddingContext) { embed(); }
  // }, [embeddingContext])
  //
  // const embed = async () => {
  //
  //   let options = {
  //     url: dashboardUrl,
  //     container: dashboardRef.current,
  //     height: "500px",
  //     width: "600px",
  //   };
  //   const userInfo = await handleFetchUserAttributes()
  //
  //   // let newUrl = "https://eu-west-1.quicksight.aws.amazon.com/embed/3bfdf79a968847789735d5d10d5d6570/dashboards/478ed133-4942-4841-8e41-ac3402ef4494?code=AYABeByuSyA7V3oLYsR07pCcR0QAAAABAAdhd3Mta21zAEthcm46YXdzOmttczpldS13ZXN0LTE6MzU2MzA1MTgzMDczOmtleS83ZTYwMTNkMC0xM2I0LTRmODItYmI1NC00YjllMTM4NzczNjYAuAECAQB4IZt0zcr71c1qotPywu6knKQF9RerzWvIw9ebvDgZ4T0BwKSEFgh8_DYRiYK-bHFFPAAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDF9Xuh8dZI6X-MUYRQIBEIA7r9rXlXf8-PkmWUC7Na4wswX5xzH9d7KOHaguUOb3NWCBAWC-RybQxAY4kCtjGogkQZSaRqX2fYkCTlkCAAAAAAwAABAAAAAAAAAAAAAAAAAAhDj3G762sZEVALtwcrFvhv____8AAAABAAAAAAAAAAAAAAABAAAAm20jOJ2M6doZXOS8m6HYcjlmwpiJeYdAE-ptzH6nFappA06SWjoWsBs1dEi5zRGEOpWDSM9oZm06KoX1f3QdrIphH86MrGCMybIAsu148eO8jqcml_P_YRDvDqOejL7OX2yO61WqpJHEZSg-eC-Z5xPyLnH4MdAcckrxFgyi4JY6GEQLJyIHzyniX6WFZCrAjv86CeRcEwkczsDVtiqg_-nwT0paBIj707PCoQ%3D%3D&identityprovider=quicksight&isauthcode=true";
  //
  //   let replacedUrl = options.url.replace(/\/([^\/?]+)\?/, "/" + userInfo['custom:DashboardId'] + "?");
  //   options.url = replacedUrl
  //   const newEmbeddedDashboard = await embeddingContext.embedDashboard(options);
  //
  //   setEmbeddedDashboard(newEmbeddedDashboard);
  // };
  //
  // // useEffect(() => {
  // //   if (embeddedDashboard) {
  // //     embeddedDashboard.navigateToDashboard(dashboardId, {})
  // //   }
  // // }, [dashboardId])
  //
  // const changeDashboardBySelect = async (e) => {
  //   const dashboardId = e.target.value
  //   setDashboardId(dashboardId)
  // }
  //
  // const changeDashboardByEffect = async (e) => {
  //   const dashboardId = e
  //   setDashboardId(dashboardId)
  // }
  //
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const userDashboardId= await handleFetchUserAttributes();
  //       console.log(userDashboardId)
  //       changeDashboardByEffect(userDashboardId)
  //     } catch (err) {
  //       console.log('Error occured when fetching books');
  //     }
  //   })();
  // }, []);
  //
  // function getItem(label, key, icon, children) {
  //   return {
  //     key,
  //     icon,
  //     children,
  //     label,
  //   };
  // }
  // const items = [
  //   getItem('Dashboard', '1', <PieChartOutlined />),
  //
  //   // getItem('Option 2', '2', <DesktopOutlined />),
  //   // getItem('User', 'sub1', <UserOutlined />, [
  //   //   getItem('Tom', '3'),
  //   //   getItem('Bill', '4'),
  //   //   getItem('Alex', '5'),
  //   // ]),
  //   // getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
  //   // getItem('Files', '9', <FileOutlined />),
  // ];
  const onMenuClick = (e) => {
    if (e.key !== "signout") {
      navigate(e.key);
    } else {
      navigate("/");
      signOut();
    }
  };
  const myWidth = 210;

  // if(user) {
  console.log("user: - ", user);
  console.log("userGroups: - ", userGroups);
  // async function initializeUserGroups() {
  //   if (!userGroups) {
  //     try {
  //       // Fetch the current authentication session
  //       const user = await fetchAuthSession();
  //
  //       // Retrieve the user's groups from the access token payload
  //       const group = user.tokens.accessToken.payload['cognito:groups'];
  //
  //       // Set the user groups to the retrieved groups or an empty array if no groups are found
  //       setUserGroups(group || []);
  //     } catch (error) {
  //       console.error('Error fetching user groups:', error);
  //       setUserGroups([]);
  //     }
  //   }
  // }

  return user ? (
    <ConfigProvider theme={main_theme_config}>
      <Layout
        style={{
          minHeight: "100svh",
        }}
      >
        {!location.pathname.includes("registration") ? (
          <Sider
            width={myWidth}
            // style={{
            //   width: '500px !important'
            // }}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            <div
              className="demo-logo-vertical"
              style={{
                padding: "15px",
              }}
            >
              {collapsed ? (
                <img style={{ width: "100%" }} src={Foqus_mini} />
              ) : (
                <img style={{ width: "100%" }} src={Foqus_logo} />
              )}
            </div>
            {/*{userGroups?.includes('portal_admin') ?*/}
            {/*  <div*/}
            {/*    className="demo-logo-vertical"*/}
            {/*    style={{*/}
            {/*      color: 'white',*/}
            {/*      textAlign: 'center',*/}
            {/*      paddingBottom: '15px'*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Admin permissions*/}
            {/*  </div>*/}
            {/*  : ''*/}
            {/*}*/}
            <div className={'menu_wrapper'}>
              <Menu
                style={{ color: "#ffffff" }}
                theme="dark"
                // defaultSelectedKeys={['dashboard']}
                mode="inline"
                onClick={onMenuClick}
                selectedKeys={
                  window.location.pathname.split("/")[1].length > 0
                    ? window.location.pathname.split("/").slice(1)
                    : ["demo-dashboard"]
                }
              >
                {userGroups?.includes("foqus_portal_demo") ||
                userGroups?.includes("portal_admin") ? (
                  <Menu.Item style={{ color: "#ffffff" }} key="quicksight-q">
                    <PieChartOutlined />
                    <span>Your AI Assistant</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {userGroups?.includes("foqus_portal_demo") ||
                userGroups?.includes("portal_admin") ? (
                  <Menu.Item style={{ color: "#ffffff" }} key="hr-event">
                    <PieChartOutlined />
                    <span>HR Event</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {userGroups?.includes("foqus_portal_demo") ||
                userGroups?.includes("portal_admin") ? (
                  <Menu.Item style={{ color: "#ffffff" }} key="demo-dashboard">
                    <PieChartOutlined />
                    <span>Demo Dashboards</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
                <Menu.Item style={{ color: "#ffffff" }} key="dashboard">
                  <PieChartOutlined />
                  <span>Dashboard</span>
                </Menu.Item>
                {userGroups?.includes("foqus_portal_demo") ||
                userGroups?.includes("portal_admin") ? (
                  <Menu.Item key="demo-video" style={{ color: "#ffffff" }}>
                    <VideoCameraOutlined />
                    <span>DemoVideo</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {userGroups?.includes("portal_admin") ? (
                  <Menu.Item key={"testing"} style={{ color: "#ffffff" }}>
                    <TableOutlined />
                    <span>Testing</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {userGroups?.includes("portal_admin") ? (
                  <Menu.Item key={"testing-client"} style={{ color: "#ffffff" }}>
                    <TableOutlined />
                    <span>Testing Client</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {userGroups?.includes("portal_admin") ? (
                  <Menu.Item
                    key={"unrecognized-testing"}
                    style={{ color: "#ffffff" }}
                  >
                    <TableOutlined />
                    <span>Unrecognized testing</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {userGroups?.includes("portal_admin") ? (
                  <Menu.Item key={"search-by-id"} style={{ color: "#ffffff" }}>
                    <TableOutlined />
                    <span>Search by ID</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {userGroups?.includes("portal_admin") ? (
                  <Menu.Item key={"duplicates"} style={{ color: "#ffffff" }}>
                    <TableOutlined />
                    <span>Duplicates</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {userGroups?.includes("portal_admin") ? (
                  <Menu.Item key={"user-list"} style={{ color: "#ffffff" }}>
                    <UserOutlined />
                    <span>User list</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {userGroups?.includes("portal_admin") ||
                (userGroups?.includes("Customer") &&
                  (userGroups?.includes("Amanat") || userGroups?.includes("Kolibrix"))) ? (
                  <Menu.Item
                    key={"client-user-list"}
                    style={{ color: "#ffffff" }}
                  >
                    <UserOutlined />
                    <span>Список сотрудников</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {(Array.isArray(userGroups) &&
                  userGroups.includes("Customer") &&
                  userGroups.includes("Aubakirova")) ||
                (Array.isArray(userGroups) &&
                  userGroups.includes("portal_admin")) ? (
                  <Menu.Item key={"user-list-edu"} style={{ color: "#ffffff" }}>
                    <UserOutlined />
                    <span>Школьные сотрудники</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {/*FIXME take perms check from EDU item once restyle is accepted*/}
                {(Array.isArray(userGroups) &&
                  userGroups.includes("portal_admin")) ? (
                  <Menu.Item key={"user-list-edu-restyle"} style={{ color: "#ffffff" }}>
                    <UserOutlined />
                    <span>(D)Школьные сотрудники</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {userGroups?.includes("portal_admin") ? (
                  <Menu.Item key={"black-list"} style={{ color: "#ffffff" }}>
                    <UserOutlined />
                    <span>Black List</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {userGroups?.includes("portal_admin") ? (
                  <Menu.Item key={"bad-images"} style={{ color: "#ffffff" }}>
                    <UserOutlined />
                    <span>Bad Images</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
                {userGroups?.includes("portal_admin") ? (
                  <Menu.Item key={"portal-users"} style={{ color: "#ffffff" }}>
                    <UserOutlined />
                    <span>Portal Users</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
              </Menu>
              <div
                key="signout"
                style={{ color: "#ffffff" }}
                onClick={signOut}
                className={'custom_menu_item'}
              >
                <LogoutOutlined />
                <span>Sign Out</span>
              </div>
            </div>
          </Sider>
        ) : (
          ""
        )}
        <Layout
          style={{
            backgroundColor: isDarkTheme ? "#232323" : "#FFFFFF",
          }}
        >
          <Spin spinning={loading} className={"main_spinner"}>
            <Content
              style={{
                margin: "16px 16px",
              }}
            >
              {/*<Heading level={1}>Hello, {userName}</Heading>*/}
              <Outlet />
              {/*<div ref={dashboardRef}/>*/}
              {/*<div style={styles.container}>*/}
              {/*  <Heading level={1}>Hello {user.email}</Heading>*/}
              {/*  <Button onClick={signOut}>Sign out</Button>*/}
              {/*  <h2>Amplify Todos</h2>*/}
              {/*  ...*/}
              {/*</div>*/}
            </Content>
          </Spin>
        </Layout>
      </Layout>
    </ConfigProvider>
  ) : (
    <Login />
  );
  // } else {
  //   return <Login />;
  // }
}

// export default withAuthenticator(App, {hideSignUp: true, components: components});
export default App;
