import { Button, Form, Input, Modal, Radio, Select, Upload } from "antd";
import {
  $chosenPotentialEmp,
  $userListPlaceId,
  changeChosenPotentialEmpEv,
  createEmpFx,
} from "../../models/userListModel";
import { useUnit } from "effector-react";
import { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import style from "./SchoolUserList.module.css";

const genderOptions = [
  {
    label: "Мужчина",
    value: "Male",
  },
  {
    label: "Женщина",
    value: "Female",
  },
];

const selectOptions = [
  {
    label: "Сотрудник",
    value: "Сотрудник",
  },
  {
    label: "Уволен",
    value: "Уволен",
  },
  {
    label: "Потенциальный сотрудник",
    value: "Потенциальный сотрудник",
  },
];

export default function MakeEmpModal() {
  const [form] = Form.useForm();
  const chosenPotentialEmp = useUnit($chosenPotentialEmp);
  const userListPlaceId = useUnit($userListPlaceId);
  const changeChosenPotentialEmp = useUnit(changeChosenPotentialEmpEv);
  const createEmp = useUnit(createEmpFx);
  const pending = useUnit(createEmpFx.pending);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const onClose = () => {
    changeChosenPotentialEmp(null);
    form.resetFields();
  };

  const onFinish = (values) => {
    createEmp({
      ...values,
      user_id: chosenPotentialEmp.user_id,
      place_id: userListPlaceId,
    });
  };

  useEffect(() => {
    form.setFieldValue("new_category", "Сотрудник");
  }, []);

  return (
    <Modal
      title={"Создать сотрудника из потенциального"}
      open={!!chosenPotentialEmp}
      onOk={form.submit}
      onCancel={onClose}
      footer={null}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout={"vertical"}
        style={{
          marginTop: "16px",
        }}
        onValuesChange={(changed, all) => {
          if (Object.values(all).some((item) => !item)) {
            if (!saveDisabled) setSaveDisabled(true);
          } else {
            if (saveDisabled) setSaveDisabled(false);
          }
        }}
      >
        <Form.Item name={"new_name"} label={"Имя"}>
          <Input placeholder={"Введите имя"} />
        </Form.Item>
        <Form.Item name={"new_surname"} label={"Фамилия"}>
          <Input placeholder={"Введите фамилию"} />
        </Form.Item>
        <Form.Item name={"new_department"} label={"Департамент"}>
          <Input placeholder={"Департамент"} />
        </Form.Item>
        <Form.Item name={"new_position"} label={"Должность"}>
          <Input placeholder={"Введите должность сотрудника"} />
        </Form.Item>
        <Form.Item name={"new_category"} label={"Статус"}>
          <Select
            options={selectOptions}
            placeholder={"Выбрать статус"}
            value={"Сотрудник"}
          />
        </Form.Item>
        <Form.Item name={"new_gender"} label={"Пол"}>
          <Radio.Group>
            <Radio value={"Female"}>Женщина</Radio>
            <Radio value={"Male"}>Мужчина</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
      <Button
        onClick={form.submit}
        className={style.modal_button}
        type={"primary"}
        style={{ marginRight: "8px" }}
        disabled={saveDisabled}
        loading={pending}
      >
        Сохранить
      </Button>
      <Button onClick={onClose} className={style.modal_button}>
        Отмена
      </Button>
    </Modal>
  );
}
