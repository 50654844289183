import {$testingFilters, $testingStartTableData, $testingTableData} from "./stores";
import {getTestingTableDataFx} from "./effects";
import {formatTableData} from "./utils";
import {sample} from "effector";
import {changeTestingFiltersEv, filterAfterApiCallEv} from "./events";

sample({
	clock: getTestingTableDataFx.doneData,
	fn: formatTableData,
	target: [$testingTableData, $testingStartTableData]
})

sample({
	source: $testingFilters,
	clock: changeTestingFiltersEv,
	fn: (source, clock) => {
		if(source[clock.field] !== clock.value) {
			return {
				...source,
				[clock.field]: clock.value
			}
		}
	},
	target: $testingFilters
})

sample({
	source: $testingFilters,
	clock: changeTestingFiltersEv,
	fn: (source, clock) => {
		return {
			...source,
			[clock.field]: clock.value
		}
	},
	target: getTestingTableDataFx
})

// sample({
// 	source: $testingStartTableData,
// 	clock: $testingFilters.updates,
// 	fn: (startData, filters) => {
// 		if(filters.checkin_status){
// 			return startData.filter(item => item.checkin_status === filters.checkin_status)
// 		}
// 		return startData
// 	},
// 	target: $testingTableData
// })

// sample({
// 	source: $testingFilters,
// 	clock: filterAfterApiCallEv,
// 	filter: (source, clock) => source.checkin_status,
// 	fn: (source, clock) => {
// 		return clock.filter(item => item.checkin_status === source.checkin_status)
// 	},
// 	target: $testingTableData
// })


