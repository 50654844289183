import {createEvent} from "effector";

export const toggleCreatePortalUserModalEv = createEvent();

export const sendCreatePortalUserEv = createEvent()

export const setEditUserEv = createEvent()
export const resetEditUserEv = createEvent()

export const sendEditPortalUserEv = createEvent()

export const setViewUserEv = createEvent()
export const resetViewUserEv = createEvent()

export const archiveUserEv = createEvent()

export const sendViewPortalUserEv = createEvent()

export const toggleCreateTenantModalEv = createEvent();

export const toggleCreatePlaceModalEv = createEvent()
export const setEditPlaceEv = createEvent()
export const resetEditPlaceEv = createEvent()
export const resetViewPortalPlaceEv = createEvent()

export const toggleCreateOrgModalEv = createEvent()
export const sendCreateOrgEv = createEvent()
export const setEditOrgEv = createEvent()
export const resetEditOrgEv = createEvent()
export const resetViewOrgEv = createEvent()

export const sendCreateTenantItemEv = createEvent()
export const sendEditTenantItemEv = createEvent()
export const sendViewTenantItemEv = createEvent()
export const sendDisableTenantItemEv = createEvent()
export const sendActivateTenantItemEv = createEvent()
