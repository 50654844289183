import {DatePicker, Select} from "antd";
import style from './CustomSelect.module.css'
import {dateFormat} from "../../models/testingModel/utils";
import {SearchOutlined, DownOutlined} from "@ant-design/icons";

const { RangePicker } = DatePicker;

export default function CustomSelect(props){
	const {options, onSelect, field, selectType, searchIcon, ...rest} = props

	const onChange = (value) => {
		onSelect({
			field: field,
			value: value,
		})
	}

	const onChangeDate = (value) => {
		onSelect({
			field: field,
			value: value.format(dateFormat)
		})
	}

	const onChangeDateRange = (value) => {
		onSelect({
			field: field,
			value: value.map(item => item.format(dateFormat))
		})
	}

	if(selectType === 'date'){
		return <DatePicker
			onChange={onChangeDate}
			{...rest}
		/>
	}
	if(selectType === 'dateRange'){
		return <RangePicker
			className={style.select}
			onChange={onChangeDateRange}
			{...rest}
		/>
	}
	return <Select
		className={style.select}
		options={options}
		onChange={onChange}
		suffixIcon={searchIcon ? <SearchOutlined /> : <DownOutlined />}
		{...rest}
	/>
}
