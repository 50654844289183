import React from 'react';
import {Form, Modal} from "antd";
import PortalPlacesForm from "./PortalPlacesForm";
import {useUnit} from "effector-react";
import {
	$showCreatePlaceModal,
	sendCreateTenantItemEv,
	sendCreateTenantItemFx,
	toggleCreatePlaceModalEv
} from "../../../../models/portalUsersModel";

function CreatePlaceIdModal() {
	const show = useUnit($showCreatePlaceModal)
	const toggleCreatePlaceModal = useUnit(toggleCreatePlaceModalEv)
	const [createForm] = Form.useForm()
	const sendCreatePlace = useUnit(sendCreateTenantItemEv)
	const loading = useUnit(sendCreateTenantItemFx.pending)

	const onCancel = () => {
		toggleCreatePlaceModal(false)
		createForm.resetFields()
	}

	const onFinish = (values) => {
		sendCreatePlace(values)
	}

	return (
		<Modal
			open={show}
			onCancel={onCancel}
			footer={null}
			destroyOnClose
			title={'Добавить place_id'}
			width={557}
		>
			<PortalPlacesForm
				form={createForm}
				onFinish={onFinish}
				onCancel={onCancel}
				loading={loading}
				type={'create'}
			/>
		</Modal>
	);
}

export default CreatePlaceIdModal;
