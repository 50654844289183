import {createStore} from "effector";

const initialFilters = {
	client_id: 'amaa25d7c9',
	date: '2024.05.06'
}

export const $unrecognizedTestingTableData = createStore([])
$unrecognizedTestingTableData.watch(state => console.log('$unrecognizedTestingTableData', state))

export const $unrecognizedFilters = createStore(initialFilters)
$unrecognizedFilters.watch(state => console.log('$unrecognizedFilters', state))
