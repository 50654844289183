import {
  $badImagesData,
  $badImagesDate,
  $badImagesPlace,
  $lastEvaluatedkey,
} from "./stores";
import { getBadImagesFx, getMoreBadImagesFx } from "./effects";
import { sample } from "effector";
import {
  changeBadImagesDateEv,
  changeBadImagesPlaceEv,
  loadMoreEv,
} from "./events";

$badImagesData.on(getBadImagesFx.doneData, (_, payload) => payload.data);
$lastEvaluatedkey
  .on(getBadImagesFx.doneData, (_, payload) => payload.LastEvaluatedKey)
  .on(getMoreBadImagesFx.doneData, (_, payload) => {
    if (payload.data.length > 0) {
      return payload.LastEvaluatedKey;
    }
    return null;
  });

sample({
  clock: changeBadImagesDateEv,
  target: $badImagesDate,
});

sample({
  clock: changeBadImagesPlaceEv,
  fn: (clock) => clock.value,
  target: $badImagesPlace,
});

sample({
  source: $badImagesPlace,
  clock: $badImagesDate.updates,
  fn: (source, clock) => {
    return {
      place_id: source,
      date_gsi: clock.format("DD-MM-YYYY"),
    };
  },
  target: getBadImagesFx,
});

sample({
  source: $badImagesDate,
  clock: $badImagesPlace.updates,
  fn: (source, clock) => {
    return {
      place_id: clock,
      date_gsi: source.format("DD-MM-YYYY"),
    };
  },
  target: getBadImagesFx,
});

sample({
  source: [$badImagesDate, $badImagesPlace, $lastEvaluatedkey],
  clock: loadMoreEv,
  fn: ([date_gsi, place_id, lastEvaluatedKey]) => {
    return {
      date_gsi,
      place_id,
      lastEvaluatedKey,
    };
  },
  target: getMoreBadImagesFx,
});
