import React, {useEffect, useRef, useState} from "react";
import style from "./BlackList.module.css";
import { Button, Checkbox, DatePicker, Segmented, Select } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import {
  $blackListData,
  $blackListFilters,
  $currentList,
  $showFilters,
  $tab,
  addUserToCurrentEv,
  changeBlackListFiltersEv,
  changeEditModalEv,
  changeHistoryModalEv,
  changeShowFiltersEv,
  changeTabEv,
  getBlackListDataFx,
  passVisitorEv,
  rejectVisitorEv,
  removeUserFromCurrentEv,
  resetFiltersEv,
  wrongPersonEv,
} from "../../models/blackListModel";
import { useUnit } from "effector-react";
import {
  BanSvg,
  CommentsSvg,
  FiltersSvg,
  NewSvg,
  VipSvg,
  VisitsSvg,
} from "./BlackListIcons";
import ImageFromS3 from "../../components/ImageFromS3/ImageFromS3";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined,
  CheckOutlined,
  StarOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { getStatusIcon } from "./utils";
import { $userGroups } from "../../models/userModel";
import { useNavigate } from "react-router-dom";
import VisitorHistoryModal from "./VisitorHistoryModal";
import VisitorEditModal from "./VisitorEditModal";

const periodOptions = [
  {
    label: "до 21:00",
    value: 0,
  },
  {
    label: "21:00 - 23:00",
    value: 1,
  },
  {
    label: "23:00 - 01:00",
    value: 2,
  },
  {
    label: "01:00+",
    value: 3,
  },
];

function BlackList(props) {
  const tab = useUnit($tab);
  const changeTab = useUnit(changeTabEv);
  const blackListFilters = useUnit($blackListFilters);
  const changeBlackListFilters = useUnit(changeBlackListFiltersEv);
  const resetFilters = useUnit(resetFiltersEv);
  const showFilters = useUnit($showFilters);
  const changeShowFilters = useUnit(changeShowFiltersEv);
  const blackListData = useUnit($blackListData);
  const currentList = useUnit($currentList);
  const userGroups = useUnit($userGroups);
  const navigate = useNavigate();
  const addUserToCurrent = useUnit(addUserToCurrentEv);

  const switchCheckBox = (value) => {
    let field = "status";
    if (value === "male" || value === "female") {
      field = "gender";
    }
    changeBlackListFilters({
      field,
      value,
    });
  };

  const segmentedOptions = [
    {
      label: "Посетители сейчас",
      value: 0,
      disabled: currentList.length === 0,
    },
    {
      label: "История посещений",
      value: 1,
    },
  ];

  const onChangeSegmented = (value) => {
    changeTab(value);
    changeShowFilters(false);
  };

  useEffect(() => {
    if (blackListData.length === 0) {
      getBlackListDataFx();
    }
  }, []);

  // useEffect(() => {
  //   if (currentList.length === 0) {
  //     getCurrentListFx();
  //   }
  // }, []);

  useEffect(() => {
    if (Array.isArray(userGroups) && !userGroups.includes("portal_admin")) {
      navigate("/dashboard");
    }
  }, [userGroups, navigate]);

  return (
    <div style={{ position: "relative" }}>
      <div className={style.filters_block}>
        {/*<Button onClick={addUserToCurrent}>TEST</Button>*/}
        <div className={style.blacklist_controls}>
          <Segmented
            options={segmentedOptions}
            onChange={onChangeSegmented}
            value={tab}
          />
          {tab === 1 && (
            <div className={style.blacklist_controls_filters}>
              <Button
                className={style.filters_button}
                icon={<FiltersSvg />}
                onClick={changeShowFilters}
              />
              <div className={style.blacklist_filters_text}>Фильтр</div>
              <DatePicker
                locale={locale}
                className={style.filters_datepicker}
              />
            </div>
          )}
        </div>
        {showFilters && (
          <>
            <div className={style.blacklist_filters_wrapper}>
              <div className={style.filter_block}>
                <div className={style.filter_title}>Пол</div>
                <div className={style.checkbox_wrapper}>
                  <Checkbox
                    checked={blackListFilters.gender.includes("male")}
                    onChange={() => switchCheckBox("male")}
                  >
                    Мужчина
                  </Checkbox>
                  <Checkbox
                    checked={blackListFilters.gender.includes("female")}
                    onChange={() => switchCheckBox("female")}
                  >
                    Женщина
                  </Checkbox>
                </div>
              </div>
              <div className={style.filter_block}>
                <div className={style.filter_title}>Статус</div>
                <div className={style.checkbox_wrapper}>
                  <Checkbox
                    checked={blackListFilters.status.includes("visitor")}
                    onChange={() => switchCheckBox("visitor")}
                  >
                    Посетитель
                  </Checkbox>
                  <Checkbox
                    checked={blackListFilters.status.includes("vip")}
                    onChange={() => switchCheckBox("vip")}
                  >
                    VIP
                  </Checkbox>
                  <Checkbox
                    checked={blackListFilters.status.includes("blacklist")}
                    onChange={() => switchCheckBox("blacklist")}
                  >
                    Черный список
                  </Checkbox>
                </div>
              </div>
              <div className={style.filter_block}>
                <div className={style.filter_title}>Промежуток времени</div>
                <Select
                  className={style.period_select}
                  value={blackListFilters.period}
                  placeholder={"Выбрать"}
                  options={periodOptions}
                  mode={"multiple"}
                  maxTagCount="responsive"
                  maxTagTextLength={16}
                  allowClear
                  onChange={(value) => {
                    changeBlackListFilters({
                      field: "period",
                      value,
                    });
                  }}
                />
              </div>
            </div>
            <div className={style.clear_filters} onClick={resetFilters}>
              Сбросить фильтр
            </div>
          </>
        )}
      </div>
      {tab === 1 ? (
        <>
          <div
            className={style.blacklist_clients_wrapper}
            style={{
              paddingTop: showFilters ? "216px" : "93px",
            }}
          >
            {blackListData.map((visitor, index) => {
              return (
                <VisitorCard
                  key={`black-list-visitor-${visitor.user_id}`}
                  number={index + 1}
                  {...visitor}
                />
              );
            })}
          </div>
          <VisitorHistoryModal />
          <VisitorEditModal />
        </>
      ) : (
        <div className={style.current_visitor}>
          {currentList.length > 0 && <CurrentVisitorCard {...currentList[0]} />}
        </div>
      )}
    </div>
  );
}

export default BlackList;

const getCroppedImage =  (src, coords, ref) => {
  const image = new Image()
  image.src = src
  const context = ref.current.getContext('2d');

  image.onload = () => {
    context.drawImage(image, coords[0], coords[1], coords[2], coords[3], 0, 0, 234, 234)
  }
}

function VisitorCard(props) {
  const {
    id,
    number,
    checkin_status,
    presigned_url,
    number_of_visits,
    pass_true,
    pass_false,
    bases,
    face_bbox_pixels,
    frame_shape
  } = props;

  const changeHistoryModal = useUnit(changeHistoryModalEv);
  const changeEditModal = useUnit(changeEditModalEv);
  const canvasRef = useRef()

  const [isPreview, setIsPreview] = useState(false)

  useEffect(() => {
    if(canvasRef.current && face_bbox_pixels){
      getCroppedImage(presigned_url, face_bbox_pixels, canvasRef)
    }
  }, [canvasRef]);
  return (
    <div className={style.visitor_card}>
      <ImageFromS3 height={234} width={234} presigned_url={presigned_url}
                   preview={{
                     visible: isPreview,
                     onVisibleChange: setIsPreview
                   }}
      />
      <canvas ref={canvasRef} width={234} height={234} onClick={() => setIsPreview(true)} className={style.face_canvas}/>
      <div className={style.visitor_info_block}>
        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "12px" }}
        >
          <div className={style.visitor_info_part}>
            <div className={style.visitor_name}>Посетитель №{number}</div>
            <div className={style.visitor_status_wrapper}>
              <div
                className={style.visitor_status}
                style={{
                  backgroundColor:
                    checkin_status === "ban" ? "#FF4D4F" : "#EAEAEA",
                  color: checkin_status === "ban" ? "white" : "#C0C0C0",
                }}
              >
                <BanSvg />
              </div>
              <div
                className={style.visitor_status}
                style={{
                  backgroundColor:
                    checkin_status === "vip" ? "#F2AE00" : "#EAEAEA",
                  color: checkin_status === "vip" ? "#111111" : "#C0C0C0",
                }}
              >
                <VipSvg />
              </div>
              <div
                className={style.visitor_status}
                style={{
                  backgroundColor:
                    checkin_status === "visitor" ? "#4FD1D9" : "#EAEAEA",
                  color: checkin_status === "visitor" ? "white" : "#C0C0C0",
                }}
              >
                <NewSvg />
              </div>
            </div>
          </div>
          <div
            className={style.visitor_info_part}
            style={{ alignItems: "flex-start" }}
          >
            <div
              style={{
                display: "flex",
                columnGap: "24px",
              }}
            >
              <div>
                <div className={style.visits_label}>
                  <VisitsSvg />
                  Посещений:
                </div>
                <div className={style.visits_count}>{number_of_visits}</div>
              </div>
              <div>
                <div className={style.visits_label}>
                  <CheckCircleOutlined />
                  Пропустили:
                </div>
                <div className={style.visits_count}>{pass_true}</div>
              </div>
              <div>
                <div className={style.visits_label}>
                  <CloseCircleOutlined />
                  Отказов:
                </div>
                <div className={style.visits_count}>{pass_false}</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: 8,
              }}
            >
              {/*<Button*/}
              {/*  icon={<EyeOutlined />}*/}
              {/*  className={style.visitor_history_button}*/}
              {/*  onClick={() => changeHistoryModal(id)}*/}
              {/*>*/}
              {/*  История посещений*/}
              {/*</Button>*/}
              <Button className={style.visitor_history_button}>
                Не тот человек
              </Button>
            </div>
          </div>
        </div>
        <div className={style.visitor_bottom_part}>
          <div className={style.visitor_bases_wrapper}>
            {/*{bases.map((base) => {*/}
            {/*  if (base === "local") {*/}
            {/*    return (*/}
            {/*      <div*/}
            {/*        key={"visitor-base-local"}*/}
            {/*        className={style.visitor_local_base}*/}
            {/*      >*/}
            {/*        <CheckOutlined />*/}
            {/*        Локальная*/}
            {/*      </div>*/}
            {/*    );*/}
            {/*  }*/}
            {/*  if (base === "premium") {*/}
            {/*    return (*/}
            {/*      <div*/}
            {/*        key={"visitor-base-premium"}*/}
            {/*        className={style.visitor_premium_base}*/}
            {/*      >*/}
            {/*        <StarOutlined />*/}
            {/*        Premium база*/}
            {/*      </div>*/}
            {/*    );*/}
            {/*  }*/}
            {/*})}*/}
          </div>
          {/*<Button*/}
          {/*  icon={<EditOutlined />}*/}
          {/*  className={style.visitor_edit_button}*/}
          {/*  onClick={() => changeEditModal(id)}*/}
          {/*>*/}
          {/*  Редактировать*/}
          {/*</Button>*/}
        </div>
      </div>
    </div>
  );
}

function CurrentVisitorCard(props) {
  const {
    name,
    checkin_status,
    presigned_url,
    number_of_visits,
    pass_true,
    pass_false,
    bases,
    comments,
    user_id,
  } = props;

  const passVisitor = useUnit(passVisitorEv);
  const rejectVisitor = useUnit(rejectVisitorEv);
  const wrongPerson = useUnit(wrongPersonEv);
  const removeUserFromCurrent = useUnit(removeUserFromCurrentEv);

  return (
    <div>
      <div
        className={style.current_visitor_image_wrapper}
        style={{
          border: `7px solid ${checkin_status === "ban" ? "#FF4D4F" : checkin_status === "vip" ? "#F2AE00" : "#4FD1D9"}`,
        }}
      >
        <ImageFromS3 height={808} width={808} presigned_url={presigned_url} />
        {getStatusIcon(checkin_status, style.current_visitor_status)}
        <Button
          className={style.current_visitor_close_button}
          icon={<CloseOutlined />}
          onClick={removeUserFromCurrent}
        >
          Закрыть
        </Button>
      </div>
      <div className={style.current_visitor_visits}>
        <div>
          <div className={style.visits_label}>
            <VisitsSvg />
            Посещений:
          </div>
          <div className={style.visits_count}>{number_of_visits}</div>
        </div>
        <div>
          <div className={style.visits_label}>
            <CheckCircleOutlined />
            Пропустили:
          </div>
          <div className={style.visits_count}>{pass_true}</div>
        </div>
        <div>
          <div className={style.visits_label}>
            <CloseCircleOutlined />
            Отказов:
          </div>
          <div className={style.visits_count}>{pass_false}</div>
        </div>
        <div>
          <div className={style.visits_label}>
            <CommentsSvg />
            Комментарии:
          </div>
          {/*<div className={style.visits_count}>{comments.length}</div>*/}
        </div>
      </div>
      <div className={style.current_visitor_actions}>
        <Button
          className={[style.action_button, style.wrong_person].join(" ")}
          // onClick={wrongPerson}
        >
          Не тот человек
        </Button>
        <Button
          className={[style.action_button, style.pass].join(" ")}
          onClick={() => passVisitor(user_id)}
        >
          Пропустить
        </Button>
        <Button
          className={[style.action_button, style.reject].join(" ")}
          onClick={() => rejectVisitor(user_id)}
        >
          Отказать
        </Button>
      </div>
    </div>
  );
}
