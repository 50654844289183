import style from "./Registration.module.css";
import {Button} from "antd";
import {sendRegistrationDataEv, setFormClickedButtonEv} from "../../models/registrationModel";
import {useUnit} from "effector-react";

export default function NextButton(props) {

	const { currentPage, onClick, title, form } = props
	const setFormClickedButton = useUnit(setFormClickedButtonEv)

	const triggerFormSubmit = (button) => {
		setFormClickedButton(button)
		form.submit()
	}


	return <div className={[style.next_button_wrapper, currentPage === 1 ? style.double_button_hor : '', currentPage === 2 ? style.bigger_wrapper : ''].join(' ')}>
		{
			currentPage === 1 ?
				<Button className={style.get_10_button} onClick={() => triggerFormSubmit(1)}>
					Получить 10%
				</Button>
				:
				''
		}
		<Button className={style.next_button} onClick={currentPage === 1 ? () => triggerFormSubmit(2) : onClick}>
			{title}
		</Button>
		{
			currentPage === 2 ?
				<Button className={style.back_button} onClick={() => onClick(currentPage - 1)}>
					Назад
				</Button>
				:
				''
		}
	</div>
}
