import {Form, Input, Modal, Select} from "antd";
import {useUnit} from "effector-react";
import {$chosenUser, changeChosenUserEv, sendEditUserFx} from "../../models/userListModel";
import {useEffect} from "react";

const selectOptions = [
	{
		label: 'Сотрудник',
		value: 'Сотрудник'
	},
	{
		label: 'Уволен',
		value: 'Уволен'
	},
	{
		label: 'Потенциальный сотрудник',
		value: 'Потенциальный сотрудник'
	},
]

export default function UserListModal(){
	const [form] = Form.useForm()
	const chosenUser = useUnit($chosenUser)
	const changeChosenUser = useUnit(changeChosenUserEv)
	const sendEditUser = useUnit(sendEditUserFx)
	const pending = useUnit(sendEditUserFx.pending)

	const onFinish = (values) => {
		const payload = {
			...chosenUser,
			emp_department: values.new_department,
			emp_position: values.new_position,
			emp_category: values.new_category,
		}
		sendEditUser(payload)
	}

	const onClose = () => {
		changeChosenUser(null)
	}

	useEffect(() => {
		if(chosenUser) form.setFieldsValue({
			new_department: chosenUser?.emp_department,
			new_position: chosenUser?.emp_position,
			new_category: chosenUser?.emp_category
		})
	}, [chosenUser]);

	return <Modal
		title={'Edit user'}
		open={!!chosenUser}
		onOk={form.submit}
		onCancel={onClose}
		confirmLoading={pending}
	>
		<Form
			form={form}
			onFinish={onFinish}
		>
			<Form.Item name={'new_department'} label={'Department'}>
				<Input/>
			</Form.Item>
			<Form.Item name={'new_position'} label={'Position'}>
				<Input/>
			</Form.Item>
			<Form.Item name={'new_category'} label={'Category'}>
				<Select
					options={selectOptions}
				/>
			</Form.Item>
		</Form>
	</Modal>
}
