import React from "react";
import { Button, Form, Modal, Upload } from "antd";
import {
  $importFileModal,
  $importFilesErrors,
  changeImportFileModalEv,
  getEduTemplateEv,
  getEduTemplateFx, sendImportFilesEDUEv,
  sendImportFilesFx,
} from "../../models/userListModel";
import { useUnit } from "effector-react";
import { UploadOutlined } from "@ant-design/icons";
import style from "./SchoolUserList.module.css";

function ImportFilesModal(props) {
  const show = useUnit($importFileModal);
  const changeImportFileModal = useUnit(changeImportFileModalEv);
  const sendImportFiles = useUnit(sendImportFilesEDUEv);
  const importFilesErrors = useUnit($importFilesErrors);
  const [form] = Form.useForm();
  const loading = useUnit(sendImportFilesFx.pending);
  const getEduTemplate = useUnit(getEduTemplateEv);
  const tempalateLoading = useUnit(getEduTemplateFx.pending);

  const onClose = () => {
    changeImportFileModal(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    console.log(values);

    sendImportFiles({
      excel: new Blob(values.excel, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }),
      archive: new Blob(values.archive, { type: "application/zip" }),
      place_id: 'zasa05e1p1'
    });
  };

  const normFile = (info) => {
    return [info?.file];
  };

  // const formatError = (num) => {
  //   const var1 = `Фото не найдено: ${num}. Комментарий: мы не можем вести посещяемость людей без фотографий, причина может быть из-за разных языков в данных`;
  //   const var2 = `Нет соответствующей записи в Excel: ${num}. Комментарии: данные сотрудники не были добавлены, т.к. их данных(кроме фото) нет`;
  //   const var3 = `Не соблюдение Фамилия+Имя.jpeg: ${num}. Поменяв местами имя и фамилию при формировании названия файла(т.е. Имя+фамилия.jpeg) сотрудники были добавлены. Однако это могло нести ошибки, просим их провалидировать в ручном режиме.`;
  // };

  return (
    <Modal
      open={show}
      onCancel={onClose}
      title={"Импорт данных"}
      footer={null}
      destroyOnClose
      afterClose={() => form.resetFields()}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout={"vertical"}
        style={{
          marginTop: "16px",
        }}
      >
        <Form.Item
          name="excel"
          getValueFromEvent={normFile}
          valuePropName={"fileList"}
        >
          <Upload beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>
              Загрузить список сотрудников
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="archive"
          getValueFromEvent={normFile}
          valuePropName={"fileList"}
        >
          <Upload beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>
              Загрузить архив фотографий
            </Button>
          </Upload>
        </Form.Item>
      </Form>

      <Button onClick={getEduTemplate} loading={tempalateLoading}>
        Загрузить шаблон
      </Button>
      <div>
        {importFilesErrors.map((item, index) => {
          if (item) {
            return (
              <div className={style.import_error} key={`import-error-${index}`}>
                {item}
              </div>
            );
          }
          return null;
        })}
      </div>

      <Button
        onClick={form.submit}
        className={style.modal_button}
        type={"primary"}
        style={{ marginRight: "8px" }}
        loading={loading}
      >
        Загрузить
      </Button>
      <Button onClick={onClose} className={style.modal_button}>
        Отмена
      </Button>
    </Modal>
  );
}

export default ImportFilesModal;
