import { createEvent } from "effector";
import { debounce } from "patronum";

export const changeBlackListFiltersEv = createEvent();
export const resetFiltersEv = createEvent();
export const changeShowFiltersEv = createEvent();

export const getBlackListDataEv = createEvent();
export const getCurrentListEv = createEvent();

export const changeHistoryModalEv = createEvent();
export const resetHistoryModalEv = createEvent();

export const changeEditModalEv = createEvent();
export const resetEditModalEv = createEvent();

export const sendEditVisitorEv = createEvent();

export const passVisitorEv = createEvent();
export const rejectVisitorEv = createEvent();
export const wrongPersonEv = createEvent();

export const addUserToCurrentEv = createEvent();
export const debounceAddUserToCurrentEv = debounce(addUserToCurrentEv, 1000);
export const removeUserFromCurrentEv = createEvent();

export const changeTabEv = createEvent();
//FIXME Event to check if currentList is empty after remove
export const closeCurrentTabEv = createEvent();
