import {useEffect, useRef, useState} from "react";
import style from './Registration.module.css'
import {Button} from "antd";
import {
	$currentPage,
	$userData,
	changeUserDataEv,
	sendRegistrationDataEv,
	setNextStepEv
} from "../../models/registrationModel";
import {useUnit} from "effector-react";
import CustomSlider from "./CustomSlider";

const steps_titles = {
	0: 'Сделайте фото анфас',
	1: 'Сделайте фото в профиль влево',
	2: 'Сделайте фото в профиль вправо',
	3: 'Сделайте фото наколнив лоб вниз'
}

export default function FaceScanPage() {

	const [animation, setAnimation] = useState(false)
	const videoRef = useRef()
	const canvasRef = useRef()
	const sendRegistration = useUnit(sendRegistrationDataEv)
	const changeUserData = useUnit(changeUserDataEv)
	const {images} = useUnit($userData)
	const setNextStep = useUnit(setNextStepEv)
	const currentPage = useUnit($currentPage)

	const startVideo = () => {
		navigator.mediaDevices.getUserMedia({video: true})
			.then((currentStream) => {
				videoRef.current.srcObject = currentStream
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const takePicture = () => {
		setAnimation(true)

		const canvas = document.createElement('canvas')
		canvas.width = videoRef.current.videoWidth
		canvas.height = videoRef.current.videoHeight
		canvas.getContext('2d').drawImage(videoRef.current, 0, 0)

		changeUserData({
			field: 'images',
			value: canvas.toDataURL()
		})

		if(images.length === 3){
			setTimeout(() => {
				sendRegistration()
			}, 250)
		}

		// canvas.toBlob(function(blob){URL.createObjectURL(blob)
		// 	changeUserData({
		// 		field: 'image',
		// 		value: URL.createObjectURL(blob)
		// 	})
		// 	setTimeout(() => {
		// 		sendRegistration()
		// 	}, 250)
		// },'image/png');
	}

	useEffect(() => {
		startVideo()
		videoRef.current.setAttribute('autoplay', '');
		videoRef.current.setAttribute('muted', '');
		videoRef.current.setAttribute('playsinline', '')

		videoRef.current.addEventListener('canplay', () => {

			const width = window.innerWidth
			const height = window.innerHeight

			canvasRef.current.width = window.innerWidth
			canvasRef.current.height = window.innerHeight

			return setInterval(() => {
				if(canvasRef.current){
					canvasRef.current.getContext('2d').drawImage(videoRef.current, 0, 0, width, height)
				}
			})
		})
	}, []);

	return <>
		<div className={style.info_block} style={{
			position: 'absolute',
			zIndex: 2,
			width: '100%',
		}}>
			<div className={style.info_title}>Выполните действия</div>
			<div className={style.info_point} style={{marginBottom: '25px'}}>{steps_titles[images.length]}</div>
			<CustomSlider
				points={[
					{
						id: 0
					},
					{
						id: 1
					},
					{
						id: 2
					},
				]}
				activePoint={images.length > 0 ? images.length - 1 : undefined}
			/>
		</div>
		<div className={style.facescan_wrapper}></div>
		<div onAnimationEnd={() => setAnimation(false)}
				 className={[style.facescan_flash, animation ? style.start_flash : ''].join(' ')}></div>
		<video className={style.facescan_video} crossOrigin={"anonymous"} ref={videoRef} autoPlay></video>
		<canvas className={style.facescan_canvas} ref={canvasRef}/>
		<div className={style.facescan_button_wrapper}>
			<Button className={style.next_button} onClick={takePicture}>Сделать фото</Button>
			<Button className={style.back_button} onClick={() => setNextStep(currentPage - 1)}>
				Назад
			</Button>
		</div>
	</>
}
