export const formatUserListTable = (data) => {
	return data.map(item => {
		return {
			key: `user-list-user-${item.partition_key.S}`,
			partition_key: item.partition_key ? item.partition_key.S.split('#')[1] : null,
			sort_key: item.sort_key ? item.sort_key.S.split('#')[1] : null,
			admin_place: item.admin_place ? item.admin_place.S : null,
			alarm: item.alarm ? item.alarm.S : null,
			category: item.category ? item.category.S : null,
			city: item.city ? item.city.S : null,
			emp_category: item.emp_category ? item.emp_category.S : null,
			emp_department: item.emp_department ? item.emp_department.S : null,
			emp_position: item.emp_position ? item.emp_position.S : null,
			place_address: item.place_address ? item.place_address.S : null,
			place_id: item.place_id ? item.place_id.S : null,
			service: item.service ? item.service.S : null,
			user_gender: item.user_gender ? item.user_gender.S : null,
			user_name: item.user_name ? item.user_name.S : null,
			user_nickname: item.user_nickname ? item.user_nickname.S : null,
			user_surname: item.user_surname ? item.user_surname.S : null,
			work_hours: item.work_hours ? item.work_hours.S : null,
			work_zones: item.work_zones ? item.work_zones.S : null,
      days_number: item.number ? item.number.S : null,
		}
	})
}
