import style from "./ClientUserList.module.css";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import React, { useEffect, useMemo, useState } from "react";
import {
  $userList,
  $userListPlaceId,
  changeChosenUserEv, changeImportFileModalEv,
  changeShowCreateModalEv,
  changeUserListPlaceIdEv,
  fireEmployeeEv,
  getUserListFx,
} from "../../models/userListModel";
import { useUnit } from "effector-react";
import { Button, Popconfirm, Segmented, Table } from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  UserAddOutlined,
  LeftOutlined,
  RightOutlined,
  EllipsisOutlined, UploadOutlined,
} from "@ant-design/icons";
import UserListModal from "./UserListModal";
import {$userAttributes, $userGroups} from "../../models/userModel";
import { useNavigate } from "react-router-dom";
import PotentialEmp from "./PotentialEmp";
import CreateUserModal from "./CreateUserModal";
import MakeEmpModal from "./MakeEmpModal";
import PotentialImagesModal from "./PotentialEmpImagesModal";
import ImportFilesModal from "./ImportFilesModal";

export default function ClientUserList() {
  const userListPlaceId = useUnit($userListPlaceId);
  const changeUserListPlaceId = useUnit(changeUserListPlaceIdEv);
  const userList = useUnit($userList);
  const loading = useUnit(getUserListFx.pending);
  const userGroups = useUnit($userGroups);
  const navigate = useNavigate();
  const changeShowCreateModal = useUnit(changeShowCreateModalEv);
  const changeImportFileModal = useUnit(changeImportFileModalEv);
  const userAttributes = useUnit($userAttributes)

  const [currentTab, setCurrentTab] = useState("0");

  const segmentedOptions = useMemo(() => {
    const initOptions = [
      {
        label: "Все",
        value: "0",
        // disabled: !userGroups?.includes('portal_admin')
      },
    ];
    if (userGroups && !userGroups.includes("CustomerOnlyView")) {
      initOptions.push({
        label: "Потенциальные сотрудники",
        value: "1",
      });
    }
    return initOptions;
  }, [userGroups]);

  const columns = useMemo(() => {
    const initColumns = [
      {
        title: "Имя",
        key: "user-list-user_name",
        dataIndex: "user_name",
      },
      {
        title: "Фамилия",
        key: "user-list-user_surname",
        dataIndex: "user_surname",
      },
      {
        title: "Департамент",
        key: "user-list-emp_department",
        dataIndex: "emp_department",
      },
      {
        title: "Должность",
        key: "user-list-emp_position",
        dataIndex: "emp_position",
      },
      {
        title: "Статус",
        key: "user-list-emp_category",
        dataIndex: "emp_category",
      },
      {
        title: "Количество дней",
        key: "user-list-days_number",
        dataIndex: "days_number",
      },
      {
        title: "Пол",
        key: "user-list-user_gender",
        dataIndex: "user_gender",
        render: (user_gender) => (user_gender === "Male" ? "М" : "Ж"),
      },
    ];
    if (userGroups && !userGroups.includes("CustomerOnlyView")) {
      initColumns.unshift({
        title: "Действия",
        key: "user-list-actions",
        render: (text, record) => (
          <div className={style.table_actions_wrapper}>
            <Button
              icon={<EditOutlined style={{ fontSize: "14px" }} />}
              onClick={() => changeChosenUserEv(record)}
              style={{ width: "24px", height: "24px" }}
            />
            {record.emp_category === "Сотрудник" ? (
              <Popconfirm
                title={"Сотрудник уволен?"}
                okText={"Да"}
                cancelText={"Нет"}
                onConfirm={() => fireEmployeeEv(record)}
              >
                <Button
                  icon={<CloseCircleOutlined style={{ fontSize: "14px" }} />}
                  style={{ width: "24px", height: "24px" }}
                />
              </Popconfirm>
            ) : (
              ""
            )}
          </div>
        ),
      });
    }
    return initColumns;
  }, [userGroups]);

  useEffect(() => {
    // console.log('userGroups status: - ', !(userGroups.includes('Customer') && userGroups.includes('Amanat')))
    if (
      (Array.isArray(userGroups) &&
        userGroups.includes("Customer") &&
        (userGroups?.includes("Amanat") || userGroups?.includes("Kolibrix"))) ||
      (Array.isArray(userGroups) && userGroups.includes("portal_admin"))
    ) {
      console.log("got perms for User List");
    } else {
      console.log("redirected from User List");
      navigate("/dashboard");
    }
    // if (
    //   Array.isArray(userGroups) && !userGroups.includes('portal_admin') ||
    //   !(userGroups.includes('Customer') && userGroups.includes('Amanat'))) {
    // 	navigate('/dashboard')
    // }
  }, [userGroups, navigate]);

  // useEffect(() => {
  //   if(userGroups?.includes('Kolibrix')){
  //     changeUserListPlaceId({value: 'klba36t1h2'})
  //   } else {
  //     getUserListFx();
  //   }
  // }, [userGroups]);

  useEffect(() => {
    if(userAttributes && userAttributes['custom:PlaceId']){
      let place_id = userAttributes['custom:PlaceId'].includes(',') ? userAttributes['custom:PlaceId'].split(',')[0] : userAttributes['custom:PlaceId']
      changeUserListPlaceId({value: place_id})
    } else {
      getUserListFx();
    }
  }, [userAttributes]);

  const cliendIdOptions = useMemo(() => {
    if(userGroups?.includes('Kolibrix')){
      return [
        {
          value: "klba36t1h2",
          label: "Kolibrix Спартака, 26",
        },
        {
          value: "klba36x2c2",
          label: "Kolibrix Жунисова, 2/13 к1",
        },
      ]
    }
    return [
      {
        value: "klba36t1h2",
        label: "Kolibrix Спартака, 26",
      },
      {
        value: "klba36x2c2",
        label: "Kolibrix Жунисова, 2/13 к1",
      },
      {
        value: "amaa25d7c9",
        label: "Amanat Достык, 232/1",
      },
      {
        value: "crtz05x7d2",
        label: "Creatti, ул.Мангилик ел, д. 59",
      },
    ];
  }, [userGroups])

  return (
    <div className={style.userList_wrapper}>
      <div className={style.userList_title}>Список сотрудников</div>
      <div className={style.user_list_top_wrapper}>
        <Segmented
          options={segmentedOptions}
          value={currentTab}
          onChange={setCurrentTab}
        />
        {currentTab === "0" ? (
          <div className={style.top_buttons_wrapper}>
            {userGroups?.includes("portal_admin") || userGroups?.includes('Kolibrix') ? (
              <CustomSelect
                options={cliendIdOptions}
                onSelect={changeUserListPlaceId}
                field={"client_id"}
                placeholder="Place"
                value={userListPlaceId}
                searchIcon={true}
              />
            ) : (
              ""
            )}
            {!userGroups?.includes("CustomerOnlyView") ? (
              <>
                {

                  userGroups?.includes('Amanat') || userGroups?.includes('portal_admin') || userGroups?.includes('Kolibrix') ? (
                      <Button
                        className={style.add_user_button}
                        icon={<UploadOutlined />}
                        onClick={changeImportFileModal}
                        type={"primary"}
                      >
                        Импорт данных
                      </Button>
                  )
                    :
                    ''
                }
                <Button
                  className={style.add_user_button}
                  icon={<UserAddOutlined />}
                  onClick={changeShowCreateModal}
                  type={"primary"}
                >
                  Добавить сотрудника
                </Button>
              </>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      {currentTab === "0" ? (
        <Table
          loading={loading}
          className={style.userList_table}
          dataSource={userList}
          columns={columns}
          scroll={{
            x: "max-content",
          }}
          bordered
          pagination={{
            locale: { items_per_page: "стр." },
            // itemRender: (page, type) => {
            // 	if (type === 'prev') {
            // 		return <div style={{border: '1px solid #D9D9D9'}}><LeftOutlined /></div>
            // 	}
            // 	if (type === 'next') {
            // 		return <div style={{border: '1px solid #D9D9D9'}}><RightOutlined /></div>
            // 	}
            // 	if(type === 'page') {
            // 		return <div style={{border: '1px solid #D9D9D9'}}>{page}</div>
            // 	}
            // 	if(type.includes('jump')) {
            // 		return <EllipsisOutlined />
            // 	}
            // }
          }}
        />
      ) : (
        <PotentialEmp />
      )}
      <UserListModal />
      <CreateUserModal />
      <MakeEmpModal />
      <PotentialImagesModal />
      <ImportFilesModal />
    </div>
  );
}
