import {createStore} from "effector";

export const $userGroups = createStore(null)
$userGroups.watch(state => console.log('$userGroups', state))

export const $userKeys = createStore({
	accessKeyId: '',
	secretAccessKey: '',
})

export const $userAttributes = createStore(null)
$userAttributes.watch(state => console.log('$userAttributes', state))

export const $userUIDData = createStore(null)
$userUIDData.watch(state => console.log('$userUIDData', state))
