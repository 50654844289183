import { sample } from "effector";
import {
  createEduUserFx,
  getEduTemplateFx,
  getPotentialEmpEduFx,
  getPotentialEmpFx, getTemplateFx,
  getUserListEduFx,
  getUserListFx,
  sendEditUserFx,
  sendImportFilesFx,
} from "./effects";
import {
  $chosenEmpEduImgModal,
  $chosenEmpImgModal,
  $chosenPotentialEmp,
  $chosenPotentialEmpEdu,
  $chosenUser, $chosenViewEdu,
  $importFileModal,
  $importFilesErrors,
  $potentialEmp,
  $potentialEmpEdu,
  $showCreateModal,
  $userEmpCategory,
  $userList,
  $userListEdu,
  $userListPlaceId,
} from "./stores";
import {
  changeChosenEmpEduImgModalEv,
  changeChosenEmpImgModalEv,
  changeChosenPotentialEmpEduEv,
  changeChosenPotentialEmpEv,
  changeChosenUserEv,
  changeImportFileModalEv,
  changeShowCreateModalEv,
  changeUserEmpCategoryEv,
  changeUserListPlaceIdEv,
  createEduUserEv,
  fireEmployeeEv,
  getEduTemplateEv, getTemplateEv, resetViewEduEv,
  sendImportFilesEDUEv, sendImportFilesHREv, setViewEduEv,
} from "./events";
import { formatUserListTable } from "./utils";

$chosenViewEdu.on(setViewEduEv, (_, payload) => payload)
  .reset(resetViewEduEv)

sample({
  clock: getUserListFx.doneData,
  fn: formatUserListTable,
  target: $userList,
});

sample({
  clock: $userListPlaceId.updates,
  target: getUserListFx,
});

sample({
  clock: $userListPlaceId.updates,
  target: getPotentialEmpFx,
});

sample({
  clock: changeUserListPlaceIdEv,
  fn: (clock) => {
    return clock.value;
  },
  target: $userListPlaceId,
});

sample({
  clock: changeUserEmpCategoryEv,
  fn: (clock) => clock,
  target: $userEmpCategory,
});

sample({
  clock: changeChosenUserEv,
  target: $chosenUser,
});

sample({
  clock: sendEditUserFx.doneData,
  filter: (clock) => clock,
  fn: (clock) => {
    if (clock) return null;
  },
  target: $chosenUser,
});

sample({
  source: $userList,
  clock: sendEditUserFx.doneData,
  filter: (source, clock) => clock,
  fn: (source, clock) => {
    const clock_partition_key = clock.partition_key.split("#")[1];
    const changedUser = source.find(
      (user) => user.partition_key === clock_partition_key,
    );
    return [
      ...source.filter((user) => user.partition_key !== clock_partition_key),
      {
        ...changedUser,
        ...clock,
        partition_key: clock_partition_key,
        sort_key: clock.sort_key.split("#")[1],
      },
    ];
  },
  target: $userList,
});

sample({
  clock: getPotentialEmpFx.doneData,
  fn: (clock) => {
    if (Array.isArray(clock)) {
      return clock;
    }
    return [clock];
  },
  target: $potentialEmp,
});

sample({
  clock: fireEmployeeEv,
  fn: (clock) => {
    return {
      ...clock,
      emp_category: "Уволен",
    };
  },
  target: sendEditUserFx,
});

sample({
  source: $showCreateModal,
  clock: changeShowCreateModalEv,
  fn: (source, clock) => {
    if (typeof clock === "boolean") {
      return clock;
    }
    return !source;
  },
  target: $showCreateModal,
});

sample({
  clock: changeChosenPotentialEmpEv,
  target: $chosenPotentialEmp,
});

sample({
  clock: changeChosenEmpImgModalEv,
  target: $chosenEmpImgModal,
});

sample({
  clock: createEduUserEv,
  target: createEduUserFx,
});

sample({
  clock: createEduUserFx.doneData,
  fn: () => false,
  target: $showCreateModal,
});

sample({
  clock: getUserListEduFx.doneData,
  fn: (clock) => {
    return clock.map((item, index) => ({
      ...item,
      key: `edu-item-${index}`,
    }));
  },
  target: $userListEdu,
});

sample({
  clock: $userEmpCategory.updates,
  fn: (clock) => clock,
  target: getUserListEduFx,
});

sample({
  source: $importFileModal,
  clock: changeImportFileModalEv,
  fn: (source, clock) => {
    if (typeof clock === "boolean") {
      return clock;
    }
    return !source;
  },
  target: $importFileModal,
});

sample({
  clock: sendImportFilesEDUEv,
  fn: (clock) => {
    return {
      link: 'https://mass-onboarding.foqus-portal.live/process-files-edu',
      ...clock
    }
  },
  target: sendImportFilesFx,
});

sample({
  clock: sendImportFilesHREv,
  fn: (clock) => {
    return {
      link: 'https://mass-onboarding.foqus-portal.live/process-files',
      ...clock
    }
  },
  target: sendImportFilesFx,
});

sample({
  clock: sendImportFilesFx.doneData,
  fn: (clock) => {
    return [
      clock.missing_employees_comment,
      clock.missing_images_comment,
      clock.name_surname_reversed_comment,
    ];
  },
  target: $importFilesErrors,
});

sample({
  clock: getEduTemplateEv,
  target: getEduTemplateFx,
});

sample({
  clock: getTemplateEv,
  target: getTemplateFx
})

sample({
  clock: getPotentialEmpEduFx.doneData,
  fn: (clock) => {
    if (Array.isArray(clock)) {
      return clock;
    }
    return [clock];
  },
  target: $potentialEmpEdu,
});

sample({
  clock: changeChosenPotentialEmpEduEv,
  target: $chosenPotentialEmpEdu,
});

sample({
  clock: changeChosenEmpEduImgModalEv,
  target: $chosenEmpEduImgModal,
});
