import { sample } from "effector";
import {
  addUserToCurrentEv,
  changeBlackListFiltersEv,
  changeEditModalEv,
  changeHistoryModalEv,
  changeShowFiltersEv,
  changeTabEv,
  closeCurrentTabEv,
  debounceAddUserToCurrentEv,
  getBlackListDataEv,
  getCurrentListEv,
  passVisitorEv,
  rejectVisitorEv,
  removeUserFromCurrentEv,
  resetEditModalEv,
  resetFiltersEv,
  resetHistoryModalEv,
  sendEditVisitorEv,
  wrongPersonEv,
} from "./events";
import {
  $blackListData,
  $blackListFilters,
  $currentList,
  $editModal,
  $historyModal,
  $showFilters,
  $tab,
} from "./stores";
import {
  getBlackListDataFx,
  getCurrentListFx,
  sendEditVisitorFx,
  updateBlackListFx,
} from "./effects";
import { notification } from "antd";
import dayjs from "dayjs";

$blackListFilters.reset(resetFiltersEv);

$blackListData
  .on(getBlackListDataFx.doneData, (_, payload) => {
    return payload.map((item) => {
      return {
        ...item,
        date: dayjs.unix(item.checkin_at).format("YYYY-MM-DD:HH:mm"),
      };
    });
  })
  .on(getCurrentListFx.doneData, (state, payload) => {
    const user_ids = state.map((item) => item.user_id);
    return [
      ...state,
      ...payload.filter((item) => !user_ids.includes(item.user_id)),
    ];
  });
$currentList.on(getCurrentListFx.doneData, (_, payload) => payload);

$historyModal.reset(resetHistoryModalEv);
$editModal.reset(resetEditModalEv);

$tab
  .on(changeTabEv, (_, payload) => payload)
  .on(getCurrentListFx.doneData, (_, payload) => (payload.length > 0 ? 0 : 1));

sample({
  source: $blackListFilters,
  clock: changeBlackListFiltersEv,
  fn: (source, clock) => {
    if (clock.field === "period") {
      return {
        ...source,
        [clock.field]: clock.value,
      };
    } else {
      if (source[clock.field].includes(clock.value)) {
        return {
          ...source,
          [clock.field]: source[clock.field].filter(
            (item) => item !== clock.value,
          ),
        };
      } else {
        return {
          ...source,
          [clock.field]: [...source[clock.field], clock.value],
        };
      }
    }
  },
  target: $blackListFilters,
});

sample({
  source: $showFilters,
  clock: changeShowFiltersEv,
  fn: (source, clock) => {
    if (typeof clock === "boolean") {
      return clock;
    }
    return !source;
  },
  target: $showFilters,
});

sample({
  clock: getBlackListDataEv,
  target: getBlackListDataFx,
});

sample({
  clock: $blackListFilters.updates,
  target: getBlackListDataFx,
});

// sample({
//   clock: getCurrentListEv,
//   target: getCurrentListFx,
// });

sample({
  source: $blackListData,
  clock: changeHistoryModalEv,
  fn: (source, clock) => {
    return source.find((item) => item.id === clock);
  },
  target: $historyModal,
});

sample({
  source: $blackListData,
  clock: changeEditModalEv,
  fn: (source, clock) => {
    return source.find((item) => item.id === clock);
  },
  target: $editModal,
});

sample({
  clock: sendEditVisitorEv,
  target: sendEditVisitorFx,
});

sample({
  clock: sendEditVisitorFx.doneData,
  fn: () => {
    notification.success({
      message: "Успех!",
      description: "Посетитель изменен",
      placement: "topRight",
    });
  },
  target: resetEditModalEv,
});

sample({
  clock: passVisitorEv,
  fn: (clock) => {
    return {
      sort_key: clock,
      pass: true,
    };
  },
  target: updateBlackListFx,
});

sample({
  clock: rejectVisitorEv,
  fn: (clock) => {
    return {
      sort_key: clock,
      pass: false,
    };
  },
  target: updateBlackListFx,
});

sample({
  clock: wrongPersonEv,
  fn: () => {
    return {
      wrong_person: true,
    };
  },
  target: updateBlackListFx,
});

sample({
  clock: updateBlackListFx.doneData,
  target: removeUserFromCurrentEv,
});

sample({
  source: $currentList,
  clock: removeUserFromCurrentEv,
  fn: (source) => {
    return source.slice(1);
  },
  target: [$currentList, closeCurrentTabEv],
});

sample({
  source: $blackListData,
  clock: debounceAddUserToCurrentEv,
  fn: (source, clock) => {
    return {
      checkin_at: +source[0].checkin_at,
    };
  },
  target: getCurrentListFx,
});

sample({
  clock: closeCurrentTabEv,
  filter: (clock) => clock.length === 0,
  fn: () => 1,
  target: changeTabEv,
});
