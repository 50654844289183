export const main_theme_config = {
  token: {
    fontFamily: "Roboto",
  },
  components: {
    Segmented: {
      itemSelectedBg: "#1890FF",
      colorText: "white",
      fontFamily: "Roboto",
      borderRadius: "2px",
      itemHoverColor: "#000000A6",
    },
    Select: {
      borderRadius: "1px",
    },
    Input: {
      borderRadius: "2px",
    },
    DatePicker: {
      borderRadius: "2px",
    },
    TimePicker: {
      borderRadius: "2px",
    },
    Button: {
      borderRadius: "1px",
      colorPrimary: "#1890FF",
    },
    Table: {
      borderRadius: "8px",
    },
    Pagination: {
      borderRadius: "1px",
      colorPrimary: "#1890FF",
    },
  },
};
