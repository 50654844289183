import {createStore} from "effector";

const filtersInitialState = {
	client_id: 'klba36t1h2',
	dates: ['2024.04.30', '2024.05.02'],
	age_groups: [],
	gender: [],
	retention: [],
}

export const $testingClientTableData = createStore([])
$testingClientTableData.watch(state => console.log('$testingClientTableData', state))

export const $testingClientStartTableData = createStore([])

export const $testingClientFilters = createStore(filtersInitialState)
$testingClientFilters.watch(state => console.log('$testingClientFilters', state))
