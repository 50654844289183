import { createEffect } from "effector";
import dayjs from "dayjs";

export const getDuplicatesFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      //FIXME Nik insert API here
      "https://0x7mmktx69.execute-api.eu-west-1.amazonaws.com/default/dev-ddb-query-for-portal-duplicates-lambda",
      {
        method: "POST",
        //FIXME Nik insert API payload here
        body: JSON.stringify({
          date: payload?.date || dayjs().format("DD-MM-YYYY"),
          place_id: payload?.place_id || "amaa25d7c9",
        }),
      },
    );

    let result = await response.json();
    // debugger
    console.log("getDuplicatesFx:: Success", result);
    // const tempRes = JSON.parse(result.body)
    // console.log('getDuplicatesFx:: Parsed body', tempRes)
    //FIXME HERE IN result we have LastEvaluatedKey, it looks like :
    // date_gsi:"05-09-2024"
    // place_id:"amaa25d7c9"
    // status:"null"
    // user_id:"amaa25d7c9_c1_f1_entranceOut_camera2_out_a31158_1725541647.580555_1660_198_1832_535_off.jpg"
    return result.body;
  } catch (e) {
    console.error(e);
  }
});

export const mergeDuplicatesFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://0x7mmktx69.execute-api.eu-west-1.amazonaws.com/default/dev-merge-duplicate-users-lambda",
      {
        method: "POST",
        body: JSON.stringify(payload),
      },
    );

    let result = await response.json();
    console.log("mergeDuplicatesFx:: Success", result);
    return result;
  } catch (e) {
    console.error(e);
  }
});

export const getMoreDuplicatesFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://0x7mmktx69.execute-api.eu-west-1.amazonaws.com/default/dev-ddb-query-for-portal-duplicates-lambda",
      {
        method: "POST",
        body: JSON.stringify({
          date: payload?.date || dayjs().format("DD-MM-YYYY"),
          place_id: payload?.place_id || "amaa25d7c9",
          lastKey: payload?.lastEvaluatedKey || null,
        }),
      },
    );

    let result = await response.json();
    console.log("getDuplicatesFx:: Success", result);
    return result.body;
  } catch (e) {
    console.error(e);
  }
});
