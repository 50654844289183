import { createStore } from "effector";
import dayjs from "dayjs";

export const $badImagesData = createStore([]);
$badImagesData.watch((state) => console.log("$badImagesData", state));

export const $badImagesDate = createStore(dayjs(new Date()));
export const $badImagesPlace = createStore("amaa25d7c9");

export const $lastEvaluatedkey = createStore(null);
$lastEvaluatedkey.watch((state) => console.log("$lastEvaluatedkey", state));
