import {Button, Form, Input, Modal, Select, Upload} from "antd";
import {useUnit} from "effector-react";
import {$showCreateModal, $userListPlaceId, changeShowCreateModalEv, createUserFx} from "../../models/userListModel";
import {UploadOutlined} from "@ant-design/icons";

const selectOptions = [
	{
		label: 'Сотрудник',
		value: 'Сотрудник'
	},
	{
		label: 'Уволен',
		value: 'Уволен'
	},
	{
		label: 'Потенциальный сотрудник',
		value: 'Потенциальный сотрудник'
	},
]

const genderOptions = [
	{
		label: 'Мужчина',
		value: 'Male',
	},
	{
		label: 'Женщина',
		value: 'Female',
	}
]

export default function CreateUserModal(){

	const showCreateModal = useUnit($showCreateModal)
	const changeShowCreateModal = useUnit(changeShowCreateModalEv)
	const userListPlaceId = useUnit($userListPlaceId)

	const [form] = Form.useForm()

	const onClose = () => {
		changeShowCreateModal(false)
		form.resetFields()
	}

	const onFinish = (values) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => {
			createUserFx({
				...values,
				place_id: userListPlaceId,
				data_url: [
					reader.result
				]
			})
		});
		reader.readAsDataURL(values.file.originFileObj);
	}

	const normFile = (info) => {
		return info?.file
	};

	return <Modal
		open={showCreateModal}
		onCancel={onClose}
		title={'Edit user'}
		onOk={form.submit}
		// confirmLoading={pending}

	>
		<Form
			form={form}
			onFinish={onFinish}
		>
			<Form.Item name="file" getValueFromEvent={normFile}>
				<Upload>
					<Button icon={<UploadOutlined />}>Click to Upload</Button>
				</Upload>
			</Form.Item>
			<Form.Item name={'new_department'} label={'Department'}>
				<Input/>
			</Form.Item>
			<Form.Item name={'new_position'} label={'Position'}>
				<Input/>
			</Form.Item>
			<Form.Item name={'new_category'} label={'Category'}>
				<Select
					options={selectOptions}
				/>
			</Form.Item>
			<Form.Item name={'new_name'} label={'Name'}>
				<Input/>
			</Form.Item>
			<Form.Item name={'new_surname'} label={'Surname'}>
				<Input/>
			</Form.Item>
			<Form.Item name={'new_nickname'} label={'Nickname'}>
				<Input/>
			</Form.Item>
			<Form.Item name={'new_gender'} label={'Gender'}>
				<Select
					options={genderOptions}
				/>
			</Form.Item>
		</Form>
	</Modal>
}
