import {createStore} from "effector";

const initialState = {
	name: '',
	surname: '',
	phone: '',
	birthday: null,
	tags: [],
	isAgreement: false,
	images: [],
}

export const $currentPage = createStore(0)
$currentPage.watch(state => console.log('$currentPage', state))

export const $isDarkTheme = createStore(false)
$isDarkTheme.watch(state => console.log('$isDarkTheme', state))

export const $userData = createStore(initialState)
$userData.watch(state => console.log('$userData', state))

export const $formClickedButton = createStore(0)
$formClickedButton.watch(state => console.log('formClickedButton', state))
