import React, {useEffect} from 'react';
import {Form, Modal} from "antd";
import DashboardForm from "./DashboardForm";
import {useUnit} from "effector-react";
import {$editDashboard, resetEditDashboardEv} from "../../../models/dashboardModel";
import {sendEditTenantItemEv, sendEditTenantItemFx} from "../../../models/portalUsersModel";

function EditDashboardModal(props) {
	const editDashboard = useUnit($editDashboard)
	const [editForm] = Form.useForm()
	const resetEditDashboard = useUnit(resetEditDashboardEv)
	const sendEditDashboard = useUnit(sendEditTenantItemEv)
	const loading = useUnit(sendEditTenantItemFx.pending)

	const onFinish = (values) => {
		sendEditDashboard(values)
	}

	const onCancel = () => {
		resetEditDashboard()
		editForm.resetFields()
	}

	useEffect(() => {
		editForm.setFieldsValue(editDashboard)
	}, [editDashboard]);
	return (
		<Modal
			open={!!editDashboard}
			footer={null}
			onCancel={onCancel}
			destroyOnClose
			title={'Редактировать dashboard'}
			width={557}
		>
			<DashboardForm
				form={editForm}
				onFinish={onFinish}
				onCancel={onCancel}
				loading={loading}
				type={'edit'}
			/>
		</Modal>
	);
}

export default EditDashboardModal;
