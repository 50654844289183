import React, {useEffect} from 'react';
import {Form, Modal} from "antd";
import PortalPlacesForm from "./PortalPlacesForm";
import {useUnit} from "effector-react";
import {$editPortalPlace, resetEditPlaceEv, sendEditTenantItemEv, sendEditTenantItemFx} from "../../../../models/portalUsersModel";

function EditPlaceIdModal() {
	const editPortalPlace = useUnit($editPortalPlace)
	const [editForm] = Form.useForm()
	const resetEditPortalPlace = useUnit(resetEditPlaceEv)
	const sendEditPortalPlace = useUnit(sendEditTenantItemEv)
	const loading = useUnit(sendEditTenantItemFx.pending)

	const onFinish = (values) => {
		sendEditPortalPlace(values)
	}

	const onCancel = () => {
		resetEditPortalPlace()
		editForm.resetFields()
	}

	useEffect(() => {
		editForm.setFieldsValue(editPortalPlace)
	}, [editPortalPlace]);

	return (
		<Modal
			open={!!editPortalPlace}
			footer={null}
			onCancel={onCancel}
			destroyOnClose
			title={'Редактировать place_id'}
			width={557}
		>
			<PortalPlacesForm
				form={editForm}
				onFinish={onFinish}
				onCancel={onCancel}
				loading={loading}
				type={'edit'}
			/>
		</Modal>
	);
}

export default EditPlaceIdModal;
