import {createEffect} from "effector";
import AWS from "aws-sdk";

export const getImageFromS3Fx = createEffect().use(async (payload) => {
	const downloadFile = async () => {
		try {
			const s3 = new AWS.S3({
        accessKeyId: payload.accessKeyId,
        secretAccessKey: payload.secretAccessKey,
				region: 'eu-west-1'
			});

			const params = {
				Bucket: payload.bucket_name,
				Key: payload.object_key,
			}

			const data = await s3.getObject(params).promise()

			const blob = new Blob([data.Body], { type: 'image/jpeg' })
			const url = URL.createObjectURL(blob)
			return url
		} catch (e){
			console.error(e)
			return ''
		}
	}
	return await downloadFile()
})
