import style from "./UnrecognizedTesting.module.css";
import { useEffect, useState } from "react";
import {
  $unrecognizedFilters,
  $unrecognizedTestingTableData,
  changeUnrecognizedFiltersEv,
  getUnrecognizedTableDataFx,
} from "../../models/unrecognizedTestingModel";
import { useNavigate } from "react-router-dom";
import { useUnit } from "effector-react";
import { $userGroups } from "../../models/userModel";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import dayjs from "dayjs";
import { Table, Image, Spin } from "antd";
import ImageFromS3 from "../../components/ImageFromS3/ImageFromS3";

const cliendIdOptions = [
  {
    value: "klba36t1h2",
    label: "Kolibrix Спартака, 26",
  },
  {
    value: "klba36x2c2",
    label: "Kolibrix Жунисова, 2/13 к1",
  },
  {
    value: "amaa25d7c9",
    label: "Amanat Достык, 232/1",
  },
  {
    value: "crtz05x7d2",
    label: "Creatti, ул.Мангилик ел, д. 59",
  },
];
const handleCopy = (hiddenText) => {
  navigator.clipboard
    .writeText(hiddenText)
    .then(() => {
      console.log("Text copied to clipboard:", hiddenText);
    })
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};

export default function UnrecognizedTesting() {
  const unrecognizedTestingTableData = useUnit($unrecognizedTestingTableData);
  const changeUnrecognizedFilters = useUnit(changeUnrecognizedFiltersEv);
  const unrecognizedFilters = useUnit($unrecognizedFilters);
  const loading = useUnit(getUnrecognizedTableDataFx.pending);
  const navigate = useNavigate();
  const userGroups = useUnit($userGroups);

  useEffect(() => {
    if (unrecognizedTestingTableData.length === 0) {
      getUnrecognizedTableDataFx();
    }
  }, [unrecognizedTestingTableData]);

  useEffect(() => {
    if (Array.isArray(userGroups) && !userGroups.includes("portal_admin")) {
      navigate("/dashboard");
    }
  }, [userGroups, navigate]);

  if (!userGroups || userGroups?.length === 0) {
    return null;
  }

  return (
    <div>
      <div className={style.filtration_wrapper}>
        <div className={style.filtration_title}>Filtration</div>
        <CustomSelect
          options={cliendIdOptions}
          onSelect={changeUnrecognizedFilters}
          field={"client_id"}
          placeholder="Client"
          value={unrecognizedFilters.client_id}
        />
        <CustomSelect
          selectType={"date"}
          onSelect={changeUnrecognizedFilters}
          field={"date"}
          value={dayjs(new Date(unrecognizedFilters.date))}
          allowClear={false}
        />
      </div>
      <div
        style={{
          display: "flex",
          maxWidth: "100%",
          flexWrap: "wrap",
        }}
      >
        {unrecognizedTestingTableData.map((item) => {
          return (
            <div
              key={`unrecognized-testing-${item.file_name}`}
              style={{
                maxWidth: "200px",
                minWidth: "200px",
                marginRight: "25px",
                marginBottom: "25px",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                }}
              >
                Time: {item.time}
              </div>
              <div
                style={{
                  textAlign: "left",
                  marginBottom: "15px",
                }}
              >
                File name:
                <button
                  onClick={() => handleCopy(item.file_name)}
                  style={{
                    marginLeft: "15px",
                    cursor: "pointer",
                  }}
                >
                  Click to copy
                </button>
              </div>
              <ImageFromS3
                // bucket_name={item.bucket_name}
                // object_key={item.object_key}
                presigned_url={item.presigned_url}
              />
            </div>
          );
        })}
      </div>
      {/*<Table*/}
      {/*	style={{width: '100%!important'}}*/}
      {/*	loading={loading}*/}
      {/*	columns={columns}*/}
      {/*	dataSource={unrecognizedTestingTableData}*/}
      {/*	scroll={{*/}
      {/*		x: 'max-content'*/}
      {/*	}}*/}
      {/*	// pagination={{*/}
      {/*	// 	pageSize: 100,*/}
      {/*	// 	pageSizeOptions: [100, 200, 300, 400, 500]*/}
      {/*	// }}*/}
      {/*/>*/}
    </div>
  );
}
