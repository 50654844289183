import {Form, Input, Modal, Select} from "antd";
import {
	$chosenPotentialEmp,
	$userListPlaceId,
	changeChosenPotentialEmpEv,
	createEmpFx
} from "../../models/userListModel";
import {useUnit} from "effector-react";
import {useEffect} from "react";

const genderOptions = [
	{
		label: 'Мужчина',
		value: 'Male',
	},
	{
		label: 'Женщина',
		value: 'Female',
	}
]

const selectOptions = [
	{
		label: 'Сотрудник',
		value: 'Сотрудник'
	},
	{
		label: 'Уволен',
		value: 'Уволен'
	},
	{
		label: 'Потенциальный сотрудник',
		value: 'Потенциальный сотрудник'
	},
]

export default function MakeEmpModal (){
	const [form] = Form.useForm()
	const chosenPotentialEmp = useUnit($chosenPotentialEmp)
	const userListPlaceId = useUnit($userListPlaceId)
	const changeChosenPotentialEmp = useUnit(changeChosenPotentialEmpEv)
	const createEmp = useUnit(createEmpFx)
	const pending = useUnit(createEmpFx.pending)

	const onClose = () => {
		changeChosenPotentialEmp(null)
	}

	const onFinish = (values) => {
		createEmp({
			...values,
			user_id: chosenPotentialEmp.user_id,
			place_id: userListPlaceId,
		})
	}

	useEffect(() => {
		form.setFieldValue('new_category', 'Сотрудник')
	}, []);

	return <Modal
		title={'Make employee from potential'}
		open={!!chosenPotentialEmp}
		onOk={form.submit}
		onCancel={onClose}
		confirmLoading={pending}
	>
		<Form
			form={form}
			onFinish={onFinish}
		>
			<Form.Item name={'new_department'} label={'Department'}>
				<Input/>
			</Form.Item>
			<Form.Item name={'new_position'} label={'Position'}>
				<Input/>
			</Form.Item>
			<Form.Item name={'new_category'} label={'Category'}>
				<Select
					options={selectOptions}
					value={'Сотрудник'}
				/>
			</Form.Item>
			<Form.Item name={'new_name'} label={'Name'}>
				<Input/>
			</Form.Item>
			<Form.Item name={'new_surname'} label={'Surname'}>
				<Input/>
			</Form.Item>
			<Form.Item name={'new_nickname'} label={'Nickname'}>
				<Input/>
			</Form.Item>
			<Form.Item name={'new_gender'} label={'Gender'}>
				<Select
					options={genderOptions}
				/>
			</Form.Item>
		</Form>
	</Modal>
}
