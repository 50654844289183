import {createEffect} from "effector";
import AWS from "aws-sdk";

export const getUnrecognizedTableDataFx = createEffect().use(async (payload) => {
	try{
		const response = await fetch(
			'https://wg8x98quyf.execute-api.eu-west-1.amazonaws.com/PortalAPI/unrecognized-images',
			{
				method: 'POST',
				body: JSON.stringify({
					client_id: payload?.client_id ? payload.client_id : 'amaa25d7c9',
					date: payload?.date ? payload.date : '06-05-2024'
				})
			}
		)

		const result = await response.json()
		console.log('getUnrecognizedTableDataFx:: Success', result)
		return result.body
	} catch (e){
		console.error(e)
	}
})
