import React, { useEffect } from "react";
import { useUnit } from "effector-react";
import {
  $badImagesData,
  $badImagesDate,
  $badImagesPlace,
  $lastEvaluatedkey,
  changeBadImagesDateEv,
  changeBadImagesPlaceEv,
  getBadImagesFx,
  getMoreBadImagesFx,
  loadMoreEv,
} from "../../models/badImagesModel";
import { Button, Card, DatePicker } from "antd";
import ImageFromS3 from "../../components/ImageFromS3/ImageFromS3";
import style from "./BadImages.module.css";
import { $userGroups } from "../../models/userModel";
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

const cliendIdOptions = [
  {
    value: "amaa25d7c9",
    label: "Amanat, ул.Достык, 232/1",
  },
  {
    value: "klba36t1h2",
    label: "Kolibrix, ул.Спартака, 26",
  },
  {
    value: "klba36x2c2",
    label: "Kolibrix, ул.Жунисова, 2/13",
  },
  {
    value: "glra12a4a6",
    label: "Galereya Novostroek, мкр.Аксай 2, 8/3",
  },
  {
    value: "glra15g5m8",
    label: "Galereya Novostroek, ул.Айманова, 140",
  },
  {
    value: "zasa05e1p1",
    label: "Zhaniya Aubakirova School, ул.Карасай батыр, 140",
  },
  {
    value: "crtz05x7d2",
    label: "Creatti, ул.Мангилик ел, 59",
  },
];

function BadImages() {
  const badImages = useUnit($badImagesData);
  const userGroups = useUnit($userGroups);
  const navigate = useNavigate();
  const badImagesDate = useUnit($badImagesDate);
  const badImagesPlace = useUnit($badImagesPlace);
  const changeBadImagesDate = useUnit(changeBadImagesDateEv);
  const changeBadImagesPlace = useUnit(changeBadImagesPlaceEv);
  const lastEvaluatedKey = useUnit($lastEvaluatedkey);
  const moreLoading = useUnit(getMoreBadImagesFx.pending);
  const loadMore = useUnit(loadMoreEv);

  useEffect(() => {
    if (badImages.length === 0) {
      getBadImagesFx();
    }
  }, [badImages]);

  useEffect(() => {
    if (Array.isArray(userGroups) && !userGroups.includes("portal_admin")) {
      navigate("/dashboard");
    }
  }, [userGroups, navigate]);
  return (
    <div className={style.bad_images_wrapper}>
      <div className={style.bad_images_title}>Bad Images</div>
      <DatePicker
        className={style.bad_images_datepicker}
        value={badImagesDate}
        onChange={changeBadImagesDate}
      />
      <CustomSelect
        options={cliendIdOptions}
        onSelect={changeBadImagesPlace}
        field={"client_id"}
        placeholder="Place"
        value={badImagesPlace}
      />
      <div className={style.bad_images_card_wrapper}>
        {badImages.map((item) => {
          return <BidImageCard key={item.user_id} {...item} />;
        })}
      </div>
      {/*{lastEvaluatedKey ? (*/}
      {/*  <Button*/}
      {/*    type={"primary"}*/}
      {/*    className={style.load_more_button}*/}
      {/*    onClick={loadMore}*/}
      {/*    loading={moreLoading}*/}
      {/*  >*/}
      {/*    Загрузить ещё*/}
      {/*  </Button>*/}
      {/*) : (*/}
      {/*  ""*/}
      {/*)}*/}
    </div>
  );
}

export default BadImages;

function BidImageCard(props) {
  const { cause, chekin_status, date, in_out, presigned_url, user_id } = props;

  return (
    <Card>
      <div>cause: {cause}</div>
      <div>chekin_status: {chekin_status}</div>
      <div>date: {date}</div>
      <div>in_out: {in_out}</div>
      <div>user_id: {user_id}</div>
      <ImageFromS3 presigned_url={presigned_url} />
    </Card>
  );
}
