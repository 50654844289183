import style from './FaceRec.module.css'
import {useEffect, useRef} from "react";
import * as faceapi from "face-api.js";
import {Button} from "antd";
export default function FaceRec(){

	const videoRef = useRef()
	const canvasRef = useRef()

	const takeStreenshot = () => {
		const canvas = document.createElement('canvas')
		canvas.width = videoRef.current.videoWidth
		canvas.height = videoRef.current.videoHeight
		canvas.getContext('2d').drawImage(videoRef.current, 0, 0)

		const img = document.createElement('img')
		img.src = canvas.toDataURL(('image/webp'))
		document.getElementById('screenshot').appendChild(img)
	}

	useEffect(() => {
		startVideo()
		videoRef && loadModels()
	}, []);

	const startVideo = () => {
		navigator.mediaDevices.getUserMedia({video: true})
			.then((currentStream) => {
				videoRef.current.srcObject = currentStream
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const loadModels = () => {
		Promise.all([
			faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
			faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
			faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
			faceapi.nets.faceExpressionNet.loadFromUri('/models')
		]).then(() => {
			faceMyDetect()
		})
	}

	const faceMyDetect = () => {
		setInterval(async () => {
			const detections = await faceapi.detectAllFaces(videoRef.current,
				new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions()

			canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(videoRef.current)
			faceapi.matchDimensions(canvasRef.current, {
				width: 940,
				height: 650
			})

			const resized = faceapi.resizeResults(detections, {
				width: 940,
				height: 650
			})

			faceapi.draw.drawDetections(canvasRef.current, resized)
			faceapi.draw.drawFaceLandmarks(canvasRef.current, resized)
			faceapi.draw.drawFaceExpressions(canvasRef.current, resized)
		}, 1000)
	}

	return <div className={style.myapp}>
		<div className={style.appvide}>
			<video crossOrigin='anonymous' ref={videoRef} autoPlay></video>
		</div>
		<canvas ref={canvasRef} width={940} height={650} className={style.appcanvas}/>
		<Button onClick={takeStreenshot}>
			Take screenshot
		</Button>
		<div id={'screenshot'} />
	</div>
}
