import React, { useEffect, useRef, useState } from 'react';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';

function QuickSightQ() {
  const [embedUrl, setEmbedUrl] = useState(null);
  const [dashboardLoaded, setDashboardLoaded] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {

    const fetchEmbedUrl = async () => {
      try {
        const response = await fetch('https://534tcargth.execute-api.eu-west-1.amazonaws.com/default/test-quicksight-q-lambda');
        const data = await response.json();
        // setEmbedUrl(data.embedUrl);
        setEmbedUrl(data.body.embedUrl);
        console.log('embedUrl: - ', data.body.embedUrl)
      } catch (error) {
        console.error('Error fetching embed URL:', error);
      }
    }

    if (!embedUrl) {
      fetchEmbedUrl();
    }
    const embedDashboard = async () => {
      // Создаем контекст для встраивания
      const embeddingContext = await createEmbeddingContext({
        onChange: (changeEvent, metadata) => {
          console.log('Context received a change', changeEvent, metadata);
        },
      });
      const frameOptions = {
        url: embedUrl, // replace this value with the url generated via embedding API
        container: containerRef.current,
        height: "700px",
        width: "100%",
        onChange: (changeEvent, metadata) => {
          switch (changeEvent.eventName) {
            case 'FRAME_MOUNTED': {
              console.log("Do something when the experience frame is mounted.");
              break;
            }
            case 'FRAME_LOADED': {
              console.log("Do something when the experience frame is loaded.");
              setDashboardLoaded(true)
              break;
            }
          }
        },
      };

      // const options = {
      //   url: embedUrl,
      //   container: containerRef.current,
      //   height: "700px",
      //   width: "100%",
      //   onChange: (changeEvent) => {
      //     console.log('Dashboard changed:', changeEvent);
      //   },
      //   // Дополнительные параметры для встраивания
      // };

      const contentOptions = {
        panelOptions: {
          panelType: 'FULL',
          // title: 'Custom Title',
          showQIcon: true,
        },
        // Uncomment below, if you prefer an experience closer to embedQSearchBar instead of a full panel.
        // panelOptions: {
        //     panelType: 'SEARCH_BAR',
        //     focusedHeight: '250px',
        //     expandedHeight: '500px',
        // },
        // showTopicName: false,
        // showPinboard: false,
        // allowTopicSelection: false,
        // allowFullscreen: false,
        searchPlaceholderText: 'Custom Search Placeholder',
        // themeOptions: {
        //   themeArn: 'arn:aws:quicksight:eu-west-01:411274530068:user/AWSReservedSSO_ops-data-eng-and-cv-perm-set_fc76eb39f7fff4f0/alikhan'
        // },
        onMessage: async (messageEvent, experienceMetadata) => {
          switch (messageEvent.eventName) {
            case 'Q_SEARCH_OPENED':
              console.log("Search bar expanded");
              break;
            case 'Q_SEARCH_FOCUSED':
              console.log("Search bar focused");
              break;
            case 'Q_SEARCH_CLOSED':
              console.log("Search bar collapsed");
              break;
            case 'Q_PANEL_ENTERED_FULLSCREEN':
              console.log("Experience entered full screen mode");
              break;
            case 'Q_PANEL_EXITED_FULLSCREEN':
              console.log("Experience exited full screen mode");
              break;
            case 'CONTENT_LOADED':
              console.log("Experience loaded");
              break;
            case 'ERROR_OCCURRED':
              console.error("An error occurred:", messageEvent.errorMessage);
              break;
            default:
              console.log("Unrecognized event:", messageEvent.eventName);
          }
        }
      };
      // debugger
      try {
        // Используем контекст для встраивания дашборда
        console.log('Embedding context:', embeddingContext)
        // debugger
        const embeddedGenerativeQnExperience = await embeddingContext.embedGenerativeQnA(frameOptions, contentOptions);
        window.embeddedGenerativeQnExperience = embeddedGenerativeQnExperience
        console.log('Dashboard embedded successfully');
      } catch (error) {
        console.error('Error embedding dashboard:', error);
      }
    };
    console.log('embedDashboard!!!!!!!!!!!!!!!!!!!!!!!')
    console.log('dashboardLoaded: - ', dashboardLoaded)
    if (dashboardLoaded === false) {
      embedDashboard();
    }
  }, );

  return <div className={'bla'}>
    <div style={{height: '90vh'}} ref={containerRef} ></div>
  </div>
}

export default QuickSightQ;