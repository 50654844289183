import {$dashboardList, $editDashboard, $showCreateDashboardModal, $viewDashboard} from "./stores";
import {getDashboardListFx} from "./effects";
import {resetEditDashboardEv, resetViewDashboardEv, setEditDashboardEv, toggleCreateDashboardModalEv} from "./events";
import {sendViewTenantItemFx} from "../portalUsersModel";

$dashboardList.on(getDashboardListFx.doneData, (_, payload) => payload)

$showCreateDashboardModal.on(toggleCreateDashboardModalEv, (state, payload) => {
	if(typeof payload === 'boolean') return payload
	return !state
})

$editDashboard.on(setEditDashboardEv, (_, payload) => payload)
	.reset(resetEditDashboardEv)

$viewDashboard.on(sendViewTenantItemFx.doneData, (_, payload) => payload)
	.reset(resetViewDashboardEv)
