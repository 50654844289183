import { useEffect, useState } from "react";
import { Image, Spin } from "antd";
import { $userKeys, getImageFromS3Fx } from "../../models/userModel";
import { useUnit } from "effector-react/compat";

export default function ImageFromS3(props) {
  const [src, setSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const { accessKeyId, secretAccessKey } = useUnit($userKeys);

  const { bucket_name, object_key, ...rest } = props;

  useEffect(() => {
    setLoading(true);
    async function getImage() {
      if (!props.presigned_url) {
        setSrc(
          await getImageFromS3Fx({
            bucket_name: props.bucket_name,
            object_key: props.object_key,
            accessKeyId: "AKIAV7QO7JUKGJF3OBFM",
            secretAccessKey: "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",
          }),
        );
      } else {
        setSrc(props.presigned_url);
      }
      setLoading(false);
    }
    getImage();
  }, [props]);

  return (
    <Spin spinning={loading}>
      <Image
        height={props.height || 250}
        width={props.width || null}
        src={src}
        {...rest}
      />
    </Spin>
  );
}
