import {Button, Form, Input, Modal, Radio, Select, Upload} from "antd";
import {useUnit} from "effector-react";
import {$showCreateModal, $userListPlaceId, changeShowCreateModalEv, createUserFx} from "../../models/userListModel";
import {UploadOutlined} from "@ant-design/icons";
import style from "./ClientUserList.module.css";
import {useState} from "react";

const selectOptions = [
	{
		label: 'Сотрудник',
		value: 'Сотрудник'
	},
	{
		label: 'Уволен',
		value: 'Уволен'
	},
	{
		label: 'Потенциальный сотрудник',
		value: 'Потенциальный сотрудник'
	},
]

const genderOptions = [
	{
		label: 'Мужчина',
		value: 'Male',
	},
	{
		label: 'Женщина',
		value: 'Female',
	}
]

export default function CreateUserModal(){

	const showCreateModal = useUnit($showCreateModal)
	const changeShowCreateModal = useUnit(changeShowCreateModalEv)
	const userListPlaceId = useUnit($userListPlaceId)
	const loading = useUnit(createUserFx.pending)

	const [form] = Form.useForm()
	const [saveDisabled, setSaveDisabled] = useState(true)

	const onClose = () => {
		changeShowCreateModal(false)
		form.resetFields()
	}

	const onFinish = (values) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => {
			createUserFx({
				...values,
				place_id: userListPlaceId,
				data_url: [
					reader.result
				]
			})
		});
		reader.readAsDataURL(values.file);
	}

	const normFile = (info) => {
		return info?.file
	};

	return <Modal
		open={showCreateModal}
		onCancel={onClose}
		title={'Добавить сотрудника'}
		onOk={form.submit}
		footer={null}
		// confirmLoading={pending}

	>
		<Form
			form={form}
			onFinish={onFinish}
			layout={"vertical"}
			style={{
				marginTop: '16px'
			}}
			onValuesChange={(changed, all) => {
				if(Object.values(all).some(item => !item)) {
					if (!saveDisabled) setSaveDisabled(true)
				} else {
					if (saveDisabled) setSaveDisabled(false)
				}
			}}
		>
			<Form.Item name="file" getValueFromEvent={normFile}>
				<Upload beforeUpload={() => false}>
					<Button icon={<UploadOutlined />}>Загрузить</Button>
				</Upload>
			</Form.Item>
			<Form.Item name={'new_name'} label={'Имя'}>
				<Input placeholder={'Введите имя'}/>
			</Form.Item>
			<Form.Item name={'new_surname'} label={'Фамилия'}>
				<Input placeholder={'Введите фамилию'}/>
			</Form.Item>
			<Form.Item name={'new_department'} label={'Департамент'}>
				<Input placeholder={'Департамент'}/>
			</Form.Item>
			<Form.Item name={'new_position'} label={'Должность'}>
				<Input placeholder={'Введите должность сотрудника'}/>
			</Form.Item>
			<Form.Item name={'new_category'} label={'Статус'}>
				<Select
					options={selectOptions}
					placeholder={'Выбрать статус'}
				/>
			</Form.Item>
			<Form.Item name={'new_gender'} label={'Пол'}>
				<Radio.Group>
					<Radio value={'Female'}>Женщина</Radio>
					<Radio value={'Male'}>Мужчина</Radio>
				</Radio.Group>
			</Form.Item>
		</Form>
		<Button
			onClick={form.submit}
			className={style.modal_button}
			type={'primary'}
			style={{marginRight: '8px'}}
			disabled={saveDisabled}
			loading={loading}
		>
			Сохранить
		</Button>
		<Button
			onClick={onClose}
			className={style.modal_button}
		>
			Отмена
		</Button>
	</Modal>
}
