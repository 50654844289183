import {createStore} from "effector";

export const $portalUsersData = createStore([])
$portalUsersData.watch(state => console.log('$portalUsersData', state))

export const $showCreatePortalUserModal = createStore(false)

export const $editPortalUser = createStore(null)

export const $viewPortalUser = createStore(null)

export const $tenantsList = createStore([])

export const $placesList = createStore([])

export const $showCreateTenantModal = createStore(false)

export const $showCreatePlaceModal = createStore(false)
export const $editPortalPlace = createStore(null)
export const $viewPlaceData = createStore(null)

export const $showCreateOrgModal = createStore(false)
export const $editOrg = createStore(null)
export const $viewOrg = createStore(null)
