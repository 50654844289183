import creatti_logo from '../../images/creatti_logo.png'
import style from './Registration.module.css'
import {Button} from "antd";

export default function StartPage(props){

	const { toNextPage } = props

	return <>
		<img src={creatti_logo} className={style.creatti_logo}/>
		<div className={style.gradient}></div>
		<div className={style.button_wrapper}>
			<Button type='primary' className={style.begin_button} onClick={toNextPage}>Начать регистрацию</Button>
			<div className={style.copyright}>© FOQUS, 2024</div>
		</div>
	</>
}
