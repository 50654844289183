import {createStore} from "effector";
import dayjs from "dayjs";
function getCurrentDate() {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero based
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}.${month}.${day}`;
}
const filtersInitialState = {
	checkin_status: '',
	client_id: 'klba36t1h2',
	date: getCurrentDate(),
}

export const $testingTableData = createStore([])
$testingTableData.watch(state => {
	console.log('$testingTableData', state)
})

export const $testingStartTableData = createStore([])

export const $testingFilters = createStore(filtersInitialState)
$testingFilters.watch(state => console.log('$testingFilters', state))
