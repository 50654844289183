import { sample } from "effector";
import {
  changeDuplicateDateEv,
  changeDuplicatesPlaceEv,
  loadMoreEv,
  resetLastEvaluatedKeyEv,
} from "./events";
import {
  $duplicatesDate,
  $duplicatesPlace,
  $duplicatesStore,
  $lastEvaluatedkey,
} from "./stores";
import {
  getDuplicatesFx,
  getMoreDuplicatesFx,
  mergeDuplicatesFx,
} from "./effects";

$lastEvaluatedkey
  .on(getMoreDuplicatesFx.doneData, (_, data) => {
    if (data.results.length > 0) {
      return data.LastEvaluatedKey;
    }
    return null;
  })
  .on(getDuplicatesFx.doneData, (_, clock) => clock.LastEvaluatedKey)
  .reset(resetLastEvaluatedKeyEv);

$duplicatesStore.on(getMoreDuplicatesFx.doneData, (state, response) => {
  return [...state, ...response.results];
});

sample({
  clock: changeDuplicateDateEv,
  target: $duplicatesDate,
});

sample({
  clock: changeDuplicatesPlaceEv,
  fn: (clock) => clock.value,
  target: $duplicatesPlace,
});

sample({
  source: $duplicatesPlace,
  clock: $duplicatesDate.updates,
  fn: (source, clock) => {
    return {
      date: clock.format("DD-MM-YYYY"),
      place_id: source,
    };
  },
  target: getDuplicatesFx,
});

sample({
  source: $duplicatesDate,
  clock: $duplicatesPlace.updates,
  fn: (source, clock) => {
    return {
      date: source.format("DD-MM-YYYY"),
      place_id: clock,
    };
  },
  target: getDuplicatesFx,
});

sample({
  clock: getDuplicatesFx.doneData,
  fn: (clock) => clock.results,
  target: $duplicatesStore,
});

sample({
  clock: mergeDuplicatesFx.doneData,
  fn: (clock) => {},
  target: "",
});

sample({
  source: [$duplicatesDate, $duplicatesPlace, $lastEvaluatedkey],
  clock: loadMoreEv,
  fn: ([date, place_id, lastEvaluatedKey]) => {
    return {
      date: date.format("DD-MM-YYYY"),
      place_id,
      lastEvaluatedKey,
    };
  },
  target: getMoreDuplicatesFx,
});
