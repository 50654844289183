import React from "react";
import { Button, Card, Col, Form, Input, Radio, Row, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import style from "../SchoolUserList.module.css";

const workTypeOptions = [
  {
    label: "Стандартный",
    value: "Стандартный",
  },
  {
    label: "Нестандартный",
    value: "Нестандартный",
  },
];

function StudentForm() {
  return (
    <>
      <Row justify={"space-between"} gutter={8}>
        <Col span={8}>
          <Form.Item name={["properties", "grade"]} label={"Класс"}>
            <Input placeholder={"Введите класс"} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {" "}
          <Form.Item name={["properties", "shift"]} label={"Смена"}>
            <Input placeholder={"Введите смену"} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name={["properties", "work_type"]}
            label={"График посещений"}
          >
            <Select options={workTypeOptions} />
          </Form.Item>
        </Col>
      </Row>
      <Form.List name={["properties", "contacts"]}>
        {(fields, { add, remove }) => (
          <>
            <div className={style.contacts}>
              {fields.map((field) => (
                <div className={style.modal_contact}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: 10,
                    }}
                  >
                    <div className={style.modal_contact_title}>Контакт</div>
                    <CloseOutlined
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  </div>
                  <Row justify={"space-between"} gutter={8}>
                    <Col span={8}>
                      <Form.Item
                        name={[field.name, "contact_name"]}
                        label={"Имя контакта"}
                      >
                        <Input placeholder={"Введите имя контакта"} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={[field.name, "contact_surname"]}
                        label={"Фамилия контакта"}
                      >
                        <Input placeholder={"Введите фамилию контакта"} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={[field.name, "contact_type"]}
                        label={"Тип контакта"}
                      >
                        <Input placeholder={"Введите тип контакта"} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify={"space-between"} gutter={8}>
                    <Col span={12}>
                      <Form.Item
                        name={[field.name, "contact_mobile"]}
                        label={"Телефонный номер контакта"}
                        style={{ marginBottom: 0 }}
                      >
                        <Input placeholder={"Введите номер контакта"} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={[field.name, "contact_email"]}
                        label={"Почта контакта"}
                        style={{ marginBottom: 0 }}
                      >
                        <Input placeholder={"Введите адрес контакта"} />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
            <Button onClick={() => add()}>+ Добавить контакт</Button>
          </>
        )}
      </Form.List>
    </>
  );
}

export default StudentForm;
