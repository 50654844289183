import React from 'react';
import {Descriptions, Modal} from "antd";
import {useUnit} from "effector-react";
import {$viewPlaceData, resetViewPortalPlaceEv} from "../../../../models/portalUsersModel";

function ViewPortalPlace() {
	const data = useUnit($viewPlaceData)
	const resetViewPlace = useUnit(resetViewPortalPlaceEv)

	return (
		<Modal
			open={!!data}
			footer={null}
			onCancel={resetViewPlace}
			destroyOnClose
			title={'Просмотр place_id'}
			width={557}
		>
			<Descriptions>
				{
					data && Object.keys(data).map(item => {
						return <Descriptions.Item label={item}>
							{data[item]}
						</Descriptions.Item>
					})
				}
			</Descriptions>
		</Modal>
	);
}

export default ViewPortalPlace;
