import { useUnit } from "effector-react";
import {
  $potentialEmp,
  changeChosenPotentialEmpEv,
  getPotentialEmpFx,
} from "../../models/userListModel";
import { useEffect } from "react";
import { Button, Card, Collapse, Popconfirm, Skeleton } from "antd";
import ImageFromS3 from "../../components/ImageFromS3/ImageFromS3";
import { PlusCircleOutlined } from "@ant-design/icons";
import style from "./UserList.module.css";
const handleCopy = (hiddenText) => {
  navigator.clipboard
    .writeText(hiddenText)
    .then(() => {
      console.log("Text copied to clipboard:", hiddenText);
    })
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};

export default function PotentialEmp() {
  const potentialEmp = useUnit($potentialEmp);
  const loading = useUnit(getPotentialEmpFx.pending);
  const changeChosenPotentialEmp = useUnit(changeChosenPotentialEmpEv);

  useEffect(() => {
    if (potentialEmp.length === 0) {
      getPotentialEmpFx();
    }
  }, [potentialEmp]);

  if (loading) {
    return (
      <Card style={{ marginTop: "20px" }}>
        <Skeleton active />
      </Card>
    );
  }

  return (
    <div className={style.potential_emp_wrapper}>
      {potentialEmp.map((item) => {
        return (
          <Card
            key={`potential-employee-${item.user_id}`}
            style={{ position: "relative" }}
          >
            <div>
              User Id: {item.user_id}
              <button
                onClick={() => handleCopy(item.user_id)}
                style={{
                  marginLeft: "15px",
                  cursor: "pointer",
                }}
              >
                Click to copy
              </button>
            </div>
            <div>Status: {item.status}</div>
            <div>Кол-во посещений: {item.count}</div>
            <Button
              className={style.make_emp_button}
              icon={<PlusCircleOutlined />}
              onClick={() =>
                changeChosenPotentialEmp({
                  user_id: item.user_id,
                })
              }
            />
            <Collapse
              style={{ marginTop: "20px" }}
              expandIconPosition={"end"}
              items={[
                {
                  key: `potential-employee-images-${item.user_id}`,
                  label: "Images",
                  children: (
                    <div className={style.collapse_images_content}>
                      {item.images.map((image) => {
                        return (
                          <div
                            key={`potential-emplyee-image-${image.file_name}`}
                          >
                            <div>
                              File name:
                              <button
                                onClick={() => handleCopy(image.file_name)}
                                style={{
                                  marginLeft: "15px",
                                  cursor: "pointer",
                                }}
                              >
                                Click to copy
                              </button>
                            </div>
                            <ImageFromS3
                              // bucket_name={image.bucket_name}
                              // object_key={image.object_key}
                              presigned_url={image["presigned-url"]}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ),
                },
              ]}
            />
          </Card>
        );
      })}
    </div>
  );
}
