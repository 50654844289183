import style from "./SearchById.module.css";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import dayjs from "dayjs";
import { useUnit } from "effector-react";
import {
  $searchByIdData,
  $searchByIdSearchValue,
  changeSearchValueEv,
  getSearchByIdDataFx,
  searchClientsEv,
  disassociateUserFx,
} from "../../models/searchByIdModel";
import { useEffect, useState } from "react";
import { Image, Input, Spin, Button, Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import { $userGroups } from "../../models/userModel";
import ImageFromS3 from "../../components/ImageFromS3/ImageFromS3";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";

const handleCopy = (hiddenText) => {
  navigator.clipboard
    .writeText(hiddenText)
    .then(() => {
      console.log("Text copied to clipboard:", hiddenText);
    })
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};

export default function SearchById() {
  const searchByIdData = useUnit($searchByIdData);
  const searchByIdSearchValue = useUnit($searchByIdSearchValue);
  const changeSearchValue = useUnit(changeSearchValueEv);
  const navigate = useNavigate();
  const userGroups = useUnit($userGroups);
  const searchClients = useUnit(searchClientsEv);
  const loading = useUnit(getSearchByIdDataFx.pending);
  const disassociateUser = useUnit(disassociateUserFx);

  const onInputChange = (e) => {
    changeSearchValue(e.target.value);
  };

  useEffect(() => {
    if (Array.isArray(userGroups) && !userGroups.includes("portal_admin")) {
      navigate("/dashboard");
    }
  }, [userGroups, navigate]);

  return (
    <div>
      <div className={style.filtration_wrapper}>
        <div className={style.filtration_title}>
          Filtration Associated Faces
        </div>
        <Input
          allowClear
          className={style.client_input}
          placeholder={"Enter client ID"}
          value={searchByIdSearchValue}
          onChange={onInputChange}
        />
        <Button type={"primary"} onClick={searchClients} loading={loading}>
          Search
        </Button>
      </div>
      {searchByIdData.length > 0 ? (
        <div
          style={{
            display: "flex",
            maxWidth: "100%",
            flexWrap: "wrap",
          }}
        >
          {searchByIdData.map((item, index) => {
            return (
              <div
                key={`searchById-client-${item.file_name}-${index}`}
                style={{
                  maxWidth: "200px",
                  minWidth: "200px",
                  marginRight: "25px",
                  marginBottom: "25px",
                }}
              >
                <div style={{ marginBottom: "15px" }}>
                  File name:
                  <div className={style.action_buttons_wrapper}>
                    <button
                      onClick={() => handleCopy(item.file_name)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Click to copy
                    </button>
                    <Popconfirm
                      onConfirm={() => {
                        disassociateUser({ file_name: item.object_key });
                      }}
                      title={"Are you sure?"}
                      icon={
                        <QuestionCircleOutlined
                          style={{
                            color: "red",
                          }}
                        />
                      }
                    >
                      <button
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Disassociate
                      </button>
                    </Popconfirm>
                  </div>
                </div>
                <ImageFromS3
                  // bucket_name={item.bucket_name}
                  // object_key={item.object_key}
                  presigned_url={item.presigned_url}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div className={style.warning_message}>Input client ID</div>
      )}
    </div>
  );
}
