import React from 'react';
import {Button, Form, Input, Space} from "antd";

function DashboardForm(props) {
	const {form, onFinish, onCancel, loading, type} = props

	return (
		<Form
			layout={"vertical"}
			form={form}
			onFinish={onFinish}
		>
			<Form.Item
				name='partition_key'
				label='partition_key'
			>
				<Input placeholder='Enter partition_key'/>
			</Form.Item>
			<Form.Item
				name='sort_key'
				label='sort_key'
			>
				<Input placeholder='Enter sort_key'/>
			</Form.Item>
			<Form.Item
				name='dashboard_name'
				label='dashboard_name'
			>
				<Input placeholder='Enter dashboard_name'/>
			</Form.Item>
			<Space>
				{
					type === 'create' ?
						<>
							<Button style={{width: 198}} type={"primary"} onClick={form.submit} loading={loading}>Добавить</Button>
							<Button style={{width: 198}} onClick={onCancel}>Отменить</Button>
						</>
						:
						<>
							<Button style={{width: 159}} type={"primary"} onClick={form.submit} loading={loading}>Сохранить</Button>
							<Button style={{width: 159}} onClick={onCancel}>Отменить</Button>
							<Button style={{width: 159, padding: 4}} type={'dashed'}>Удалить пользователя</Button>
						</>
				}
			</Space>
		</Form>
	);
}

export default DashboardForm;
