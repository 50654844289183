import React from 'react';
import {Descriptions, Modal} from "antd";
import {$viewPortalUser, resetViewUserEv} from "../../../../models/portalUsersModel";
import {useUnit} from "effector-react";

function ViewPortalUser() {
	const viewPortalUser = useUnit($viewPortalUser);
	const resetViewUser = useUnit(resetViewUserEv);

	const onCancel = () => {
		resetViewUser()
	}

	return (
		<Modal open={!!viewPortalUser} footer={null} onCancel={onCancel}>
			<Descriptions layout={'vertical'}>
				<Descriptions.Item label="UID">{viewPortalUser?.sort_key}</Descriptions.Item>
				<Descriptions.Item label="Name">{viewPortalUser?.name}</Descriptions.Item>
				<Descriptions.Item label="Email">{viewPortalUser?.email}</Descriptions.Item>
				<Descriptions.Item label="category">{viewPortalUser?.category}</Descriptions.Item>
				<Descriptions.Item label="active">{viewPortalUser?.active}</Descriptions.Item>
				<Descriptions.Item label="partition_key">{viewPortalUser?.partition_key}</Descriptions.Item>
			</Descriptions>
		</Modal>
	);
}

export default ViewPortalUser;
