import React from 'react';
import {Form, Modal} from "antd";
import DashboardForm from "./DashboardForm";
import {useUnit} from "effector-react";
import {$showCreateDashboardModal, toggleCreateDashboardModalEv} from "../../../models/dashboardModel";
import {sendCreateTenantItemEv, sendCreateTenantItemFx} from "../../../models/portalUsersModel";

function CreateDashboardModal(props) {
	const show = useUnit($showCreateDashboardModal)
	const toggleCreateDashboardModal = useUnit(toggleCreateDashboardModalEv)
	const [createForm] = Form.useForm()
	const sendCreateDashboard = useUnit(sendCreateTenantItemEv)
	const loading = useUnit(sendCreateTenantItemFx.pending)

	const onCancel = () => {
		toggleCreateDashboardModal(false)
		createForm.resetFields()
	}

	const onFinish = (values) => {
		sendCreateDashboard(values)
	}
	return (
		<Modal
			open={show}
			onCancel={onCancel}
			footer={null}
			destroyOnClose
			title={'Добавить dashboard'}
			width={557}
		>
			<DashboardForm
				form={createForm}
				onFinish={onFinish}
				onCancel={onCancel}
				loading={loading}
				type={'create'}
			/>
		</Modal>
	);
}

export default CreateDashboardModal;
