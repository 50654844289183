import '../../App.css';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import {withAuthenticator, Heading, Link, useAuthenticator} from '@aws-amplify/ui-react';
import { fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import AWS from 'aws-sdk'
import {EditOutlined, EyeOutlined, PieChartOutlined, PlusOutlined} from '@ant-design/icons';
import {Select, Table, Button} from 'antd';
import {useUnit} from 'effector-react';
import {$userGroups, setUserGroupsEv} from '../../models/userModel';
import {
  $dashboardList,
  getDashboardListFx,
  setEditDashboardEv,
  toggleCreateDashboardModalEv
} from "../../models/dashboardModel";
import CreateDashboardModal from "./modals/CreateDashboardModal";
import EditDashboardModal from "./modals/EditDashboardModal";
import ViewDashboard from "./modals/ViewDashboard";
import {sendViewTenantItemEv, sendViewTenantItemFx} from "../../models/portalUsersModel";


function Dashboard() {
  const { user } = useAuthenticator();
  const dashboardRef = useRef([]);
  const [dashboardId, setDashboardId] = useState('');
  const [embeddedDashboard, setEmbeddedDashboard] = useState(null);
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const [embeddingContext, setEmbeddingContext] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [userName, setUserName] = useState('');
  const [userGroup, setUserGroup] = useState([]);
  const [dashboardData, setDashboardData] = useState(null);
  const setUserGroups = useUnit(setUserGroupsEv)
  const userGroups = useUnit($userGroups)
  const dashboardList = useUnit($dashboardList)
  const toggleCreateDashboardModal = useUnit(toggleCreateDashboardModalEv)
  const setEditDashboard = useUnit(setEditDashboardEv)
  const sendViewTenantItem = useUnit(sendViewTenantItemEv)
  const viewLoading = useUnit(sendViewTenantItemFx.pending)
  const columns = [
    {
      title: 'actions',
      key: 'actions',
      render: (record) => {
        return <div>
          <Button icon={<EditOutlined />} onClick={() => setEditDashboard(record)}/>
          <Button icon={<EyeOutlined />} onClick={() => sendViewTenantItem(record)} loading={viewLoading}/>
        </div>
      }
    },
    {
      title: 'dashboard_name',
      dataIndex: 'dashboard_name',
      key: 'dashboard_name',
    },
    {
      title: 'disabled',
      dataIndex: 'disabled',
      key: 'disabled',
    },
    {
      title: 'partition_key',
      dataIndex: 'partition_key',
      key: 'partition_key',
    },
    {
      title: 'sort_key',
      dataIndex: 'sort_key',
      key: 'sort_key',
    },
  ]

  function useInitializeUserGroups(user) {
    const setUserGroups = useUnit(setUserGroupsEv);

    useEffect(() => {
      const initializeUserGroups = async () => {
        try {
          if (user) {
            const session = await fetchAuthSession();
            const group = session.tokens.accessToken.payload['cognito:groups'];
            setUserGroups(group || []);
          }
        } catch (error) {
          console.error('Error fetching user groups:', error);
          setUserGroups([]);
        }
      };

      initializeUserGroups();
    }, [user, setUserGroups]);
  }

  useInitializeUserGroups(user);

  // useEffect(() => {
  //   const cloudWatch = new AWS.CloudWatch({
  //     region: 'eu-west-1',
  //     accessKeyId: 'AKIAV7QO7JUKGJF3OBFM',
  //     secretAccessKey: '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',
  //   });
  //
  //   const params = {
  //     DashboardName: 'Backend',
  //   };
  //
  //   // cloudWatch.getDashboard(params, (err, data) => {
  //   //   if (err) {
  //   //     console.log('Error fetching CloudWatch dashboard:', err);
  //   //   } else {
  //   //     console.log('getDashboard data: - ', data)
  //   //     setDashboardData(data.DashboardBody);
  //   //   }
  //   // });
  // }, []);

  useEffect(() => {
    const fetchUserGroup = async () => {
      // debugger
      // console.log('fetchUserGroup');
      try {
        const user = await fetchAuthSession();
        // debugger
        const group = user.tokens.accessToken.payload['cognito:groups'];
        setUserGroup(group || []);
        console.log('group: - ', group[0])
      } catch (error) {
        console.error('Error fetching user group:', error);
      }
    };

    fetchUserGroup();
  }, []);

  async function handleFetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      // setDashboardId(userAttributes['custom:DashboardId']);
      // await changeDashboard(userAttributes['custom:DashboardId']);
      console.log('userAttributes', userAttributes);
      setUserName(userAttributes['name']);
      return userAttributes
    } catch (error) {
      console.log(error);
    }
  }



  useEffect(() => {
    const timeout = setTimeout(() => {

      fetch("https://9iz15iyde3.execute-api.eu-west-1.amazonaws.com/embed/anonymous-embed-v2"
      ).then((response) => response.json()
      ).then((response) => {
        setDashboardUrl(response.EmbedUrl)
      })
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const createContext = async () => {
    const context = await createEmbeddingContext();
    setEmbeddingContext(context);
  }

  useEffect(() => {
    if (dashboardUrl) { createContext() }
  }, [dashboardUrl])

  useEffect(() => {
    if (embeddingContext) { embed(); }
  }, [embeddingContext])

  const embed = async (id) => {
    let options = {
      url: dashboardUrl,
      container: dashboardRef.current,
      height: "500px",
      width: "600px",
    };
    const userInfo = await handleFetchUserAttributes()
    console.log('userInfo: - ', userInfo)
    // https://eu-west-1.quicksight.aws.amazon.com/embed/c61bf940118d4bb8822043d095262699/dashboards/478ed133-4942-4841-8e41-ac3402ef4494?code=AYABeP9VxKsT-j3oJm1mApgAKFkAAAABAAdhd3Mta21zAEthcm46YXdzOmttczpldS13ZXN0LTE6MzU2MzA1MTgzMDczOmtleS83ZTYwMTNkMC0xM2I0LTRmODItYmI1NC00YjllMTM4NzczNjYAuAECAQB4IZt0zcr71c1qotPywu6knKQF9RerzWvIw9ebvDgZ4T0BaHgqMtvadgyLAc0r78eFswAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDG5mdcl_K-CDeKJGTgIBEIA7eaGqy4-WdtNwQj1rwnLTUaMPPAyPlZNQq_jEuzRAT81GQGaRAnzVm-F9NoTShkOnuchu1YTQrT8RaZ8CAAAAAAwAABAAAAAAAAAAAAAAAAAAqR-bLVg6y3moVB_XuTk0ov____8AAAABAAAAAAAAAAAAAAABAAAAmxaVbsrCVeWUmJK3R-P5eBBU8kH9IdFlxxcp2-kbT3BEPuKWjoFgmkodX6TFylS4M41Ibu5oF_hJSkR8AblpnP4_DQX1v4J74sG_3yYo5Im6DhS_lKsHNIbNwFxw3G5QAC2kul91L6nGGkWs8W9tXYUR_lAJVe4BZ1WIDuYL15cH74bBtSxbiAbdHwQHOslEXeumKGjIQGWskLbLOdidzIVWmK5DoEv9l-SU-g%3D%3D&identityprovider=quicksight&isauthcode=true
    // let newUrl = "https://eu-west-1.quicksight.aws.amazon.com/embed/3bfdf79a968847789735d5d10d5d6570/dashboards/478ed133-4942-4841-8e41-ac3402ef4494?code=AYABeByuSyA7V3oLYsR07pCcR0QAAAABAAdhd3Mta21zAEthcm46YXdzOmttczpldS13ZXN0LTE6MzU2MzA1MTgzMDczOmtleS83ZTYwMTNkMC0xM2I0LTRmODItYmI1NC00YjllMTM4NzczNjYAuAECAQB4IZt0zcr71c1qotPywu6knKQF9RerzWvIw9ebvDgZ4T0BwKSEFgh8_DYRiYK-bHFFPAAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDF9Xuh8dZI6X-MUYRQIBEIA7r9rXlXf8-PkmWUC7Na4wswX5xzH9d7KOHaguUOb3NWCBAWC-RybQxAY4kCtjGogkQZSaRqX2fYkCTlkCAAAAAAwAABAAAAAAAAAAAAAAAAAAhDj3G762sZEVALtwcrFvhv____8AAAABAAAAAAAAAAAAAAABAAAAm20jOJ2M6doZXOS8m6HYcjlmwpiJeYdAE-ptzH6nFappA06SWjoWsBs1dEi5zRGEOpWDSM9oZm06KoX1f3QdrIphH86MrGCMybIAsu148eO8jqcml_P_YRDvDqOejL7OX2yO61WqpJHEZSg-eC-Z5xPyLnH4MdAcckrxFgyi4JY6GEQLJyIHzyniX6WFZCrAjv86CeRcEwkczsDVtiqg_-nwT0paBIj707PCoQ%3D%3D&identityprovider=quicksight&isauthcode=true";
    let replacedUrl = ''
    console.log('embed id: -', id)
    console.log('options: - ', options)
    if(id){
      console.log('replacedUrl if id')
      if(!options.url){
        replacedUrl = "/" + '478ed133-4942-4841-8e41-ac3402ef4494' + "?"
      } else {
        replacedUrl = options.url.replace(/\/([^\/?]+)\?/, "/" + userInfo['custom:DashboardId'] + "?");
      }
    } else {
      console.log('replacedUrl if no id')
      replacedUrl = options.url.replace(/\/([^\/?]+)\?/, "/" + userInfo['custom:DashboardId'] + "?");
      console.log('replacedUrl: - ', replacedUrl)
    }
    replacedUrl = options.url.replace(/\/([^\/?]+)\?/, "/" + userInfo['custom:DashboardId'] + "?");
    options.url = replacedUrl
    console.log('options.url: - ',options.url)
    const newEmbeddedDashboard = await embeddingContext.embedDashboard(options);

    setEmbeddedDashboard(newEmbeddedDashboard);
  };

  useEffect(() => {
    if (embeddedDashboard) {
      embeddedDashboard.navigateToDashboard(dashboardId, {})
    }
  }, [dashboardId])

  const changeDashboardBySelect = async (e) => {
    // debugger
    const dashboardId = e
    setDashboardId(dashboardId)
  }

  const changeDashboardByEffect = async (e) => {
    const dashboardId = e
    setDashboardId(dashboardId)
    // embed(dashboardId)
  }

  useEffect(() => {
    (async () => {
      try {
        const userDashboardId= await handleFetchUserAttributes();
        console.log(userDashboardId)
        changeDashboardByEffect(userDashboardId)
      } catch (err) {
        console.log('Error occured when fetching books');
      }
    })();
  }, []);

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
  const items = [
    getItem('Dashboard', '1', <PieChartOutlined />),

    // getItem('Option 2', '2', <DesktopOutlined />),
    // getItem('User', 'sub1', <UserOutlined />, [
    //   getItem('Tom', '3'),
    //   getItem('Bill', '4'),
    //   getItem('Alex', '5'),
    // ]),
    // getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
    // getItem('Files', '9', <FileOutlined />),
  ];

  const dsahbordIdsDictionaries = [
    '478ed133-4942-4841-8e41-ac3402ef4494', '2c61e87c-bb84-4d0e-b6bc-87037013d81a', '252e62c9-c3f4-46b0-9b75-0aaea22eff5a', '0cbb8f53-ff88-4798-99ba-5a57880efeab', 'c1b645d3-6293-4d46-be2c-7d9cb25857db', '59225a74-dd26-40d6-9bae-d7f366b36094', 'c7322b99-8a43-4796-afd9-a30d3e237245', '2f4a6722-0750-4f6d-8d82-864d975b4976', '36317c0a-107b-493a-9c43-742ac35ebfa3'
  ]

  useEffect(() => {
    getDashboardListFx()
  }, [])

  return (
    <div style={{textAlign: 'left'}}>
      {userGroups?.includes('portal_admin') ?
        <Select
          defaultValue={'478ed133-4942-4841-8e41-ac3402ef4494'}
          style={{
            width: '250px',
            // marginBottom: '25px'
          }}
          placeholder="Select a Dashboard"
          optionFilterProp="label"
          // style={{ width: 120 }}
          // value={dashboardId}
          onChange={changeDashboardBySelect}
          options={[
            { value: '478ed133-4942-4841-8e41-ac3402ef4494', label: 'Amanat' },
            { value: '252e62c9-c3f4-46b0-9b75-0aaea22eff5a', label: 'Kolibrix' },
            { value: '0cbb8f53-ff88-4798-99ba-5a57880efeab', label: 'Russian Demo' },
            { value: '59225a74-dd26-40d6-9bae-d7f366b36094', label: 'Investor Demo' },
            { value: '2f4a6722-0750-4f6d-8d82-864d975b4976', label: 'ASchool' },
            { value: '36317c0a-107b-493a-9c43-742ac35ebfa3', label: 'HR event'},
            { value: '46c663cd-39e9-45ce-a547-57f8c83d7da5', label: 'Demo HR'},
          ]}
        /> : ''
      }
      {
        userGroups?.includes('portal_admin') ?
          <>
            <CreateDashboardModal />
            <EditDashboardModal />
            <ViewDashboard />
            <Button icon={<PlusOutlined />} style={{display: 'block', margin: '10px 0'}} onClick={toggleCreateDashboardModal}/>
            <Table
              size={"small"}
              dataSource={dashboardList}
              columns={columns}
            />
          </>
          :
          ''
      }
      <div ref={dashboardRef}/>
    </div>

  );
};

export default withAuthenticator(Dashboard, {hideSignUp: true});
