import React from 'react';
import {Button, Form, Input, Space} from "antd";

function PortalPlacesForm(props) {
	const {form, onFinish, onCancel, loading, type} = props

	return (
		<Form
			layout={"vertical"}
			form={form}
			onFinish={onFinish}
		>
			<Form.Item
				name="partition_key"
				label="partition_key"
			>
				<Input placeholder="Введите partition_key" />
			</Form.Item>
			<Form.Item
				name="sort_key"
				label="sort_key"
			>
				<Input placeholder="Введите sort_key" />
			</Form.Item>
			<Form.Item
				name="admin_place"
				label="admin_place"
			>
				<Input placeholder="Введите admin_place" />
			</Form.Item>
			<Form.Item
				name="city"
				label="city"
			>
				<Input placeholder="Введите city" />
			</Form.Item>
			<Form.Item
				name="place_address"
				label="place_address"
			>
				<Input placeholder="Введите place_address" />
			</Form.Item>
			<Form.Item
				name="place_id"
				label="place_id"
			>
				<Input placeholder="Введите place_id" />
			</Form.Item>
			<Form.Item
				name="service"
				label="service"
			>
				<Input placeholder="Введите service" />
			</Form.Item>
			<Form.Item
				name="work_zones"
				label="work_zones"
			>
				<Input placeholder="Введите work_zones" />
			</Form.Item>
			<Form.Item
				name="average_bill"
				label="average_bill"
			>
				<Input placeholder="Введите average_bill" />
			</Form.Item>
			<Form.Item
				name="industry"
				label="industry"
			>
				<Input placeholder="Введите industry" />
			</Form.Item>
			<Form.Item
				name="subindustry"
				label="subindustry"
			>
				<Input placeholder="Введите subindustry" />
			</Form.Item>
			<Form.Item
				name="place_category"
				label="place_category"
			>
				<Input placeholder="Введите place_category" />
			</Form.Item>
			<Form.Item
				name="work_hours"
				label="work_hours"
			>
				<Input placeholder="Введите work_hours" />
			</Form.Item>
			<Form.Item
				name="traffic_level"
				label="traffic_level"
			>
				<Input placeholder="Введите traffic_level" />
			</Form.Item>
			<Form.Item
				name="social_accounts"
				label="social_accounts"
			>
				<Input placeholder="Введите social_accounts" />
			</Form.Item>
			<Form.Item
				name="one_time_payment"
				label="one_time_payment"
			>
				<Input placeholder="Введите one_time_payment" />
			</Form.Item>
			<Form.Item
				name="start_date"
				label="start_date"
			>
				<Input placeholder="Введите start_date" />
			</Form.Item>
			<Form.Item
				name="additional_info"
				label="additional_info"
			>
				<Input placeholder="Введите additional_info" />
			</Form.Item>
			<Form.Item
				name="telegram_chat"
				label="telegram_chat"
			>
				<Input placeholder="Введите telegram_chat" />
			</Form.Item>
			<Form.Item
				name="telegram_token"
				label="telegram_token"
			>
				<Input placeholder="Введите telegram_token" />
			</Form.Item>
			<Space>
				{
					type === 'create' ?
						<>
							<Button style={{width: 198}} type={"primary"} onClick={form.submit} loading={loading}>Добавить</Button>
							<Button style={{width: 198}} onClick={onCancel}>Отменить</Button>
						</>
						:
						<>
							<Button style={{width: 159}} type={"primary"} onClick={form.submit} loading={loading}>Сохранить</Button>
							<Button style={{width: 159}} onClick={onCancel}>Отменить</Button>
							<Button style={{width: 159, padding: 4}} type={'dashed'}>Удалить пользователя</Button>
						</>
				}
			</Space>
		</Form>
	);
}

export default PortalPlacesForm;
