import {createStore} from "effector";

export const $dashboardList = createStore([])
$dashboardList.watch((state) => console.log('$dashboardList', state))

export const $showCreateDashboardModal = createStore(false)

export const $editDashboard = createStore(null)

export const $viewDashboard = createStore(null)
