import style from './Registration.module.css'

export default function CustomSlider(props) {

	const { points, activePoint } = props

	return <div className={style.info_slider}>
		<div className={style.info_slider_point}/>
		{
			points.map(item => {
				return <div key={`slider-point-${item.id}`} className={style.info_slider_point_active} style={{
					top: '-3px',
					right: '50%',
					transform: 'translateX(50%)'
				}}>
					{
						item.tooltip ?
							<div className={style.slider_point_tooltip} style={{
								background: item.id === activePoint ?
									'linear-gradient(36deg, rgba(49,208,84,1) 0%, rgba(15,100,228,1) 100%)'
									:
									'#3683FF29'
							}}>{item.tooltip}
							</div>
							:
							''
					}
				</div>
			})
		}
		{
			activePoint !== undefined ?
				<div className={style.slider_track} style={{width: `${activePoint < points.length ?
						(100 / points.length) * (activePoint + 1)
						:
						'100'
				}%`}}/>
				:
				''
		}
	</div>
}
