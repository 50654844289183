import React, { useEffect } from "react";
import { Button, Form, Input, Modal, Radio } from "antd";
import { useUnit } from "effector-react";
import {
  $editModal,
  resetEditModalEv,
  sendEditVisitorEv,
  sendEditVisitorFx,
} from "../../models/blackListModel";
import style from "./BlackList.module.css";
import TextArea from "antd/es/input/TextArea";

function VisitorEditModal() {
  const editModal = useUnit($editModal);
  const resetEditModal = useUnit(resetEditModalEv);
  const [editForm] = Form.useForm();
  const submit = useUnit(sendEditVisitorEv);
  const loading = useUnit(sendEditVisitorFx.pending);

  useEffect(() => {
    editForm.setFieldsValue(editModal);
  }, [editModal]);

  return (
    <Modal
      open={!!editModal}
      onCancel={resetEditModal}
      footer={null}
      width={557}
      destroyOnClose
    >
      <div className={style.edit_modal_title}>Редактировать</div>
      <Form
        form={editForm}
        layout={"vertical"}
        style={{ marginTop: 24 }}
        onFinish={submit}
      >
        <Form.Item
          label={<label className={style.edit_form_label}>Имя</label>}
          name={"name"}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<label className={style.edit_form_label}>Статус</label>}
          name={"status"}
        >
          <Radio.Group>
            <Radio.Button value={"new"}>Посетитель</Radio.Button>
            <Radio.Button value={"vip"}>VIP список</Radio.Button>
            <Radio.Button value={"ban"}>Чёрный список</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={<label className={style.edit_form_label}>Комментарий</label>}
          name={"comment"}
        >
          <TextArea rows={4} placeholder={"Введите текст"} />
        </Form.Item>
      </Form>
      <div className={style.edit_form_buttons}>
        <Button
          className={style.edit_form_save}
          onClick={editForm.submit}
          loading={loading}
        >
          Сохранить
        </Button>
        <Button className={style.edit_form_cancel} onClick={resetEditModal}>
          Отмена
        </Button>
      </div>
    </Modal>
  );
}

export default VisitorEditModal;
