import {createEffect} from "effector";

export const getPortalUsersDataFx = createEffect().use(async () => {
	try{
		const response = await fetch(
			"https://79hs4sswlk.execute-api.eu-west-1.amazonaws.com/Development/CNAME",
			{
				method: "POST",
				body: JSON.stringify({
					"action": "list_users_by_tenants"
				})
			}
		)

		let result = await response.json()
		console.log("getPortalUsersDataFx:: Success", result);
		return JSON.parse(result.body)
	} catch (e){
		console.log(e)
	}
})

export const sendCreatePortalUserFx = createEffect().use(async (payload) => {
	try {
		const response = await fetch(
			"https://79hs4sswlk.execute-api.eu-west-1.amazonaws.com/Development/UID",
			{
				method: 'POST',
				body: JSON.stringify(payload)
			}
		)

		let result = await response.json()
		console.log("sendCreatePortalUserFx:: Success", result);
		return JSON.parse(result.body)
	} catch (e){
		console.log(e)
	}
})

export const sendEditPortalUserFx = createEffect().use(async (payload) => {
	try {
		const response = await fetch(
			"https://79hs4sswlk.execute-api.eu-west-1.amazonaws.com/Development/UID",
			{
				method: 'POST',
				body: JSON.stringify(payload)
			}
		)

		let result = await response.json()
		console.log("sendEditPortalUserFx:: Success", result);
		return JSON.parse(result.body)
	} catch (e){
		console.log(e)
	}
})

export const viewPortalUserFx = createEffect().use(async (payload) => {
	try {
		const response = await fetch(
			"https://79hs4sswlk.execute-api.eu-west-1.amazonaws.com/Development/UID",
			{
				method: 'POST',
				body: JSON.stringify(payload)
				// body: JSON.stringify({
				// 	action: 'read_uid',
				// 	uid: '00b9f7b1-9444-45e2-9379-f670e3b60ea9'
				// })
			}
		)

		let result = await response.json()
		console.log("viewPortalUserFx:: Success", result);
		return JSON.parse(result.body)
	} catch (e){
		console.log(e)
	}
})

export const archiveUserFx = createEffect().use(async (payload) => {
	try {
		const response = await fetch(
			"https://79hs4sswlk.execute-api.eu-west-1.amazonaws.com/Development/UID",
			{
				method: 'POST',
				body: JSON.stringify(payload)
			}
		)

		let result = await response.json()
		console.log("archiveUserFx:: Success", result);
		return JSON.parse(result.body)
	} catch (e){
		console.log(e)
	}
})

export const getTenantsListFx = createEffect().use(async () => {
	try {
		const response = await fetch(
			"https://79hs4sswlk.execute-api.eu-west-1.amazonaws.com/Development/CNAME",
			{
				method: "POST",
				body: JSON.stringify({
					"action": "list_tenant_names"
				})
			}
		)

		let result = await response.json()
		console.log("getTenantsListFx:: Success", result);
		return JSON.parse(result.body)
	} catch (e){
		console.error(e)
	}
})

export const getPlaceListFx = createEffect().use(async () => {
	try {
		const response = await fetch(
			"https://79hs4sswlk.execute-api.eu-west-1.amazonaws.com/Development/CNAME",
			{
				method: "POST",
				body: JSON.stringify({
					"action": "list_place_ids"
				})
			}
		)

		let result = await response.json()
		console.log("getPlaceListFx:: Success", result);
		return JSON.parse(result.body)
	} catch (e){
		console.error(e)
	}
})

export const sendCreateTenantItemFx = createEffect().use(async (payload) => {
	try{
		const response = await fetch(
			"https://79hs4sswlk.execute-api.eu-west-1.amazonaws.com/Development/Tenant-Onboarding",
			{
				method: "POST",
				body: JSON.stringify(payload)
			}
		)

		let result = await response.json()
		console.log("sendCreateTenantItemFx:: Success", result);
		return JSON.parse(result.body)
	} catch (e){
		console.error(e)
	}
})

export const sendEditTenantItemFx = createEffect().use(async (payload) => {
	try{
		const response = await fetch(
			"https://79hs4sswlk.execute-api.eu-west-1.amazonaws.com/Development/Tenant-Onboarding",
			{
				method: "POST",
				body: JSON.stringify(payload)
			}
		)

		let result = await response.json()
		console.log("sendEditTenantItemFx:: Success", result);
		return JSON.parse(result.body)
	} catch (e){
		console.error(e)
	}
})

export const sendViewTenantItemFx = createEffect().use(async (payload) => {
	try{
		const response = await fetch(
			"https://79hs4sswlk.execute-api.eu-west-1.amazonaws.com/Development/Tenant-Onboarding",
			{
				method: "POST",
				body: JSON.stringify(payload)
			}
		)

		let result = await response.json()
		console.log("sendViewTenantItemFx:: Success", result);
		return result.body
	} catch (e){
		console.error(e)
	}
})

export const sendDisableTenantItemFx = createEffect().use(async (payload) => {
	try{
		const response = await fetch(
			"https://79hs4sswlk.execute-api.eu-west-1.amazonaws.com/Development/Tenant-Onboarding",
			{
				method: "POST",
				body: JSON.stringify(payload)
			}
		)

		let result = await response.json()
		console.log("sendDisableTenantItemFx:: Success", result);
		return result.body
	} catch (e){
		console.error(e)
	}
})

export const sendActivateTenantItemFx = createEffect().use(async (payload) => {
	try{
		const response = await fetch(
			"https://79hs4sswlk.execute-api.eu-west-1.amazonaws.com/Development/Tenant-Onboarding",
			{
				method: "POST",
				body: JSON.stringify(payload)
			}
		)

		let result = await response.json()
		console.log("sendActivateTenantItemFx:: Success", result);
		return result.body
	} catch (e){
		console.error(e)
	}
})
