import { useEffect, useState } from 'react';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';

const InactivityHandler = () => {
  const [tokenExpiration, setTokenExpiration] = useState(null);

  const getTokenExpiration = async () => {
    console.log('im at InactivityHandler')
    try {
      const session = await fetchAuthSession();
      // debugger
      const idToken = session.tokens.idToken
      const expirationTime = idToken.payload.exp * 1000; // Время истечения в миллисекундах
      console.log('expirationTime: - ', expirationTime)
      setTokenExpiration(expirationTime);
    } catch (error) {
      console.error('Ошибка получения токена:', error);
    }
  };

  const checkTokenExpiration = () => {
    const currentTime = Date.now();
    // console.log('checkTokenExpiration:: ')
    if (tokenExpiration && currentTime >= tokenExpiration) {
      logout();
    }
  };

  const logout = async () => {
    try {
      await signOut();
      window.location.reload();
    } catch (error) {
      console.error('Ошибка при выходе:', error);
    }
  };

  useEffect(() => {
    getTokenExpiration();

    // Проверка каждые 60 секунд
    const intervalId = setInterval(checkTokenExpiration, 60 * 1000);

    return () => clearInterval(intervalId);
  }, [tokenExpiration]);

  return null;
};

export default InactivityHandler;