import {sample} from "effector";
import {getUnrecognizedTableDataFx} from "./effects";
import {$unrecognizedFilters, $unrecognizedTestingTableData} from "./stores";
import {changeUnrecognizedFiltersEv} from "./events";
import {formatUnrecognizedTableData} from "./utils";

sample({
	clock: getUnrecognizedTableDataFx.doneData,
	fn: formatUnrecognizedTableData,
	target: $unrecognizedTestingTableData
})

sample({
	source: $unrecognizedFilters,
	clock: changeUnrecognizedFiltersEv,
	fn: (source, clock) => {
		return {
			...source,
			[clock.field]: clock.value
		}
	},
	target: $unrecognizedFilters
})

sample({
	clock: $unrecognizedFilters.updates,
	fn: (clock) => {
		return {
			client_id: clock.client_id,
			date: clock.date.split('.').reverse().join('-')
		}
	},
	target: getUnrecognizedTableDataFx
})
