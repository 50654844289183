import { addUserToCurrentEv } from "./events";

const ws_url =
  "wss://c1cru56nwe.execute-api.eu-west-1.amazonaws.com/Development";

const onOpen = (websocket) => {
  return () => {
    console.log("WebSocket connection established");

    const request = {
      action: "get_last_item",
    };

    websocket.send(JSON.stringify(request));
    console.log("Sent request for last item..");
  };
};

const onMessage = (event) => {
  const response = event.data;
  console.log("Received message:", response);

  try {
    const data = JSON.parse(response);
    console.log("Parsed data:", data);
    addUserToCurrentEv();
  } catch (error) {
    console.error("Error parsing message:", error);
  }
};

const onError = (error) => {
  console.error("An error occurred:", error);
};

const onClose = (event) => {
  console.log(
    `WebSocket connection closed: Code=${event.code}, Reason=${event.reason}\nAttempting reconnection...`,
  );
  initSocket();
};

export const initSocket = () => {
  const websocket = new WebSocket(ws_url);
  window.ws = websocket;

  websocket.onopen = onOpen(websocket);
  websocket.onmessage = onMessage;
  websocket.onerror = onError;
  websocket.onclose = onClose;
};
