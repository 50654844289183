import {createEffect} from "effector";

export const getDashboardListFx = createEffect().use(async () => {
	try{
		const response = await fetch(
			"https://79hs4sswlk.execute-api.eu-west-1.amazonaws.com/Development/Tenant-Onboarding",
			{
				method: "POST",
				body: JSON.stringify({
					action: 'list_dashboards'
				})
			}
		)

		let result = await response.json()
		console.log("getDashboardListFx:: Success", result);
		return JSON.parse(result.body)
	} catch (e){
		console.error(e)
	}
})
