import React, {useEffect} from 'react';
import {Form, Modal} from "antd";
import PortalUserForm from "./PortalUserForm";
import {
	$editPortalUser,
	resetEditUserEv,
	sendEditPortalUserEv,
	sendEditPortalUserFx
} from "../../../../models/portalUsersModel";
import {useUnit} from "effector-react";

function EditPortalUser() {
	const editPortalUser = useUnit($editPortalUser)
	const [editForm] = Form.useForm()
	const resetEditPortalUser = useUnit(resetEditUserEv)
	const sendEditPortalUser = useUnit(sendEditPortalUserEv)
	const loading = useUnit(sendEditPortalUserFx.pending)

	const onFinish = (values) => {
		sendEditPortalUser({
			uid: editPortalUser.sort_key.replace('UID#', ''),
			values
		})
		// sendEditPortalUser(values)
	}

	const onCancel = () => {
		resetEditPortalUser()
		editForm.resetFields()
	}

	useEffect(() => {
		if(editPortalUser){
			editForm.setFieldsValue(editPortalUser)
		}
	}, [editPortalUser]);

	return (
		<Modal
			open={!!editPortalUser}
			footer={null}
			onCancel={onCancel}
			destroyOnClose
			title={'Редактировать пользователя'}
			width={557}
		>
			<PortalUserForm
				form={editForm}
				onFinish={onFinish}
				onCancel={onCancel}
				loading={loading}
				type={'edit'}
			/>
		</Modal>
	);
}

export default EditPortalUser;
