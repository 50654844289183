import React from 'react';
import {useUnit} from "effector-react";
import {$viewDashboard, resetViewDashboardEv} from "../../../models/dashboardModel";
import {Descriptions, Modal} from "antd";

function ViewDashboard(props) {
	const data = useUnit($viewDashboard)
	const resetViewDashboard = useUnit(resetViewDashboardEv)

	return (
		<Modal
			open={!!data}
			footer={null}
			onCancel={resetViewDashboard}
			destroyOnClose
			title={'Просмотр организацю'}
			width={557}
		>
			<Descriptions>
				{
					data && Object.keys(data).map(item => {
						return <Descriptions.Item label={item}>
							{data[item]}
						</Descriptions.Item>
					})
				}
			</Descriptions>
		</Modal>	);
}

export default ViewDashboard;
