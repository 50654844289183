import { createEffect } from "effector";

export const getBadImagesFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://wg8x98quyf.execute-api.eu-west-1.amazonaws.com/PortalAPI/bad-images-ddb",
      {
        method: "POST",
        body: JSON.stringify({
          place_id: payload?.place_id || "amaa25d7c9",
          date_gsi: payload?.date_gsi || "01-10-2024",
        }),
      },
    );

    let result = await response.json();
    return JSON.parse(result.body);
  } catch (e) {
    console.error(e);
  }
});

export const getMoreBadImagesFx = createEffect().use(async (payload) => {
  try {
    const response = await fetch(
      "https://wg8x98quyf.execute-api.eu-west-1.amazonaws.com/PortalAPI/bad-images-ddb",
      {
        method: "POST",
        body: JSON.stringify({
          place_id: payload?.place_id || "amaa25d7c9",
          date_gsi: payload?.date_gsi || "01-10-2024",
          lastKey: payload?.lastEvaluatedKey || null,
        }),
      },
    );

    let result = await response.json();
    console.log("getMoreBadImagesFx:: Success", result);
    return JSON.parse(result.body);
  } catch (e) {
    console.error(e);
  }
});
