import React from 'react';
import {Button, Col, Form, Input, Row, Select, Space} from "antd";
import {useUnit} from "effector-react";
import {$placesList} from "../../../../models/portalUsersModel";

function PortalUserForm(props) {
	const {form, onFinish, onCancel, loading, type} = props
	const place_id_options = useUnit($placesList)
	return (
		<Form
			layout={"vertical"}
			form={form}
			onFinish={onFinish}
		>
			<Form.Item
				name={'surname'}
				label={'Фамилия'}
			>
				<Input placeholder={'Введите фамилию'}/>
			</Form.Item>
			<Form.Item
				name={'name'}
				label={'Имя'}
			>
				<Input placeholder={'Введите имя'}/>
			</Form.Item>
			<Form.Item
				name={'relation_type'}
				label={'Тип связи'}
			>
				<Select placeholder={'Выбрать'}/>
			</Form.Item>
			<Form.Item
				name={'phone'}
				label={'Контактный номер'}
			>
				<Input placeholder={'+'}/>
			</Form.Item>
			<Form.Item
				name={'position'}
				label={'Позиция'}
			>
				<Input/>
			</Form.Item>
			<Row gutter={8}>
				<Col span={12}>
					<Form.Item
						name={'place_id'}
						label={'Доступный place_id'}
					>
						<Select options={place_id_options}/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name={'dashboard'}
						label={'Доступные дэшборды'}
					>
						<Input/>
					</Form.Item>
				</Col>
			</Row>
			<Form.Item
				name={'group'}
				label={'Группа'}
			>
				<Input/>
			</Form.Item>
			<Form.Item
				name={'role'}
				label={'Роль'}
			>
				<Input/>
			</Form.Item>
			<Space>
				{
					type === 'create' ?
						<>
							<Button style={{width: 198}} type={"primary"} onClick={form.submit} loading={loading}>Добавить</Button>
							<Button style={{width: 198}} onClick={onCancel}>Отменить</Button>
						</>
						:
						<>
							<Button style={{width: 159}} type={"primary"} onClick={form.submit} loading={loading}>Сохранить</Button>
							<Button style={{width: 159}} onClick={onCancel}>Отменить</Button>
							<Button style={{width: 159, padding: 4}} type={'dashed'}>Удалить пользователя</Button>
						</>
				}
			</Space>
		</Form>
	);
}

export default PortalUserForm;
