import { Heading, useTheme } from "@aws-amplify/ui-react";

export function SignInHeader() {
  const { tokens } = useTheme();

  return (
    <Heading
      style={{
        textAlign: 'center'
      }}
      level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}
    >
      Приветствуем
    </Heading>
  );
}
