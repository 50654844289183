export const formatTableData = (data) => {
	return Object.keys(data).map(user_id => {
		return {
			key: `testing-table-item-${user_id}`,
      user_id: user_id,
      data: {
        ...data[user_id].data,
        user_id: user_id
      },
      //fixme
			// ...data[user_id],
			// ...data[user_id].data[0],
			// ...data[user_id].additional_info,
			visits: data[user_id].visits
				// return {
        //   age_high: item.age_high,
        //   age_low: item.age_low,
        //   gender: item.gender,
				// 	emotion: item.emotion,
				// 	date: item.date,
				// 	in_out: item.in_out,
				// 	s3_bucket_name: item.s3_bucket_name,
				// 	s3_object_key: item.s3_object_key,
				// 	file_name: item.file_name,
				// }
			// })
		}
	})

	// return data.map((item, index) => {
	// 	return {
	// 		...item,
	// 		date: item.date.split(' ')[1],
	// 		emp_position: item.emp_position || "null",
	// 		emp_category: item.emp_category || "null",
	// 		user_nickname: item.user_nickname || "null",
	// 		user_name: item.user_name || "null",
	// 		emp_department: item.emp_department || "null",
	// 		user_gender: item.user_gender || "null",
	// 		user_surname: item.user_surname || "null",
	// 		key: `testing-table-item-${index}`
	// 	}
	// })
}

export const dateFormat = 'YYYY.MM.DD'

// function groupAndAddVisitsByUserId(arr) {
// 	const grouped = {};
//
// 	arr.forEach(obj => {
// 		const { date, in_out, s3_bucket_name, s3_object_key, file_name, ...userProps } = obj;
//
// 		if (!grouped[obj.user_id.split('#')[0]]) {
// 			grouped[obj.user_id.split('#')[0]] = { ...userProps, visits: [] };
// 		}
//
// 		grouped[obj.user_id.split('#')[0]].visits.push({ date, in_out, s3_bucket_name, s3_object_key, file_name });
// 	});
// 	return Object.values(grouped).map(user => {
// 		user.visits.sort((a, b) => {
// 			const timeA = a.date.split(':').map(Number);
// 			const timeB = b.date.split(':').map(Number);
//
// 			// Compare hours
// 			if (timeA[0] !== timeB[0]) {
// 				return timeA[0] - timeB[0];
// 			}
// 			// Compare minutes
// 			if (timeA[1] !== timeB[1]) {
// 				return timeA[1] - timeB[1];
// 			}
// 			// Compare seconds
// 			return timeA[2] - timeB[2];
// 		});
//
// 		return {
// 			...user,
// 			visits: user.visits.map(visit => {
// 				return {
// 					date: visit.date,
// 					in_out: visit.in_out,
// 					s3_bucket_name: visit.s3_bucket_name,
// 					s3_object_key: visit.s3_object_key,
// 					file_name: visit.file_name
// 				};
// 			})
// 		};
// 	});
// }
