import creatti_logo from '../../images/creatti_logo.png'
import confetti_box from '../../images/confetti_box.png'
import style from './Registration.module.css'

export default function SuccessPage(){
	return <>
		<img src={creatti_logo} className={style.success_page_logo}/>
		<img src={confetti_box} className={style.success_box}/>
		<div className={style.success_title}>Поздравляем!</div>
		<div className={style.success_subtitle}>Мы рады видеть ваc <br/> в кругу наших почётных гостей</div>
		{/*<div className={style.success_more}>+Еще описание возможностей</div>*/}
	</>
}
