import React from 'react';
import {Descriptions, Modal} from "antd";
import {useUnit} from "effector-react";
import {$viewOrg, resetViewOrgEv} from "../../../../models/portalUsersModel";

function ViewOrgModal() {
	const data = useUnit($viewOrg)
	const resetViewOrg = useUnit(resetViewOrgEv)

	return (
		<Modal
			open={!!data}
			footer={null}
			onCancel={resetViewOrg}
			destroyOnClose
			title={'Просмотр организацю'}
			width={557}
		>
			<Descriptions>
				{
					data && Object.keys(data).map(item => {
						return <Descriptions.Item label={item}>
							{data[item]}
						</Descriptions.Item>
					})
				}
			</Descriptions>
		</Modal>
	);
}

export default ViewOrgModal;
