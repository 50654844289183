import { createStore } from "effector";
import { initSocket } from "./websocket";

const initFilters = {
  gender: ["male", "female"],
  status: ["visitor", "vip", "blacklist"],
  period: null,
};

export const $blackListData = createStore([]);
$blackListData.watch((state) => console.log("$blackListData", state));

export const $currentList = createStore([]);
$currentList.watch((state) => console.log("$currentBlackList", state));

export const $blackListFilters = createStore(initFilters);
export const $showFilters = createStore(false);

export const $historyModal = createStore(null);
export const $editModal = createStore(null);

export const $tab = createStore(1);

initSocket();
