import {createEffect} from "effector";

export const getTestingClientTableDataFx = createEffect().use( async (payload) => {

	const apiPayload = {
		client_id: "klba36t1h2",
		...payload
	}

	try{
		const response = await fetch(
			'https://wg8x98quyf.execute-api.eu-west-1.amazonaws.com/PortalAPI/client',
			{
				method: 'POST',
				body: JSON.stringify(apiPayload)
			}
		)

		const result = await response.json()
		console.log('getTestingClientTableDataFx:: Success', result)
		return JSON.parse(result.data)
	} catch (e){
		console.error(e)
	}
})
