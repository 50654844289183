import style from "./TestingClient.module.css";
import { Table } from "antd";
import { useUnit } from "effector-react";
import {
  $testingClientFilters,
  $testingClientTableData,
  changeTestingClientFiltersEv,
  getTestingClientTableDataFx,
} from "../../models/testingClientModel";
import { useNavigate } from "react-router-dom";
import { $userGroups } from "../../models/userModel";
import { useEffect } from "react";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import dayjs from "dayjs";

const columns = [
  {
    title: "User id",
    dataIndex: "user_id",
    key: "user_id",
    sorter: (a, b) => a.user_id.localeCompare(b.user_id),
  },
  {
    title: "User name",
    dataIndex: "user_name",
    key: "user_name",
    sorter: (a, b) => a.user_name.localeCompare(b.user_name),
  },
  {
    title: "User surname",
    dataIndex: "user_surname",
    key: "user_surname",
    sorter: (a, b) => a.user_surname.localeCompare(b.user_surname),
  },
  {
    title: "Gender",
    dataIndex: "gender",
    key: "gender",
    sorter: (a, b) => a.gender.localeCompare(b.gender),
  },
  {
    title: "User gender",
    dataIndex: "user_gender",
    key: "user_gender",
    sorter: (a, b) => a.user_gender.localeCompare(b.user_gender),
  },
  {
    title: "Age high",
    dataIndex: "age_high",
    key: "age_high",
    sorter: (a, b) => a.age_high - b.age_high,
  },
  {
    title: "Age low",
    dataIndex: "age_low",
    key: "age_low",
    sorter: (a, b) => a.age_low - b.age_low,
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    sorter: (a, b) => a.date.localeCompare(b.date),
  },
  {
    title: "In out",
    dataIndex: "in_out",
    key: "in_out",
    sorter: (a, b) => a.in_out.localeCompare(b.in_out),
  },
  {
    title: "Emotion",
    dataIndex: "emotion",
    key: "emotion",
    sorter: (a, b) => a.emotion.localeCompare(b.emotion),
  },
  {
    title: "Service",
    dataIndex: "service",
    key: "service",
    sorter: (a, b) => a.service.localeCompare(b.service),
  },
];
const cliendIdOptions = [
  {
    value: "klba36t1h2",
    label: "Kolibrix Спартака, 26",
  },
  {
    value: "klba36x2c2",
    label: "Kolibrix Жунисова, 2/13 к1",
  },
  {
    value: "amaa25d7c9",
    label: "Amanat Достык, 232/1",
  },
  {
    value: "crtz05x7d2",
    label: "Creatti, ул.Мангилик ел, д. 59",
  },
];
const ageGroupOptions = [
  {
    value: "0-3",
    label: "0-3",
  },
  {
    value: "3-12",
    label: "3-12",
  },
  {
    value: "12-16",
    label: "12-16",
  },
  {
    value: "16-21",
    label: "16-21",
  },
  {
    value: "21-30",
    label: "21-30",
  },
  {
    value: "30-45",
    label: "30-45",
  },
  {
    value: "45-55",
    label: "45-55",
  },
  {
    value: "55-64",
    label: "55-64",
  },
  {
    value: "64-120",
    label: "64-120",
  },
];
const genderOptions = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
];
const retentionOptions = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 15,
    label: "15",
  },
];

export default function TestingClient() {
  const loading = useUnit(getTestingClientTableDataFx.pending);
  const testingClientTableData = useUnit($testingClientTableData);
  const changeTestingClientFilters = useUnit(changeTestingClientFiltersEv);
  const testingClientFilters = useUnit($testingClientFilters);
  const navigate = useNavigate();
  const userGroups = useUnit($userGroups);

  useEffect(() => {
    if (testingClientTableData.length === 0) {
      getTestingClientTableDataFx();
    }
  }, [testingClientTableData]);

  useEffect(() => {
    if (Array.isArray(userGroups) && !userGroups.includes("portal_admin")) {
      navigate("/dashboard");
    }
  }, [userGroups, navigate]);

  if (!userGroups || userGroups?.length === 0) {
    return null;
  }

  return (
    <div>
      <div className={style.filtration_wrapper}>
        <div className={style.filtration_title}>Filtration</div>
        <div className={style.filtration_filters}>
          <CustomSelect
            options={cliendIdOptions}
            onSelect={changeTestingClientFilters}
            field={"client_id"}
            placeholder="Client"
            value={testingClientFilters.client_id}
          />
          <CustomSelect
            selectType={"dateRange"}
            onSelect={changeTestingClientFilters}
            field={"dates"}
            value={testingClientFilters.dates.map((item) =>
              dayjs(new Date(item)),
            )}
            allowClear={false}
          />
          <CustomSelect
            options={ageGroupOptions}
            onSelect={changeTestingClientFilters}
            field={"age_groups"}
            placeholder="Age group"
            value={testingClientFilters.age_groups}
            mode={"multiple"}
            allowClear
          />
          <CustomSelect
            options={genderOptions}
            onSelect={changeTestingClientFilters}
            field={"gender"}
            placeholder="Gender"
            value={testingClientFilters.gender}
            mode={"multiple"}
            allowClear
          />
          <CustomSelect
            options={retentionOptions}
            onSelect={changeTestingClientFilters}
            field={"retention"}
            placeholder="Retention"
            value={testingClientFilters.retention}
            mode={"multiple"}
            allowClear
          />
        </div>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={testingClientTableData}
        scroll={{
          x: "max-content",
        }}
        // pagination={{
        // 	pageSize: 100,
        // 	pageSizeOptions: [100, 200, 300, 400, 500]
        // }}
      />
    </div>
  );
}
