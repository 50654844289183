export const edu_field_names = {
  user_gender: "Пол",
  user_email: "Почта",
  user_birth_day: "День рождения",
  user_surname: "Фамилия",
  user_birth_year: "Год рождения",
  user_address: "Адрес",
  emp_date: "Дата приема на работу",
  work_type: "График работы",
  dis_date: "Дата увольнения",
  emp_position: "Должность",
  emp_category: "Статус пользователя",
  user_name: "Имя",
  user_birth_month: "Месяц рождения",
  start_work_time: "Время начала рабочего дня",
  end_work_time: "Время окончания рабочего дня",
  subject: "Предметы(Кафедры)",
  emp_department: "Учебная часть",
  user_mobile: "Телефонный номер",
  grade: "Класс",
  shift: "Смена",
  contacts: "Контакты",
  created_at: "Создан",
  alarm: "alarm",
};

export const defaultStartWorkTime = {
  Понедельник: "09:00",
  Вторник: "09:00",
  Среда: "09:00",
  Четверг: "09:00",
  Пятница: "09:00",
  Суббота: "09:00",
  Воскресенье: "09:00",
};

export const defaultEndWorkTime = {
  Понедельник: "17:00",
  Вторник: "17:00",
  Среда: "17:00",
  Четверг: "17:00",
  Пятница: "17:00",
  Суббота: "17:00",
  Воскресенье: "17:00",
};
