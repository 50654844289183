import React, {useEffect} from 'react';
import {Form, Modal} from "antd";
import PortalOrgForm from "./PortalOrgForm";
import {useUnit} from "effector-react";
import {
	$editOrg,
	resetEditOrgEv,
	sendEditTenantItemEv,
	sendEditTenantItemFx
} from "../../../../models/portalUsersModel";

function EditOrgModal() {
	const editOrg = useUnit($editOrg)
	const [editForm] = Form.useForm()
	const resetEditOrg = useUnit(resetEditOrgEv)
	const sendEditOrg = useUnit(sendEditTenantItemEv)
	const loading = useUnit(sendEditTenantItemFx.pending)

	const onFinish = (values) => {
		sendEditOrg(values)
	}

	const onCancel = () => {
		resetEditOrg()
		editForm.resetFields()
	}

	useEffect(() => {
		editForm.setFieldsValue(editOrg)
	}, [editOrg]);
	return (
		<Modal
			open={!!editOrg}
			footer={null}
			onCancel={onCancel}
			destroyOnClose
			title={'Редактировать организацию'}
			width={557}
		>
			<PortalOrgForm
				form={editForm}
				onFinish={onFinish}
				onCancel={onCancel}
				loading={loading}
				type={'edit'}
			/>
		</Modal>
	);
}

export default EditOrgModal;
