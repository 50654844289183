import {Flex, Link, Text, useTheme} from "@aws-amplify/ui-react";
import footer_badge from '../../images/footer_badge 1.png'
import nvidia_logo from '../../images/nvidia_logo.png'

export function Footer() {
  const { tokens } = useTheme();

  return (
    <div padding={tokens.space.medium}>
      <Text style={{textAlign: 'center', marginTop: '15px'}}><Link >Правила и соглашения</Link></Text>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '125px',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <div style={{
          display: "flex",
          columnGap: 20
        }}>
          <img
            style={{
              maxWidth: '100px',
              marginBottom: '125px'
            }}
            src={footer_badge}/>
          <img
            style={{
              marginBottom: '125px'
            }}
            src={nvidia_logo}/>
        </div>
        <div>@FOQUS 2024</div>
      </div>
    </div>
  );
}
