import {sample} from "effector";
import {getTestingClientTableDataFx} from "./effects";
import {$testingClientFilters, $testingClientStartTableData, $testingClientTableData} from "./stores";
import {changeTestingClientFiltersEv, debounced, filterAfterApiCallEv} from "./events";
import {formatClientTableData} from "./utils";

sample({
	clock: getTestingClientTableDataFx.doneData,
	fn: formatClientTableData,
	target: [$testingClientTableData, $testingClientStartTableData]
})

sample({
	source: $testingClientFilters,
	clock: changeTestingClientFiltersEv,
	fn: (source, clock) => {
		if(source[clock.field] !== clock.value){
			return {
				...source,
				[clock.field]: clock.value
			}
		}
	},
	target: $testingClientFilters
})

sample({
	clock: $testingClientFilters.updates,
	fn: (filters) => {
		const payload = {
			...filters,
			dateFrom: filters.dates[0].split('.').reverse().join('-'),
			dateTo: filters.dates[1].split('.').reverse().join('-'),
			age_groups: filters.age_groups.length > 0 ? filters.age_groups.map(item => item.split('-').map(num => parseInt(num))) : []
		}
		delete payload.dates
		if(payload.age_groups.length === 0) delete payload.age_groups
		if(payload.gender.length === 0) delete payload.gender
		if(payload.retention.length === 0) delete payload.retention
		return payload
	},
	target: getTestingClientTableDataFx
})
