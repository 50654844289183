import React from "react";
import { Divider, Modal } from "antd";
import { useUnit } from "effector-react";
import {
  $historyModal,
  resetHistoryModalEv,
} from "../../models/blackListModel";
import style from "./BlackList.module.css";

function VisitorHistoryModal() {
  const historyModal = useUnit($historyModal);
  const resetHistoryModal = useUnit(resetHistoryModalEv);

  return (
    <Modal
      open={!!historyModal}
      onCancel={resetHistoryModal}
      footer={null}
      width={557}
      destroyOnClose
    >
      <div className={style.history_modal_title}>История посещений</div>
      <div style={{ marginTop: 24 }}>
        <div className={style.history_visit_title}>Посещение</div>
        {historyModal &&
          historyModal.total_visits.map((visit, index) => (
            <Visit key={`visit-${index}`} date={visit} />
          ))}
      </div>
      <Divider />
      <div>
        {historyModal &&
          historyModal.comments.map((comment, index) => (
            <div key={`comment-${index}`}>
              <div className={style.history_comment_title}>Комментарий</div>
              <div className={style.history_comment_text}>
                {comment.comment}
              </div>
              <div className={style.history_comment_info}>
                <div>
                  <div className={style.history_visit_label}>Дата:</div>
                  <div className={style.history_visit_date}>{comment.date}</div>
                </div>
                <div>
                  <div className={style.history_visit_label}>Пользователь:</div>
                  <div className={style.history_visit_date}>
                    {comment.comment_by}
                  </div>
                </div>
              </div>
              <Divider />
            </div>
          ))}
      </div>
    </Modal>
  );
}

export default VisitorHistoryModal;

function Visit(props) {
  const { date } = props;

  return (
    <div className={style.history_visit_wrapper}>
      <div className={style.history_visit_label}>Дата:</div>
      <div className={style.history_visit_date}>{date}</div>
    </div>
  );
}
