import style from './Registration.module.css'
import CustomSlider from "./CustomSlider";

export default function InfoBlock(props) {

	const { items, activePoint } = props

	return <div className={style.info_block}>
		<div className={style.info_title}>2 простых шага для получения карты почётного гостя Creatti</div>
		<ol style={{
			paddingLeft: '14px',
			margin: '14px 0px 34px 0px'
		}}>
			{
				items.map(item => <li key={`info-point-${item}`} className={style.info_point}>{item}</li>)
			}
		</ol>
		<CustomSlider
			points={[
				{
					tooltip: '10%',
					id: 0
				},
				{
					tooltip: '20%',
					id: 1
				}
			]}
			activePoint={activePoint}
		/>
	</div>
}
