import style from "./BlackList.module.css";
import { BanSvg, NewSvg, VipSvg } from "./BlackListIcons";
import React from "react";

export const getStatusIcon = (status, customClass) => {
  const statusIcons = {
    ban: (
      <div
        className={[style.visitor_status, customClass].join(" ")}
        style={{
          backgroundColor: status === "ban" ? "#FF4D4F" : "#EAEAEA",
          color: status === "ban" ? "white" : "#C0C0C0",
        }}
      >
        <BanSvg />
      </div>
    ),
    vip: (
      <div
        className={[style.visitor_status, customClass].join(" ")}
        style={{
          backgroundColor: status === "vip" ? "#F2AE00" : "#EAEAEA",
          color: status === "vip" ? "#111111" : "#C0C0C0",
        }}
      >
        <VipSvg />
      </div>
    ),
    visitor: (
      <div
        className={[style.visitor_status, customClass].join(" ")}
        style={{
          backgroundColor: status === "visitor" ? "#4FD1D9" : "#EAEAEA",
          color: status === "visitor" ? "white" : "#C0C0C0",
        }}
      >
        <NewSvg />
      </div>
    ),
  };
  return statusIcons[status];
};
