import React, {useEffect} from 'react';
import ReactPlayer from 'react-player/lazy'
import medicine from '../../images/medicine.jpg'
import {useUnit} from "effector-react";
import {$userGroups} from "../../models/userModel";
import {useNavigate} from "react-router-dom";

function DemoVideo() {

  const userGroups = useUnit($userGroups)
  const navigate = useNavigate()

  useEffect(() => {
    if( Array.isArray(userGroups)){
      if(!userGroups.includes('portal_admin') && !userGroups.includes('foqus_portal_demo')){
        navigate('/dashboard')
      }
    }
  }, [userGroups, navigate]);

  if(!userGroups || userGroups?.length === 0){
    return null
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }}
    >
      <div className="player-wrapper">
        <h1
          style={{
            marginBottom: '20px',
            fontSize: '18px'
          }}
        >HR Demo</h1>
        <ReactPlayer
          className="react-player"
          url='https://youtu.be/qzDwRxpEOgQ'
          width="auto"
          height="40vh"
          style={{
            marginBottom: '20px'
          }}
        />
      </div>
      <div className="player-wrapper">
        <h1
          style={{
            marginBottom: '20px',
            fontSize: '18px'
          }}
        >Grocery Retail Demo</h1>
        <ReactPlayer
          className="react-player"
          url='https://youtu.be/k52e1eiG48Y'
          width="auto"
          height="40vh"
          style={{
            marginBottom: '20px'
          }}
        />
      </div>
      <div className="player-wrapper">
        <h1
          style={{
            marginBottom: '20px',
            fontSize: '18px'
          }}
        >Cashier Zone Demo</h1>
        <ReactPlayer
          className="react-player"
          url='https://youtu.be/CiFS7gMmewA'
          width="auto"
          height="40vh"
          style={{
            marginBottom: '20px'
          }}
        />
      </div>
      <div className="player-wrapper">
        <h1
          style={{
            marginBottom: '20px',
            fontSize: '18px'
          }}
        >Fashion Retail Demo</h1>
        <ReactPlayer
          className="react-player"
          url='https://youtu.be/s89qyTq2v3c'
          width="auto"
          height="40vh"
          style={{
            marginBottom: '20px'
          }}
        />
      </div>
      <div className="player-wrapper">
        <h1
          style={{
            marginBottom: '20px',
            fontSize: '18px'
          }}
        >Gas Station Demo</h1>
        <ReactPlayer
          className="react-player"
          url='https://youtu.be/g3J6xE9qWE0'
          width="auto"
          height="40vh"
          style={{
            marginBottom: '20px',
          }}
        />
      </div>
      <div className="player-wrapper">
        <h1
          style={{
            marginBottom: '20px',
            fontSize: '18px'
          }}
        >Medical Clinic: Telegram Alert</h1>
        <img
          src={medicine}
          style={{
            height: '40vh'
          }}
        />
      </div>
    </div>
  );
}

export default DemoVideo;
