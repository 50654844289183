import React from 'react';
import {Button, Form, Input, Space} from "antd";

function PortalOrgForm(props) {
	const {form, onFinish, onCancel, loading, type} = props
	return (
		<Form
			layout={"vertical"}
			form={form}
			onFinish={onFinish}
		>
			<Form.Item
				name='partition_key'
				label='partition_key'
			>
				<Input placeholder='Enter partition_key'/>
			</Form.Item>
			<Form.Item
				name='sort_key'
				label='sort_key'
			>
				<Input placeholder='Enter sort_key'/>
			</Form.Item>
			<Form.Item
				name='admin_client'
				label='admin_client'
			>
				<Input placeholder='Enter admin_client'/>
			</Form.Item>
			<Form.Item
				name='billing_info'
				label='billing_info'
			>
				<Input placeholder='Enter billing_info'/>
			</Form.Item>
			<Form.Item
				name='city'
				label='city'
			>
				<Input placeholder='Enter city'/>
			</Form.Item>
			<Form.Item
				name='company_name'
				label='company_name'
			>
				<Input placeholder='Enter company_name'/>
			</Form.Item>
			<Form.Item
				name='contract_start'
				label='contract_start'
			>
				<Input placeholder='Enter contract_start'/>
			</Form.Item>
			<Form.Item
				name='industry'
				label='industry'
			>
				<Input placeholder='Enter industry'/>
			</Form.Item>
			<Form.Item
				name='segment'
				label='segment'
			>
				<Input placeholder='Enter segment'/>
			</Form.Item>
			<Form.Item
				name='taxes'
				label='taxes'
			>
				<Input placeholder='Enter taxes'/>
			</Form.Item>
			<Form.Item
				name='website'
				label='website'
			>
				<Input placeholder='Enter website'/>
			</Form.Item>
			<Form.Item
				name='interests'
				label='interests'
			>
				<Input placeholder='Enter interests'/>
			</Form.Item>
			<Form.Item
				name='age_target'
				label='age_target'
			>
				<Input placeholder='Enter age_target'/>
			</Form.Item>
			<Form.Item
				name='gender_target'
				label='gender_target'
			>
				<Input placeholder='Enter gender_target'/>
			</Form.Item>
			<Form.Item
				name='additional_info'
				label='additional_info'
			>
				<Input placeholder='Enter additional_info'/>
			</Form.Item>
			<Form.Item
				name='telegram_chat'
				label='telegram_chat'
			>
				<Input placeholder='Enter telegram_chat'/>
			</Form.Item>
			<Form.Item
				name='telegram_token'
				label='telegram_token'
			>
				<Input placeholder='Enter telegram_token'/>
			</Form.Item>
			<Space>
				{
					type === 'create' ?
						<>
							<Button style={{width: 198}} type={"primary"} onClick={form.submit} loading={loading}>Добавить</Button>
							<Button style={{width: 198}} onClick={onCancel}>Отменить</Button>
						</>
						:
						<>
							<Button style={{width: 159}} type={"primary"} onClick={form.submit} loading={loading}>Сохранить</Button>
							<Button style={{width: 159}} onClick={onCancel}>Отменить</Button>
							<Button style={{width: 159, padding: 4}} type={'dashed'}>Удалить пользователя</Button>
						</>
				}
			</Space>
		</Form>
	);
}

export default PortalOrgForm;
