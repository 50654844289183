export const formatClientTableData = (data) => {
	return data.map((item, index) => {
		return {
			...item,
			key: `testing-client-table-item-${index}`,
			emp_position: item.emp_position || "null",
			emp_category: item.emp_category || "null",
			user_nickname: item.user_nickname || "null",
			user_name: item.user_name || "null",
			emp_department: item.emp_department || "null",
			user_gender: item.user_gender || "null",
			user_surname: item.user_surname || "null",
		}
	})
}
