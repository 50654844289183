import style from "./UserList.module.css";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { useEffect, useMemo, useState } from "react";
import {
  $userList,
  $userListPlaceId,
  changeChosenUserEv,
  changeShowCreateModalEv,
  changeUserListPlaceIdEv,
  fireEmployeeEv,
  getUserListFx,
} from "../../models/userListModel";
import { useUnit } from "effector-react";
import { Button, Popconfirm, Segmented, Table } from "antd";
import {
  EditOutlined,
  CloseCircleOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import UserListModal from "./UserListModal";
import { $userGroups } from "../../models/userModel";
import { useNavigate } from "react-router-dom";
import PotentialEmp from "./PotentialEmp";
import CreateUserModal from "./CreateUserModal";
import MakeEmpModal from "./MakeEmpModal";

const cliendIdOptions = [
  {
    value: "klba36t1h2",
    label: "Kolibrix Спартака, 26",
  },
  {
    value: "klba36x2c2",
    label: "Kolibrix Жунисова, 2/13 к1",
  },
  {
    value: "amaa25d7c9",
    label: "Amanat Достык, 232/1",
  },
  {
    value: "crtz05x7d2",
    label: "Creatti, ул.Мангилик ел, д. 59",
  },
];

const columns = [
  {
    title: "Actions",
    key: "user-list-actions",
    render: (text, record) => (
      <div className={style.table_actions_wrapper}>
        <Button
          icon={<EditOutlined />}
          onClick={() => changeChosenUserEv(record)}
        />
        {record.emp_category === "Сотрудник" ? (
          <Popconfirm
            title={"Сотрудник уволен?"}
            okText={"Да"}
            cancelText={"Нет"}
            onConfirm={() => fireEmployeeEv(record)}
          >
            <Button icon={<CloseCircleOutlined />} />
          </Popconfirm>
        ) : (
          ""
        )}
      </div>
    ),
  },
  {
    title: "Partition key",
    key: "user-list-partition_key",
    dataIndex: "partition_key",
  },
  {
    title: "Days Number",
    key: "user-list-days_number",
    dataIndex: "days_number",
  },
  {
    title: "Sort key",
    key: "user-list-sort_key",
    dataIndex: "sort_key",
  },
  {
    title: "Admin place",
    key: "user-list-admin_place",
    dataIndex: "admin_place",
  },
  {
    title: "Alarm",
    key: "user-list-alarm",
    dataIndex: "alarm",
  },
  {
    title: "Category",
    key: "user-list-category",
    dataIndex: "category",
  },
  {
    title: "City",
    key: "user-list-city",
    dataIndex: "city",
  },
  {
    title: "Emp. category",
    key: "user-list-emp_category",
    dataIndex: "emp_category",
  },
  {
    title: "Emp. department",
    key: "user-list-emp_department",
    dataIndex: "emp_department",
  },
  {
    title: "Emp. position",
    key: "user-list-emp_position",
    dataIndex: "emp_position",
  },
  {
    title: "Place address",
    key: "user-list-place_address",
    dataIndex: "place_address",
  },
  {
    title: "place_id",
    key: "user-list-place_id",
    dataIndex: "place_id",
  },
  {
    title: "Service",
    key: "user-list-service",
    dataIndex: "service",
  },
  {
    title: "User gender",
    key: "user-list-user_gender",
    dataIndex: "user_gender",
  },
  {
    title: "User name",
    key: "user-list-user_name",
    dataIndex: "user_name",
  },
  {
    title: "User nickname",
    key: "user-list-user_nickname",
    dataIndex: "user_nickname",
  },
  {
    title: "User surname",
    key: "user-list-user_surname",
    dataIndex: "user_surname",
  },
  {
    title: "Work hours",
    key: "user-list-work_hours",
    dataIndex: "work_hours",
  },
  {
    title: "Work zones",
    key: "user-list-work_zones",
    dataIndex: "work_zones",
  },
];

export default function UserList() {
  const userListPlaceId = useUnit($userListPlaceId);
  const changeUserListPlaceId = useUnit(changeUserListPlaceIdEv);
  const userList = useUnit($userList);
  const loading = useUnit(getUserListFx.pending);
  const userGroups = useUnit($userGroups);
  const navigate = useNavigate();
  const changeShowCreateModal = useUnit(changeShowCreateModalEv);

  const [currentTab, setCurrentTab] = useState("0");

  const segmentedOptions = useMemo(
    () => [
      {
        label: "Все",
        value: "0",
        disabled: !userGroups?.includes("portal_admin"),
      },
      {
        label: "Потенциальные сотрудники",
        value: "1",
      },
    ],
    [userGroups],
  );

  useEffect(() => {
    if (Array.isArray(userGroups) && !userGroups.includes("portal_admin")) {
      navigate("/dashboard");
    }
  }, [userGroups, navigate]);

  useEffect(() => {
    getUserListFx();
  }, []);

  return (
    <div className={style.userList_wrapper}>
      <div className={style.userList_title}>User list</div>
      <div className={style.user_list_top_wrapper}>
        <div>
          <CustomSelect
            options={cliendIdOptions}
            onSelect={changeUserListPlaceId}
            field={"client_id"}
            placeholder="Place"
            value={userListPlaceId}
          />
          <Segmented
            options={segmentedOptions}
            value={currentTab}
            onChange={setCurrentTab}
          />
        </div>
        {currentTab === "0" ? (
          <Button icon={<UserAddOutlined />} onClick={changeShowCreateModal} />
        ) : (
          ""
        )}
      </div>
      {currentTab === "0" ? (
        <Table
          loading={loading}
          className={style.userList_table}
          dataSource={userList}
          columns={columns}
          scroll={{
            x: "max-content",
          }}
        />
      ) : (
        <PotentialEmp />
      )}
      <UserListModal />
      <CreateUserModal />
      <MakeEmpModal />
    </div>
  );
}
