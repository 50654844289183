import '../../App.css';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import {withAuthenticator, Button, Heading, Link} from '@aws-amplify/ui-react';
import { fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import AWS from 'aws-sdk'
import {PieChartOutlined} from '@ant-design/icons';


function DashboardHR({ signOut, user }) {
  const dashboardRef = useRef([]);
  const [dashboardId, setDashboardId] = useState('');
  const [embeddedDashboard, setEmbeddedDashboard] = useState(null);
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const [embeddingContext, setEmbeddingContext] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [userName, setUserName] = useState('');
  const [userGroup, setUserGroup] = useState([]);
  const [dashboardData, setDashboardData] = useState(null);

  // useEffect(() => {
  //   const cloudWatch = new AWS.CloudWatch({
  //     region: 'eu-west-1',
  //     accessKeyId: 'AKIAV7QO7JUKGJF3OBFM',
  //     secretAccessKey: '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',
  //   });
  //
  //   const params = {
  //     DashboardName: 'Backend',
  //   };
  //
  //   // cloudWatch.getDashboard(params, (err, data) => {
  //   //   if (err) {
  //   //     console.log('Error fetching CloudWatch dashboard:', err);
  //   //   } else {
  //   //     console.log('getDashboard data: - ', data)
  //   //     setDashboardData(data.DashboardBody);
  //   //   }
  //   // });
  // }, []);

  useEffect(() => {
    const fetchUserGroup = async () => {
      // debugger
      // console.log('fetchUserGroup');
      try {
        const user = await fetchAuthSession();
        // debugger
        const group = user.tokens.accessToken.payload['cognito:groups'];
        setUserGroup(group || []);
        console.log('group: - ', group[0])
      } catch (error) {
        console.error('Error fetching user group:', error);
      }
    };

    fetchUserGroup();
  }, []);

  async function handleFetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      // setDashboardId(userAttributes['custom:DashboardId']);
      // await changeDashboard(userAttributes['custom:DashboardId']);
      console.log(userAttributes);
      setUserName(userAttributes['name']);
      return userAttributes
    } catch (error) {
      console.log(error);
    }
  }



  useEffect(() => {
    const timeout = setTimeout(() => {

      fetch("https://9iz15iyde3.execute-api.eu-west-1.amazonaws.com/embed/anonymous-embed-v2"
      ).then((response) => response.json()
      ).then((response) => {
        setDashboardUrl(response.EmbedUrl)
      })
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const createContext = async () => {
    const context = await createEmbeddingContext();
    setEmbeddingContext(context);
  }

  useEffect(() => {
    if (dashboardUrl) { createContext() }
  }, [dashboardUrl])

  useEffect(() => {
    if (embeddingContext) { embed(); }
  }, [embeddingContext])

  const embed = async () => {

    let options = {
      url: dashboardUrl,
      container: dashboardRef.current,
      height: "500px",
      width: "600px",
    };
    const userInfo = await handleFetchUserAttributes()

    // let newUrl = "https://eu-west-1.quicksight.aws.amazon.com/embed/3bfdf79a968847789735d5d10d5d6570/dashboards/478ed133-4942-4841-8e41-ac3402ef4494?code=AYABeByuSyA7V3oLYsR07pCcR0QAAAABAAdhd3Mta21zAEthcm46YXdzOmttczpldS13ZXN0LTE6MzU2MzA1MTgzMDczOmtleS83ZTYwMTNkMC0xM2I0LTRmODItYmI1NC00YjllMTM4NzczNjYAuAECAQB4IZt0zcr71c1qotPywu6knKQF9RerzWvIw9ebvDgZ4T0BwKSEFgh8_DYRiYK-bHFFPAAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDF9Xuh8dZI6X-MUYRQIBEIA7r9rXlXf8-PkmWUC7Na4wswX5xzH9d7KOHaguUOb3NWCBAWC-RybQxAY4kCtjGogkQZSaRqX2fYkCTlkCAAAAAAwAABAAAAAAAAAAAAAAAAAAhDj3G762sZEVALtwcrFvhv____8AAAABAAAAAAAAAAAAAAABAAAAm20jOJ2M6doZXOS8m6HYcjlmwpiJeYdAE-ptzH6nFappA06SWjoWsBs1dEi5zRGEOpWDSM9oZm06KoX1f3QdrIphH86MrGCMybIAsu148eO8jqcml_P_YRDvDqOejL7OX2yO61WqpJHEZSg-eC-Z5xPyLnH4MdAcckrxFgyi4JY6GEQLJyIHzyniX6WFZCrAjv86CeRcEwkczsDVtiqg_-nwT0paBIj707PCoQ%3D%3D&identityprovider=quicksight&isauthcode=true";
    console.log('userInfo: - ', userInfo)
    let replacedUrl = options.url.replace(/\/([^\/?]+)\?/, "/" + '36317c0a-107b-493a-9c43-742ac35ebfa3' + "?");
    options.url = replacedUrl
    console.log('options: - ', options)
    const newEmbeddedDashboard = await embeddingContext.embedDashboard(options);

    setEmbeddedDashboard(newEmbeddedDashboard);
  };

  // useEffect(() => {
  //   if (embeddedDashboard) {
  //     embeddedDashboard.navigateToDashboard(dashboardId, {})
  //   }
  // }, [dashboardId])

  const changeDashboardBySelect = async (e) => {
    const dashboardId = e.target.value
    setDashboardId('36317c0a-107b-493a-9c43-742ac35ebfa3')
  }

  const changeDashboardByEffect = async (e) => {
    const dashboardId = e
    setDashboardId('36317c0a-107b-493a-9c43-742ac35ebfa3')
  }

  useEffect(() => {
    (async () => {
      try {
        const userDashboardId= await handleFetchUserAttributes();
        console.log(userDashboardId)
        changeDashboardByEffect('36317c0a-107b-493a-9c43-742ac35ebfa3')
      } catch (err) {
        console.log('Error occured when fetching books');
      }
    })();
  }, []);

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }
  const items = [
    getItem('Dashboard', '1', <PieChartOutlined />),

    // getItem('Option 2', '2', <DesktopOutlined />),
    // getItem('User', 'sub1', <UserOutlined />, [
    //   getItem('Tom', '3'),
    //   getItem('Bill', '4'),
    //   getItem('Alex', '5'),
    // ]),
    // getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
    // getItem('Files', '9', <FileOutlined />),
  ];



  return (
    <div ref={dashboardRef}/>
  );
};

export default withAuthenticator(DashboardHR, {hideSignUp: true});