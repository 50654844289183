import {sample} from "effector";
import {setUserAttributesEv, setUserGroupsEv, setUserKeysEv, setUserUIDDataEv} from "./events";
import {$userAttributes, $userGroups, $userKeys, $userUIDData} from "./stores";

$userAttributes.on(setUserAttributesEv, (state, payload) => payload)

$userUIDData.on(setUserUIDDataEv, (_, payload) => payload)

sample({
	clock: setUserGroupsEv,
	target: $userGroups
})

sample({
	clock: setUserKeysEv,
	target: $userKeys
})
