import {$currentPage, $formClickedButton, $isDarkTheme, $userData} from "./stores";
import {
	changeUserDataEv, formIsValidEv,
	sendRegistrationDataEv,
	setFormClickedButtonEv,
	setNextStepEv,
	toggleDarkThemeEv
} from "./events";
import {sample} from "effector";
import {sendRegistrationFx} from "./effects";

$isDarkTheme.on(toggleDarkThemeEv, (state) => !state)

$currentPage.on(setNextStepEv, (state, payload) => {
	if(Number.isInteger(payload)) return payload
	return state + 1
})

$formClickedButton.on(setFormClickedButtonEv, (state, payload) => payload)

sample({
	source: $userData,
	clock: changeUserDataEv,
	fn: (source, clock) => {
		if(clock.field === 'tags' || clock.field === 'images'){
			if(source[clock.field].includes(clock.value)){
				return {
					...source,
					[clock.field]: source[clock.field].filter(item => item !== clock.value)
				}
			} else {
				return {
					...source,
					[clock.field]: [...source[clock.field], clock.value]
				}
			}
		}
		return {
			...source,
			[clock.field]: clock.value
		}
	},
	target: $userData
})

sample({
	source: $userData,
	clock: sendRegistrationDataEv,
	fn: (source) => {
		const payload = {
			place_id: 'crtz05x7d2',
			user_name: source.name,
			user_surname: source.surname,
			user_mobile: parseInt(source.phone),
			user_date_of_birth: source.birthday.format('YYYY-MM-DD'),
			user_interests: source.tags,
			data_url: source.images
		}

		if(source.images.length === 0){
			delete payload.data_url
		}
		return payload
	},
	target: sendRegistrationFx
})

sample({
	clock: sendRegistrationFx.doneData,
	// filter: (clock) => clock,
	fn: () => 4,
	target: setNextStepEv
})

sample({
	source: $formClickedButton,
	clock: formIsValidEv,
	filter: (source, clock) => source === 2,
	target: setNextStepEv
})

sample({
	source: $formClickedButton,
	clock: formIsValidEv,
	filter: (source, clock) => source === 1,
	target: sendRegistrationDataEv
})
