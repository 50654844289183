import React, { useEffect } from "react";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
} from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import style from "../SchoolUserList.module.css";
import {
  defaultEndWorkTime,
  defaultStartWorkTime,
} from "../../../dictionaries/edu-dictionary";
import dayjs from "dayjs";

const workTypeOptions = [
  {
    label: "Стандартный",
    value: "Стандартный",
  },
  {
    label: "Нестандартный",
    value: "Нестандартный",
  },
];
const format = "HH:mm";

function EmpForm({ form }) {
  const work_type = Form.useWatch(["properties", "work_type"], form);

  return (
    <>
      <Row justify={"space-between"} gutter={8}>
        <Col span={8}>
          {" "}
          <Form.Item
            name={["properties", "emp_department"]}
            label={"Учебная часть"}
          >
            <Input placeholder={"Введите учебную часть"} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={["properties", "emp_position"]} label={"Должность"}>
            <Input placeholder={"Введите должность"} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {" "}
          <Form.Item name={["properties", "work_type"]} label={"График работы"}>
            <Select options={workTypeOptions} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify={"space-between"} gutter={8}>
        <Col span={12}>
          <Form.Item
            name={["properties", "emp_date"]}
            label={"Дата приема на работу"}
          >
            <DatePicker style={{ width: "100%" }} locale={locale} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={["properties", "dis_date"]}
            label={"Дата увольнения"}
          >
            <DatePicker style={{ width: "100%" }} locale={locale} />
          </Form.Item>
        </Col>
      </Row>

      <Row justify={"space-between"} gutter={8}>
        <Col span={12}>
          <Form.Item
            name={["properties", "subject"]}
            label={"Предметы(Кафедры) через запятую"}
          >
            <Input placeholder={"Биология, Алгебра и т.д."} />
          </Form.Item>
        </Col>
      </Row>
      {work_type && work_type !== "Стандартный" ? (
        <>
          <div className={style.modal_day_wrapper}>
            <div className={style.modal_day_title}>Понедельник</div>
            <div className={style.modal_day_timers}>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={["properties", "start_work_time", "Понедельник"]}
                label={"Время начала рабочего дня"}
              >
                <TimePicker
                  style={{ width: 300 }}
                  defaultValue={dayjs("09:00", "HH:mm")}
                  showNow={false}
                  locale={locale}
                  format={format}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={["properties", "end_work_time", "Понедельник"]}
                label={"Время окончания рабочего дня"}
              >
                <TimePicker
                  style={{ width: 300 }}
                  defaultValue={dayjs("17:00", "HH:mm")}
                  showNow={false}
                  locale={locale}
                  format={format}
                />
              </Form.Item>
            </div>
          </div>
          <div className={style.modal_day_wrapper}>
            <div className={style.modal_day_title}>Вторник</div>
            <div className={style.modal_day_timers}>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={["properties", "start_work_time", "Вторник"]}
                label={"Время начала рабочего дня"}
              >
                <TimePicker
                  style={{ width: 300 }}
                  defaultValue={dayjs("09:00", "HH:mm")}
                  showNow={false}
                  locale={locale}
                  format={format}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={["properties", "end_work_time", "Вторник"]}
                label={"Время окончания рабочего дня"}
              >
                <TimePicker
                  style={{ width: 300 }}
                  defaultValue={dayjs("17:00", "HH:mm")}
                  showNow={false}
                  locale={locale}
                  format={format}
                />
              </Form.Item>
            </div>
          </div>
          <div className={style.modal_day_wrapper}>
            <div className={style.modal_day_title}>Среда</div>
            <div className={style.modal_day_timers}>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={["properties", "start_work_time", "Среда"]}
                label={"Время начала рабочего дня"}
              >
                <TimePicker
                  style={{ width: 300 }}
                  defaultValue={dayjs("09:00", "HH:mm")}
                  showNow={false}
                  locale={locale}
                  format={format}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={["properties", "end_work_time", "Среда"]}
                label={"Время окончания рабочего дня"}
              >
                <TimePicker
                  style={{ width: 300 }}
                  defaultValue={dayjs("17:00", "HH:mm")}
                  showNow={false}
                  locale={locale}
                  format={format}
                />
              </Form.Item>
            </div>
          </div>
          <div className={style.modal_day_wrapper}>
            <div className={style.modal_day_title}>Четверг</div>
            <div className={style.modal_day_timers}>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={["properties", "start_work_time", "Четверг"]}
                label={"Время начала рабочего дня"}
              >
                <TimePicker
                  style={{ width: 300 }}
                  defaultValue={dayjs("09:00", "HH:mm")}
                  showNow={false}
                  locale={locale}
                  format={format}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={["properties", "end_work_time", "Четверг"]}
                label={"Время окончания рабочего дня"}
              >
                <TimePicker
                  style={{ width: 300 }}
                  defaultValue={dayjs("17:00", "HH:mm")}
                  showNow={false}
                  locale={locale}
                  format={format}
                />
              </Form.Item>
            </div>
          </div>
          <div className={style.modal_day_wrapper}>
            <div className={style.modal_day_title}>Пятница</div>
            <div className={style.modal_day_timers}>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={["properties", "start_work_time", "Пятница"]}
                label={"Время начала рабочего дня"}
              >
                <TimePicker
                  style={{ width: 300 }}
                  defaultValue={dayjs("09:00", "HH:mm")}
                  showNow={false}
                  locale={locale}
                  format={format}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={["properties", "end_work_time", "Пятница"]}
                label={"Время окончания рабочего дня"}
              >
                <TimePicker
                  style={{ width: 300 }}
                  defaultValue={dayjs("17:00", "HH:mm")}
                  showNow={false}
                  locale={locale}
                  format={format}
                />
              </Form.Item>
            </div>
          </div>
          <div className={style.modal_day_wrapper}>
            <div className={style.modal_day_title}>Суббота</div>
            <div className={style.modal_day_timers}>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={["properties", "start_work_time", "Суббота"]}
                label={"Время начала рабочего дня"}
              >
                <TimePicker
                  style={{ width: 300 }}
                  defaultValue={dayjs("09:00", "HH:mm")}
                  showNow={false}
                  locale={locale}
                  format={format}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={["properties", "end_work_time", "Суббота"]}
                label={"Время окончания рабочего дня"}
              >
                <TimePicker
                  style={{ width: 300 }}
                  defaultValue={dayjs("17:00", "HH:mm")}
                  showNow={false}
                  locale={locale}
                  format={format}
                />
              </Form.Item>
            </div>
          </div>
          <div className={style.modal_day_wrapper}>
            <div className={style.modal_day_title}>Воскресенье</div>
            <div className={style.modal_day_timers}>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={["properties", "start_work_time", "Воскресенье"]}
                label={"Время начала рабочего дня"}
              >
                <TimePicker
                  style={{ width: 300 }}
                  defaultValue={dayjs("09:00", "HH:mm")}
                  showNow={false}
                  locale={locale}
                  format={format}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
                name={["properties", "end_work_time", "Воскресенье"]}
                label={"Время окончания рабочего дня"}
              >
                <TimePicker
                  style={{ width: 300 }}
                  defaultValue={dayjs("17:00", "HH:mm")}
                  showNow={false}
                  locale={locale}
                  format={format}
                />
              </Form.Item>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default EmpForm;
